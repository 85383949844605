import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import Layout from './Layout';

const ProductsList = ({ products, setProducts, disabled = false }) => {
  const { fetchFromBackend } = useFetch();
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const generateUniqueToken = () => {
    let token = (Math.random() + 1).toString(36).substring(7);

    // ensure uniqueness of token
    let usedTokens = products.map((product) => product.id);
    if (usedTokens.includes(token)) {
      return generateUniqueToken();
    }

    return token;
  };

  const handleProductChange = (selected, oldValue) => {
    const index = products.findIndex((product) => product.id === oldValue);
    let updatedProducts = products;
    if (index !== -1) {
      updatedProducts[index].value = selected.value;
    }
    setProducts([...updatedProducts]);
  };

  const handleQuantityChange = (productId, quantity) => {
    const index = products.findIndex((product) => product.id === productId);
    let updatedProducts = products;
    if (index !== -1) {
      updatedProducts[index].quantity = quantity;
    }
    setProducts([...updatedProducts]);
  };

  const handleAddProduct = () => {
    let newProductField = {
      id: generateUniqueToken(),
      value: '',
      quantity: '',
    };

    setProducts([...products, newProductField]);
  };

  const handleSubtractProduct = (id) => {
    const newProductFields = products.filter((prod) => prod.id !== id);
    setProducts(newProductFields);
  };

  useEffect(() => {
    setSelectedProducts(products.flatMap((product) => product.value));
  }, [products]);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true)
      const res = await fetchFromBackend('/products', 'GET', {}, true);
      const fetchedProducts = res.map((product) => ({
        value: String(product.prd_id),
        label: product.prd_dspnam,
      }));
      setProductOptions(fetchedProducts);
      setIsLoading(false)
    };

    // fetch active products
    fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      handleQuantityChange={handleQuantityChange}
      handleProductChange={handleProductChange}
      handleSubtractProduct={handleSubtractProduct}
      handleAddProduct={handleAddProduct}
      productOptions={productOptions}
      products={products}
      selectedProducts={selectedProducts}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};

export default ProductsList;

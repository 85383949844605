import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from './Layout';
import uploadToS3 from '../../utils/uploadFile';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import notify, { MISSING_FIELDS } from '../../utils/notify';
import { numericMask } from '../../utils/formater';
import formatPrice from '../../utils/formatPrice';

const Product = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [imageUrl, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urlText, setUrlText] = useState('');
  const [urlTextInfo1, setUrlTextInfo1] = useState('');
  const [urlTextInfo2, setUrlTextInfo2] = useState('');
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange, setFieldValue, handleFinish, setFieldProperty } = useForm({
    name: {
      required: true,
    },
    displayWeight: {
      required: false,
    },
    description: {
      required: false,
    },
    brand: {
      required: false,
    },
    prodTime: {
      required: false,
    },
    sku: {
      required: true,
    },
    bling: {
      required: false,
    },
    ncm: {
      required: false,
    },
    price: {
      required: true,
      mask: numericMask,
    },
    height: {
      required: false,
    },
    width: {
      required: false,
    },
    length: {
      required: false,
    },
    netWeight: {
      required: false,
    },
    grossWeight: {
      required: true,
    },
    active: {
      required: true,
      value: 'true',
    },
    custom: {
      required: true,
      value: 'false',
    },
    benefit: {
      required: true,
      value: 'false',
    },
    shelf: {
      required: true,
      value: 'false',
    },
    productType: {
      required: false,
    },
    taxType: {
      required: false,
    },
    weightUnit: {
      required: false,
    },
    dimensionUnit: {
      required: false,
    },
  });
  const onClickGoBack = () => {
    navigate('/products/');
  };

  const handleFileInput = (evt) => {
    if (file === null) {
      setFile(evt.target.files[0]);
      setFileName(evt.target.value);
    } else {
      setFile(evt.target.files[0]);
      setFileName(evt.target.value);
    }
  };

  const submitFile = async () => {
    if (fields.name.value !== null) {
      setLoading(true);
      let obj = await uploadToS3(file);
      setImageURL(obj.location);
      setLoading(false);
    }
  };

  const updateProduct = async () => {
    setLoading(true);
    const { valid } = await handleFinish();
    if (valid) {
      let formattedPrice = parseInt(fields.price.value.replace(',', ''));
      const slugName = fields.name.value.replace(' ', '_').toLowerCase();
      const productObject = {
        active: fields.active.value === 'true' ? true : false,
        is_benefit: fields.benefit.value === 'true' ? true : false,
        is_shelf: fields.shelf.value === 'true' ? true : false,
        is_custom: fields.custom.value === 'true' ? true : false,
        value: formattedPrice,
        sku: fields.sku.value,
        name: slugName,
        display_name: fields.name.value,
        weight: fields.grossWeight.value,
        description: fields.description.value,
        image: imageUrl,
        bling_code: fields.bling.value,
        brand: fields.brand.value,
        ncm_code: fields.ncm.value,
        size_display: fields.displayWeight.value,
        net_weight: fields.netWeight.value,
        gross_weight: fields.grossWeight.value,
        production_time: fields.prodTime.value,
        length: fields.length.value === '' ? 0 : fields.length.value,
        height: fields.height.value === '' ? 0 : fields.height.value,
        width: fields.width.value === '' ? 0 : fields.width.value,
        weight_unit: fields.weightUnit.value,
        dimension_unit: fields.dimensionUnit.value,
        product_type: fields.productType.value,
        tax_type: fields.taxType.value,
      };
      fetchFromBackend('/products/' + productId, 'PATCH', productObject, true)
        .then((response) => {
          setLoading(false);
          notify('Produto atualizado!', 'success');
        })
        .catch((error) => {
          notify('Erro ao atualizar produto', 'error');
          setLoading(false);
        });
      setLoading(false);
    } else {
      setLoading(false);
      notify(MISSING_FIELDS, 'error');
    }
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);
  };

  const handleSelectChange = (targetValue, targetName, isMulti) => {
    let fieldValue = [];
    if (targetName === 'taxType') {
      if (targetValue[0].value === 'product') {
        setFieldProperty('bling', 'required', true);
      } else {
        setFieldProperty('bling', 'required', false);
      }
    }

    targetValue.forEach((selected) => selected && fieldValue.push(selected.value));
    handleChange({ target: { name: targetName, value: fieldValue[0] } });
  };

  const formatedValue = () => {
    if (fields.price.value) {
      let parsedValue = fields.price.value.replace(',', '');
      return formatPrice(parseInt(parsedValue));
    }
    return null;
  };
  useEffect(() => {
    const getProductsInfo = () => {
      fetchFromBackend('/products/' + productId + '?all_products=true', 'GET', {}).then(
        (response) => {
          setFieldValue('active', response.prd_active.toString(), true);
          setFieldValue('benefit', response.prd_benefit.toString(), true);
          setFieldValue('shelf', response.prd_shelf.toString(), true);
          setFieldValue('custom', response.prd_custom.toString(), true);
          setFieldValue('price', response.prd_value.toString(), true);
          setFieldValue('sku', response.prd_sku, true);
          setFieldValue('name', response.prd_dspnam, true);
          setFieldValue('grossWeight', response.prd_grswt.toString(), true);
          setFieldValue('description', response.prd_dscrp, true);
          setImageURL(response.prd_image);
          setFieldValue('bling', response.prd_blingcod, true);
          setFieldValue('brand', response.prd_brand, true);
          setFieldValue('ncm', response.prd_ncm, true);
          setFieldValue('displayWeight', response.prd_szdisp.toString(), true);
          setFieldValue('netWeight', response.prd_netwt.toString(), true);
          setFieldValue('prodTime', response.prd_prdtime.toString(), true);
          if (response?.prd_length) {
            setFieldValue('length', response.prd_length.toString(), true);
          }
          if (response?.prd_height) {
            setFieldValue('height', response.prd_height.toString(), true);
          }
          if (response?.prd_width) {
            setFieldValue('width', response.prd_width.toString(), true);
          }
          if (response?.prd_taxtype) {
            setFieldValue('taxType', response.prd_taxtype, true);
          }

          if (response?.prd_wtunit) {
            setFieldValue('weightUnit', response.prd_wtunit ? response.prd_wtunit : 'mg', true);
          }

          if (response?.prd_type) {
            setFieldValue('productType', response.prd_type, true);
          }

          if (response?.prd_dimunit) {
            setFieldValue('dimensionUnit', response.prd_dimunit ? response.prd_dimunit : 'm', true);
          }
          if (response.prd_taxtype !== 'product') {
            setFieldProperty('bling', 'required', false);
          } else {
            setFieldProperty('bling', 'required', true);
          }
        }
      );
    };

    getProductsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlText);
    notify('URL Copiada para Área de Transferência', 'success');
  };

  useEffect(() => {
    const sku = fields.sku.value;
    var returning =
      fields.custom.value === 'true'
        ? 'https://checkout.justfor.com.br/?form=<form id>&products=' + sku + ':1'
        : 'https://checkout.justfor.com.br/?products=' + sku + ':1';
    setUrlText(returning);
  }, [fields]);

  useEffect(() => {
    var url_text_info1 =
      fields.custom.value === 'true'
        ? 'Para utilizar a URL, substitua <formid> por um form id válido'
        : '';
    var url_text_info2 =
      fields.custom.value === 'true'
        ? 'https://checkout.justfor.com.br/?form=<formid>&products=<sku1>:<quantidade>,<sku2>:<quantidade>'
        : 'https://checkout.justfor.com.br/?products=<sku1>:<quantidade>,<sku2>:<quantidade>';
    setUrlTextInfo1(url_text_info1);
    setUrlTextInfo2(url_text_info2);
  }, [fields]);

  return (
    <Layout
      onClickGoBack={onClickGoBack}
      handleFileInput={handleFileInput}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      handleSelectChange={handleSelectChange}
      submitFile={submitFile}
      file={file}
      fileName={fileName}
      fields={fields}
      createProduct={updateProduct}
      imageUrl={imageUrl}
      formatedValue={formatedValue}
      loading={loading}
      copyToClipboard={copyToClipboard}
      urlText={urlText}
      urlTextInfo1={urlTextInfo1}
      urlTextInfo2={urlTextInfo2}
    />
  );
};

export default Product;

import { PanelContainer, Block } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import formatPrice from '../../../utils/formatPrice';

import styles from './Products.module.css';
import { ReactComponent as ValidIcon } from '../../../assets/icons/approved.svg';
import { ReactComponent as InvalidIcon } from '../../../assets/icons/denied.svg';

const Layout = ({
  handleNewProduct,
  shelfProducts,
  products,
  handleClick,
  fields,
  filteredProducts,
  handleChange,
}) => {
  return (
    <PanelContainer
      headerMenu
      headerTitle="Produtos"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <Block className={styles.block}>
        <div className={styles.search}>
          <Input
            name="search"
            placeholder="Filtrar produtos"
            className={styles.input}
            onChange={handleChange}
            value={fields.search.value}
          />
          <Button
            primary
            text="Criar Produto"
            className={styles.button}
            onClick={handleNewProduct}
          />
        </div>
      </Block>
      <Block className={styles.block}>
        {fields.search?.value === '' ? <h2>Produtos do Checkout</h2> : <h2>Produtos</h2>}
        <div className={styles.productListContainer}>
          {fields.search?.value === ''
            ? shelfProducts.map((product, key) => {
              return (
                <div className={styles.product} key={key}>
                  <div className={styles.productName}>
                    <h2 onClick={(e) => handleClick(product.prd_id, e.ctrlKey)}>
                      {product.prd_dspnam}
                    </h2>
                    <p>{product.prd_dscrp}</p>
                    <b>SKU:{product.prd_sku}</b>
                  </div>
                  <div className={styles.value}>
                    <h2>Preço</h2>
                    <h2>{formatPrice(product.prd_value)}</h2>
                  </div>
                  <div className={styles.status}>
                    <h2>Ativo?</h2>
                    {product.prd_active ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                  <div className={styles.valid}>
                    <h2>Necessita de formula?</h2>
                    {product.prd_custom ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                </div>
              );
            })
            : filteredProducts.map((product, key) => {
              return (
                <div className={styles.product} key={key}>
                  <div className={styles.productName}>
                    <h2 onClick={(e) => handleClick(product.prd_id, e.ctrlKey)}>
                      {product.prd_dspnam}
                    </h2>
                    <p>{product.prd_dscrp}</p>
                    <b>SKU:{product.prd_sku}</b>
                  </div>
                  <div className={styles.value}>
                    <h2>Preço</h2>
                    <h2>{formatPrice(product.prd_value)}</h2>
                  </div>
                  <div className={styles.status}>
                    <h2>Ativo?</h2>
                    {product.prd_active ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                  <div className={styles.valid}>
                    <h2>Necessita de formula?</h2>
                    {product.prd_custom ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                </div>
              );
            })}
        </div>
      </Block>

      {fields.search?.value === '' ? (
        <Block className={styles.block}>
          <h2>Todos os Produtos</h2>
          <div className={styles.productListContainer}>
            {products.map((product, key) => {
              return (
                <div className={styles.product} key={key}>
                  <div className={styles.productName}>
                    <h2 onClick={(e) => handleClick(product.prd_id, e.ctrlKey)}>
                      {product.prd_dspnam}
                    </h2>
                    <p>{product.prd_dscrp}</p>
                    <b>SKU:{product.prd_sku}</b>
                  </div>
                  <div className={styles.value}>
                    <h2>Preço</h2>
                    <h2>{formatPrice(product.prd_value)}</h2>
                  </div>
                  <div className={styles.status}>
                    <h2>Ativo?</h2>
                    {product.prd_active ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                  <div className={styles.valid}>
                    <h2>Necessita de formula?</h2>
                    {product.prd_custom ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                  <div className={styles.benefit}>
                    <h2>Brinde?</h2>
                    {product.prd_benefit ? <ValidIcon /> : <InvalidIcon />}
                  </div>
                </div>
              );
            })}
          </div>
        </Block>
      ) : null}
    </PanelContainer>
  );
};

export default Layout;

import Layout from './Layout';

const ProductCard = ({ url, display_name, size, value }) => {
  const product = {
    url: url,
    product_display_name: display_name,
    product_size: size,
    value: value,
  };
  return <Layout product={product} />;
};

export default ProductCard;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { noSpaceMask, numericMask } from '../../utils/formater';
import notify, {
  MISSING_FIELDS,
  VOUCHER_GREATER_THAN_MINIMUM_AMOUNT,
  UF_VALUE_IS_MISSING,
  NO_STATES_MASS_CHANGE_WITH_FILTER,
  NO_PRICE_MASS_CHANGE,
  CONFIRM_MASS_CHANGE_UF_VALUE,
  VOUCHER_MINIMUM_VALUE,
  VOUCHER_SUCCESS,
} from '../../utils/notify';
import {
  validateUfandValues,
  formatUfList,
  getRegionUf,
  removeSpecialCharacters,
} from './AddVoucherHelpers';

import Layout from './Layout';

const AddVoucher = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState();
  const { fetchFromBackend } = useFetch();
  const [loadingUf, setLoadingUf] = useState(false);
  const [dataUf, setDataUf] = useState([]);
  const [dataUfFilter, setDataUfFilter] = useState([]);
  const { fields, handleChange, setFieldValue, handleFinish, setFieldProperty } = useForm(
    {
      code: {
        required: true,
        mask: noSpaceMask,
      },
      description: {
        required: true,
      },
      percent: {
        required: true,
        value: 'true',
      },
      discountValuePercentage: {
        required: true,
      },
      discountValueAbsolut: {
        required: false,
        mask: numericMask,
      },
      minValue: {
        required: false,
        mask: numericMask,
      },
      freeShipping: {
        required: false,
        value: false,
      },
      supportShipping: {
        required: false,
        value: 'false',
      },
      fixShipping: {
        required: false,
        value: 'false',
      },
      zip_limitation: {
        required: false,
        value: false,
      },
      ufSelected: {
        required: false,
        value: [],
      },
      start: {
        required: true,
      },
      end: {
        required: true,
      },
      minProducts: {
        required: false,
      },
      shippingSearch: {
        required: false,
      },
      shippingFilterBy: {
        required: false,
        value: 'uf',
      },
      shippingFilterByOptions: {
        required: false,
        value: [
          { value: 'uf', label: 'Estado' },
          { value: 'regions', label: 'Região' },
        ],
      },
      shippingTypeOptions: {
        required: false,
        value: [
          { value: 'client', label: 'Pago pelo cliente', useValue: false },
          { value: 'free', label: 'Frete Grátis', useValue: false },
          { value: 'support', label: 'Frete Custeado', useValue: true },
          { value: 'fix', label: 'Frete Fixo', useValue: true },
        ],
      },
      shippingType: {
        required: false,
        value: 'client',
      },
      shippingTypeAllUfs: {
        required: false,
      },
      shippingValueAllUfs: {
        required: false,
        mask: numericMask,
      },
    }
  );

  const getDataUfFilter = (dataUfInfo, termSearch = '') => {
    const filterBy = fields.shippingFilterBy?.value;
    const search = removeSpecialCharacters(termSearch || fields.shippingSearch?.value);

    if (filterBy === 'uf' && search) {
      return {
        filterInUse: true,
        ufList: dataUfInfo.filter((uf) => new RegExp(search).test(removeSpecialCharacters(uf.label)))
      };
    }

    if (filterBy === 'regions' && search) {
      return {
        filterInUse: true,
        ufList: dataUfInfo.filter((uf) => new RegExp(search).test(removeSpecialCharacters(uf.region)))
      };
    }

    return {
      filterInUse: false,
      ufList: dataUfInfo
    };
  };

  const handleConfirmModalAction = () => {
    handleChangeAllShippingValue();
    setModalIsOpen(false);
  };

  const handleCancelModalAction = () => {
    setModalIsOpen(false);
  };
  const handleOpenModal = () => {
    const shippingTypeAllUfs = fields.shippingTypeAllUfs.value;
    const shippingValueAllUfs = fields.shippingValueAllUfs.value;

    const afectedUfs = dataUf.filter(uf => uf.group === shippingTypeAllUfs);

    if (!afectedUfs || afectedUfs?.length === 0) return notify(NO_STATES_MASS_CHANGE_WITH_FILTER, 'error');
    if (!shippingValueAllUfs) return notify(NO_PRICE_MASS_CHANGE, 'error');

    setModalMessage(CONFIRM_MASS_CHANGE_UF_VALUE(afectedUfs.length))
    setModalIsOpen(true);
  };

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/vouchers');
  };

  const handleChangeUfShippingValue = (price, shippingInfo) => {
    //regex to apply mask to number 1.111,00
    let priceShipping = price
      .replace(/\D/g, '')
      .replace(/(\d{1,2})$/, ',$1')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    const newDataUf = dataUf.map((uf) => {
      if (uf.id === shippingInfo.id) {
        return { ...uf, price: priceShipping, valid: true};
      }
      return uf;
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
  };

  const handleChangeUfShippingType = (seleted, shippingInfo) => {
    const newDataUf = dataUf.map((uf) => {
      if (uf.id === shippingInfo.id) {
        return { ...uf, group: seleted.value, useValue: seleted.useValue, price: seleted.useValue ? uf.price : '' };
      }
      return uf;
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
    const shippingTypes = fields.shippingTypeOptions.value;
    const allShippingTypeIsTheSame = shippingTypes.find((shippingType) =>
      newDataUf.every((uf) => uf.group === shippingType.value)
    );

    setFieldValue(
      'shippingType',
      allShippingTypeIsTheSame ? allShippingTypeIsTheSame.value : ''
    );
  };

  const handleChangeAllShippingType = (type) => {
    const shippingTypes = fields.shippingTypeOptions.value;
    const seletedShippingTypes = shippingTypes.find(
      (shippingType) => shippingType.value === type
    );

    const newDataUf = dataUf.map((uf) => {
      return {
        ...uf,
        group: type,
        useValue: seletedShippingTypes.useValue,
        price: seletedShippingTypes.useValue ? uf.price : ''
      };
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
    setFieldValue('shippingType', type);
  };

  const handleChangeAllShippingValue = () => {
    const shippingTypeAllUfs = fields.shippingTypeAllUfs.value;
    const shippingValueAllUfs = fields.shippingValueAllUfs.value;

    const newDataUf = dataUf.map((uf) => {
      if (uf.group === shippingTypeAllUfs) {
        return {
          ...uf,
          price: shippingValueAllUfs,
          valid: true,
        };
      }

      return uf;
    });

    setDataUf(newDataUf);
    setDataUfFilter(newDataUf);
  }

  const handleFilterShippingChange = (valueSearch, inputName) => {
    const termSearch = valueSearch.target?.value;
    const { ufList } = getDataUfFilter(dataUf, termSearch);
    setDataUfFilter(ufList);
    setFieldValue(inputName, termSearch);
  }

  const getUfCodes = async () => {
    setLoadingUf(true);
    await fetchFromBackend(`/logistics/ufs`, 'GET', {}, true).then((response) => {
      let parsedResponse = [];
      response.forEach((uf) => {
        if (uf.uf_if) {
          parsedResponse.push({
            id: uf.uf_if,
            label: uf.uf_name,
            value: uf.uf_if,
            group: 'client',
            price: '',
            region: getRegionUf(uf.uf_if).region,
            valid: true,
          });
        }
      });

      const ordenedResponse = parsedResponse.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

      setDataUf(ordenedResponse);
      setDataUfFilter(ordenedResponse);
    });
    setLoadingUf(false);
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);

    // Toggle fields' requirement property based on percentage selector
    if (field === 'percent') {
      if (value === 'true') {
        setFieldProperty('discountValuePercentage', 'required', true);
        setFieldProperty('discountValueAbsolut', 'required', false);
      } else {
        setFieldProperty('discountValuePercentage', 'required', false);
        setFieldProperty('discountValueAbsolut', 'required', true);
      }
    }
  };

  const handleShippingSearchChange = (targetValue, targetName) => {
    let fieldValue = fields[targetName].value;

    if (targetValue[0]) {
      fieldValue = targetValue[0].value;
    } else {
      fieldValue = '';
    }

    handleChange({ target: { name: targetName, value: fieldValue } });
  };

  const changeObjectToShowError = (dateUfUnfiled) => {
    let objectErrorIds = [];
    dateUfUnfiled.forEach((error) => {
      return objectErrorIds.push(error.id);
    })

    const newDataUf = dataUf.map((uf) => {
      if (objectErrorIds.includes(uf.id)) {
        return {
          ...uf,
          valid: false,
        };
      }

      return uf;
    });
    setDataUfFilter(newDataUf);
  }


  const createVoucher = async () => {
    setLoading(true);
    const { valid } = await handleFinish();
    const ufIsValid = validateUfandValues(dataUf, fields);

    if (!ufIsValid.valid) {
      changeObjectToShowError(ufIsValid.details)
      return notify(UF_VALUE_IS_MISSING, 'error');
    }

    if (fields.percent?.value === 'false') {
      let minimunValue;
      let voucherValue;
      if (fields.minValue?.value) {
        minimunValue = parseInt(fields.minValue?.value?.replace(/,/g, ''));
        voucherValue = parseInt(fields.discountValueAbsolut?.value?.replace(/,/g, ''));
      } else {
        notify(VOUCHER_MINIMUM_VALUE, 'error');
        return;
      }

      if (voucherValue > minimunValue) {
        notify(VOUCHER_GREATER_THAN_MINIMUM_AMOUNT, 'error');
        return;
      }
    }
    if (valid) {
      const reqBody = {
        code: fields.code.value,
        description: fields.description.value,
        payment_method: 'any',
        all_products: true,
        uf_list: formatUfList(dataUf),
        zip_limitation: ufIsValid.zipLimitationIsEnabled,
      };

      if (fields.freeShipping?.value === 'true') {
        reqBody.free_shipment = true;
      } else {
        reqBody.free_shipment = false;
      }

      if (fields.percent?.value === 'true') {
        reqBody.is_percent = true;
        reqBody.discount_value = parseInt(fields.discountValuePercentage?.value);
      } else {
        reqBody.is_percent = false;
        let discount = fields.discountValueAbsolut?.value.replace(/,/g, '');
        reqBody.discount_value = parseInt(discount);
      }

      if (fields.start.value) {
        reqBody.start_date = new Date(fields.start.value).toISOString();
      }
      if (fields.end.value) {
        reqBody.end_date = new Date(fields.end.value).toISOString();
      }

      if (fields.minValue?.value) {
        let minValue = fields.minValue?.value.replace(/,/g, '');
        reqBody.minimun_spent = parseInt(minValue);
      }

      reqBody.product_quantity = fields.minProducts?.value ? fields.minProducts.value : 0;

      fetchFromBackend('/vouchers', 'POST', reqBody, true).then((response) => {
        notify(VOUCHER_SUCCESS, 'success');
        setTimeout(() => {
          navigate('/vouchers');
        }, 3000);
      });
    } else {
      notify(MISSING_FIELDS, 'error');
    }
  };

  useEffect(() => {
    getUfCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      onClickGoBack={handleGoBack}
      handleClickAddVoucher={createVoucher}
      loading={loading}
      dataUf={dataUfFilter}
      loadingUf={loadingUf}
      confirmAction={handleConfirmModalAction}
      cancelAction={handleCancelModalAction}
      isOpen={modalIsOpen}
      modalMessage={modalMessage}
      handleShippingSearchChange={handleShippingSearchChange}
      handleChangeUfShippingValue={handleChangeUfShippingValue}
      handleChangeUfShippingType={handleChangeUfShippingType}
      handleChangeAllShippingType={handleChangeAllShippingType}
      handleFilterShippingChange={handleFilterShippingChange}
      handleChangeAllShippingValue={handleChangeAllShippingValue}
      handleOpenModal={handleOpenModal}
    />
  );
};

export default AddVoucher;

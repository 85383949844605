import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import useForm from '../../hooks/useForm';
import useFetch from '../../hooks/useFetch';
import getUrlParams from '../../utils/urlParamsHandler';
import notify, { INTERNAL_ERROR } from '../../utils/notify';

import Layout from './Layout';

const Vouchers = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalVouchers, setTotalVouchers] = useState();
  const { fetchFromBackend } = useFetch();
  const defaultPageSize = 10;
  const defaultPage = 0;
  const [urlFilters] = useState(getUrlParams(searchParams, ['search', 'page', 'limit']));
  const [pageSize, setPageSize] = useState(urlFilters.limit || defaultPageSize);
  const [currentPage, setCurrentPage] = useState(urlFilters.page || defaultPage);
  const { fields, handleChange } = useForm({
    search: {
      required: false,
      value: urlFilters.search,
    },
  });

  const handleVoucherClick = (code, newTab = false) => {
    const url = `/vouchers/${code}`;
    if (newTab) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const handleNewVoucher = () => {
    navigate('/vouchers/add');
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.value);
  };

  const parseSearchParams = (params = {}) => {
    Object.entries(fields).forEach((field) => {
      const key = field[0];
      const value = field[1].value;
      if (value) {
        if (typeof value === 'object') {
          const validValues = value.filter((v) => v !== '');
          if (validValues.length > 0) params[key] = validValues.join(',');
        } else if (value !== '') {
          params[key] = value;
        }
      }
    });
    if (pageSize !== defaultPageSize) params.limit = pageSize;
    if (currentPage !== defaultPage) params.page = currentPage;
    return createSearchParams(params);
  };

  const fetchVouchers = (limit = pageSize, page = currentPage) => {
    setLoading(true);
    const requestURL = decodeURIComponent(`/vouchers/filter/general?${parseSearchParams()}`);
    fetchFromBackend(requestURL, 'GET', {}, true)
      .then((response) => {
        setVouchers(response.data);
        setTotalPages(Math.ceil(response.total / response.limit));
        setTotalVouchers(response.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(INTERNAL_ERROR);
      });
  };

  useEffect(() => {
    !loading && setLoading(true);
    const filterDelayDebounce = setTimeout(async () => {
      await fetchVouchers();
      setSearchParams(parseSearchParams());
    }, 1000);
    return () => clearTimeout(filterDelayDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, currentPage, pageSize]);

  return (
    <Layout
      vouchers={vouchers}
      totalPages={totalPages}
      totalVouchers={totalVouchers}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      loading={loading}
      fields={fields}
      handleChange={handleChange}
      pageSize={pageSize}
      currentPage={currentPage}
      handleNewVoucher={handleNewVoucher}
      handleVoucherClick={handleVoucherClick}
      allowNewVoucher={authContext.verifyAccess([2])}
    />
  );
};

export default Vouchers;

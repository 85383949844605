import Select from 'react-select';

import { PanelContainer, Block } from '../../../components/PanelContainer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Switch from '../../../components/Switch';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import styles from './HashCampaign.module.css';
import ProductsList from '../../../components/ProductsList';

const Layout = ({
  fields,
  handleChange,
  handleFieldChange,
  handleSelectChange,
  handleClickAddCampaign,
  onClickGoBack,
  products,
  setProducts,
  defaultSheet,
  sheetOptions,
  anyChanged,
  loading,
  campaignId,
}) => {
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      height: '37px',
    }),

    container: (provided, state) => ({
      ...provided,
      width: '200px',
      height: '37px',
    }),
    input: (provided, state) => ({
      ...provided,
      marginTop: '-10px',
    }),
  };
  return (
    <PanelContainer
      headerMenu
      headerTitle="Criar Campanha de Códigos Promocionais"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      <div className={styles.header}>
        <Button
          tertiary
          icon={<ArrowLeft />}
          text="ver todos as campanhas"
          className={styles.back}
          onClick={onClickGoBack}
        />
      </div>
      <Block className={styles.block}>
        <div className={styles.name}>
          <h2>Nome da Campanha</h2>
          <p>{fields.campaignName?.value}</p>
        </div>
        <h2 className={styles.closeHeader}>Quantidade de códigos</h2>
        <div className={styles.quantities}>
          <p>
            Total: <span>{fields.quantityTotal?.value}</span>
          </p>
          <p className={styles.leftPadded}>
            Utilizados: <span>{fields.quantityUsed?.value}</span>
          </p>
        </div>
        <a href={`/hash-campaigns/${campaignId}/hashes`} className={styles.link}>
          Ver todos os códigos
        </a>
      </Block>
      <Block className={styles.block}>
        <div className={styles.date}>
          <h2>Data de expiração</h2>
          <Input
            name="expirationDate"
            type="datetime-local"
            value={fields.expirationDate?.value}
            onChange={handleChange}
            className={styles.input}
          />
        </div>
        <h2 className={styles.closeHeader}>Marketing</h2>
        <div className={styles.flexContainer}>
          <div>
            <h3>CRM</h3>
            <Switch
              checked={fields.crm?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('crm', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h3>Analytics</h3>
            <Switch
              checked={fields.analytics?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('analytics', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div>
            <h2>Gerará Nota fiscal</h2>
            <Switch
              checked={fields.sendInvoice?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendInvoice', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h2>Enviará pela transportadora</h2>
            <Switch
              checked={fields.ship?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('ship', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <h2 className={styles.closeHeader}>Transacional</h2>
        <div className={styles.flexContainer}>
          <div>
            <h3>Email</h3>
            <Switch
              checked={fields.sendEmail?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendEmail', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h3>SMS</h3>
            <Switch
              checked={fields.sendSms?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendSms', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <div className={styles.sheet}>
          <h2>Planilha</h2>
          <Select
            key="sheetName"
            options={sheetOptions}
            placeholder="Planilha"
            hideSelectedOptions
            className="filter-container"
            classNamePrefix="filter"
            styles={selectStyle}
            isClearable={false}
            onChange={(selected) => handleSelectChange(selected, 'sheetName')}
            defaultValue={defaultSheet}
            value={{ value: fields.sheetName?.value, label: fields.sheetName?.value }}
          />
        </div>
        <div>
          <h2>Produtos</h2>
          {products && <ProductsList products={products} setProducts={setProducts} />}
        </div>
        <Button
          primary
          text="Atualizar"
          className={styles.button}
          onChange={handleChange}
          onClick={handleClickAddCampaign}
          disabled={!anyChanged}
          loading={loading}
        />
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import { slide as Menu } from 'react-burger-menu';
import { APP_ROUTES } from '../../../routes/appRoutes';
import { ReactComponent as JFYLogo } from '../../../assets/jfy-white-logo.svg';

import styles from './Menu.module.css';
import './burger-menu-styles.css';

const Layout = ({
  onClickItem,
  selectedRoute,
  onClose,
  onOpen,
  isOpen,
  transition,
  disableOverlayClick,
  logout,
  userRole,
}) => {
  const renderRoute = (route) => {
    if (route.menu && !route.auth?.denyList.includes(userRole)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Menu
      styles={styles}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      noTransition={!transition}
      disableOverlayClick={disableOverlayClick}
    >
      <div className={styles.logoContainer}>
        <JFYLogo className={styles.jfyLogo} />
        <h1>PAINEL</h1>
      </div>
      {Object.entries(APP_ROUTES).map(([item, route], key) =>
        renderRoute(route) ? (
          <div
            className={styles.item + (route.path === selectedRoute ? ` ${styles.selected}` : '')}
            key={key}
            onClick={(e) => onClickItem(route.path, e.ctrlKey)}
          >
            <h2>{route.name}</h2>
          </div>
        ) : null
      )}
      <div className={styles.item} onClick={logout}>
        <h2>Sair</h2>
      </div>
    </Menu>
  );
};

export default Layout;

import Layout from './Layout';

import { useState, useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const HashCampaigns = () => {
  const navigate = useNavigate();
  const { fetchFromBackend } = useFetch();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCampaignClick = (campaignId, newTab = false) => {
    const url = '/hash-campaigns/' + campaignId;
    if (newTab) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const handleClickAdd = (newTab = false) => {
    const url = '/hash-campaigns/add';
    if (newTab) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.value);
  };

  const onSearchFieldChange = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const search = () => {
    fetchHashCampaigns(0, pageSize, searchTerm);
  };

  const fetchHashCampaigns = (page = currentPage, limit = pageSize, search = '') => {
    let params;
    let requestURL;

    params = createSearchParams({ page: page, limit: limit, campaign_name: searchTerm });
    requestURL = decodeURIComponent(`/hash/campaigns?${params}`);

    setLoading(true);
    fetchFromBackend(requestURL, 'GET', {}, true)
      .then((response) => {
        setLoading(false);
        setCampaigns(response.campaigns);
        setTotalPages(Math.ceil(response.total / limit));
      })
      .catch((error) => {
        setCampaigns(false);
      });
  };

  useEffect(() => {
    fetchHashCampaigns(currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return (
    <Layout
      loading={loading}
      campaigns={campaigns}
      pageSize={pageSize}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      currentPage={currentPage}
      totalPages={totalPages}
      onSearchFieldChange={onSearchFieldChange}
      search={search}
      searchTerm={searchTerm}
      handleCampaignClick={handleCampaignClick}
      handleClickAdd={handleClickAdd}
    />
  );
};

export default HashCampaigns;

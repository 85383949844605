import { PanelContainer, Block } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';
import JFYSelect from '../../../components/Select';
import ReactPaginate from 'react-paginate';

import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left.svg';

import styles from './Hashes.module.css';
import paginateStyles from '../../../styles/Paginate.module.css';
import './select.css';
import dayjs from 'dayjs';

const Layout = ({
  loading,
  hashes,
  campaign,
  onPageSizeChange,
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  search,
  searchTerm,
  onSearchFieldChange,
  onClickGoBack,
}) => {
  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;
  };

  const pageSizes = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  return (
    <PanelContainer
      headerMenu
      headerTitle={'Códigos de compra da campanha ' + campaign}
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <div className={styles.header}>
        <Button
          tertiary
          icon={<LeftArrow />}
          text="ver campanha"
          className={styles.back}
          onClick={onClickGoBack}
        />
      </div>
      <Block className={styles.block}>
        <div className={styles.search}>
          <Button secondary text="Filtrar" className={styles.filter} onClick={search} />
          <Input
            name="search"
            value={searchTerm}
            placeholder="Buscar hash"
            className={styles.input}
            onChange={onSearchFieldChange}
          />
        </div>
        <div className={styles.hashes}>
          {!loading ? (
            <>
              {hashes.length > 0 ? (
                <>
                  <div className={styles.header}>
                    <p>Código</p>
                    <p>Criado em:</p>
                    <p>Atualizado em:</p>
                    <p>Ativo</p>
                    <p>Pedido</p>
                  </div>
                  {hashes.map((hash, key) => (
                    <div className={styles.hash} key={key}>
                      <div className={styles.number}>
                        <h2 title={hash.id}>{hash.id.match(/.{1,12}/g)[0]}</h2>
                      </div>
                      <div>
                        <p>{formatDate(hash.creation_date)}</p>
                      </div>
                      <div className={styles.name}>
                        <p>{formatDate(hash.update_date)}</p>
                      </div>
                      <div>
                        <div>
                          <p>{hash.active ? 'Sim' : 'Não'}</p>
                        </div>
                      </div>
                      <div>
                        <h2>{hash.order_id !== null ? hash.order_id : null}</h2>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className={styles.notFound}>Nenhum código encontrado</p>
              )}
            </>
          ) : (
            <LoadingDots className={styles.loading} />
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.limit}>
            <JFYSelect
              options={pageSizes}
              defaultValue={
                pageSizes.find((page) => page.value === parseInt(pageSize)) || pageSizes[0]
              }
              isSearchable={false}
              onChange={onPageSizeChange}
            />
            <p>Itens por página</p>
          </div>
          <div className={styles.pages}>
            <ReactPaginate
              pageCount={totalPages}
              nextLabel={<LeftArrow />}
              previousLabel={<LeftArrow />}
              initialPage={parseInt(currentPage) || undefined}
              previousClassName={paginateStyles.paginationPreviousLink}
              containerClassName={paginateStyles.pagination}
              previousLinkClassName={paginateStyles.paginationLink}
              nextLinkClassName={paginateStyles.paginationLink + ' ' + paginateStyles.next}
              activeClassName={paginateStyles.paginationLinkActive}
              onPageChange={onPageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import Select from 'react-select';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import styles from './ProductsList.module.css';

const Layout = ({
  handleQuantityChange,
  handleProductChange,
  handleSubtractProduct,
  handleAddProduct,
  productOptions,
  products,
  selectedProducts,
  isLoading,
  disabled
}) => {
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      height: '37px',
    }),
    input: (provided, state) => ({
      ...provided,
      marginTop: '-10px',
    }),
  };
  return (
    <div className={styles.products}>
      {!isLoading && products.map((productField) =>
      (<div className={styles.product} key={productField.id}>
        <Select
          key={productField.id}
          options={productOptions.filter((product) => !selectedProducts.includes(product.value))}
          placeholder="Produto"
          hideSelectedOptions
          className="filter-container"
          classNamePrefix="filter"
          styles={selectStyle}
          isClearable={false}
          isDisabled={disabled}
          onChange={(selected) => handleProductChange(selected, productField.id)}
          defaultValue={productOptions.find((product) => productField.value === product.value)}
        />

        <Input
          name={productField.quantity}
          value={productField.quantity}
          type="number"
          min="1"
          disabled={disabled}
          placeholder="Qtde"
          className={styles.input}
          onChange={(e) => handleQuantityChange(productField.id, e.target.value)}
        />
        {!disabled &&
          <>
            <Button
              secondary
              text=" - "
              className={`${styles.buttonSub} ${products.length === 1 ? styles.hidden : ''}`}
              onClick={() => handleSubtractProduct(productField.id)}
            />
            <Button
              secondary
              text=" + "
              className={`${styles.buttonAdd} ${productOptions.filter((product) => !selectedProducts.includes(product.value)).length
                ? ''
                : styles.hidden
                }`}
              onClick={handleAddProduct}
            />
          </>
        }
      </div>)
      )}
    </div>
  );
};

export default Layout;

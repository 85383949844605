export class BadRequestError extends Error {
  constructor(message, responseJson) {
    super(message);
    this.name = 'BadRequestError';
    this.responseJson = responseJson;
  }
}

export class NetworkResponseWasNotOk extends Error {
  constructor(method, url, realError) {
    super(`Failed to *${method}* \`${url}\``);
    this.name = 'NetworkError';
    this.realError = realError;
  }
}

import Select from 'react-select';
import './select.css';

export const JFYSelect = ({
  options,
  defaultValue,
  isSearchable,
  onChange,
  optionsPlacement = 'top',
  className = 'select-container',
  classNamePrefix = 'select',
  ...props
}) => {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      className={className}
      classNamePrefix={classNamePrefix}
      isSearchable={isSearchable}
      menuPlacement={optionsPlacement}
      onChange={onChange}
      {...props}
    />
  );
};

export default JFYSelect;

export const existingUrlFilters = (urlFilters, includeSearch = false) => {
  let filtersExist = Object.keys(urlFilters).find((urlKey) => urlFilters[urlKey]);

  if (includeSearch) {
    filtersExist = filtersExist || urlFilters.search;
  }

  return filtersExist;
};

export const formatValueToPattern = (filterByType, field, value) => {
  const pattersRegex = [
    {
      regex: /[^0-9]/g,
      field: 'search',
      filterByType: 'order',
    },
  ];
  const patternSeleted = pattersRegex.find(
    (pattern) => pattern.field === field && pattern.filterByType === filterByType
  );

  if (patternSeleted) {
    return value.replace(patternSeleted.regex, '');
  }

  return value;
};

import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { APP_ROUTES } from './appRoutes';
import Unauthorized from '../pages/Unauthorized';

const CustomRoute = ({ route }) => {
  const authContext = useContext(AuthContext);

  if (route.auth.required && !authContext.signed) {
    return <Navigate to={APP_ROUTES.login.path} />;
  } else {
    if (!route.auth.denyList?.includes(authContext?.role)) {
      return route.element;
    } else {
      return <Unauthorized />;
    }
  }
};

export default CustomRoute;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { noSpaceMask } from '../../utils/formater';
import dayjs from 'dayjs';
import Layout from './Layout';

const Campaign = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange, setFieldValue } = useForm({
    active: {
      required: false,
    },
    name: {
      required: true,
      mask: noSpaceMask,
    },
    date: {
      required: false,
    },
    voucher: {
      required: false,
      mask: noSpaceMask,
    },
    utmCampaign: {
      required: false,
    },
    utmSource: {
      required: false,
    },
    utmMedium: {
      required: false,
    },
    utmContent: {
      required: false,
    },
    utmTerm: {
      required: false,
    }
  });

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/vouchers');
  };

  const formatDate = (date) => {
    if (date) {
      return dayjs(date.split('+')[0]).subtract(3, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return null;
    }
  };


  useEffect(() => {
    if (!campaign) {
      setLoading(true);
      fetchFromBackend(`/campaigns/${id}`, 'GET', {}, true)
        .then((response) => {
          setCampaign(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      const campaignInfo = campaign.campaign;
      setFieldValue('active', campaignInfo.active ? 'true' : 'false');
      setFieldValue('name', campaignInfo.campaign_name);
      setFieldValue('date', formatDate(campaignInfo.creation_datetime));
      setFieldValue('voucher', campaignInfo.voucher_code);
      setFieldValue('utmCampaign', campaignInfo.utm_campaign);
      setFieldValue('utmSource', campaignInfo.utm_source);
      setFieldValue('utmMedium', campaignInfo.utm_medium);
      setFieldValue('utmContent', campaignInfo.utm_content);
      setFieldValue('utmTerm', campaignInfo.utm_term);

      // populate products state so it will be used in child component
      let productFields = [];
      campaign.products.forEach((product) => {
        productFields.push({
          id: String(product.product_id),
          value: String(product.product_id),
          quantity: String(product.quantity),
        });
      });
      setProducts(productFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  return (
    <Layout
      campaign={campaign}
      fields={fields}
      handleChange={handleChange}
      loading={loading}
      onClickGoBack={handleGoBack}
      products={products}
      setProducts={setProducts}
    />
  );
};

export default Campaign;

import styles from './Button.module.css';

const Button = ({
  text = 'Botão',
  primary = false,
  secondary = false,
  tertiary = false,
  loading = false,
  disabled = false,
  icon,
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      onClick={(disabled || loading) && onClick ? null : onClick}
      disabled={disabled}
      className={
        styles.btn +
        (primary ? ' ' + styles.primary : '') +
        (secondary ? ' ' + styles.secondary : '') +
        (tertiary ? ' ' + styles.tertiary : '') +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      {icon && icon}
      <span>{loading ? 'Processando...' : text}</span>
    </button>
  );
};

export default Button;

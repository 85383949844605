import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const Square = styled.div`
  width: 7px;
  height: 7px;
  min-width: 7px;
  background:${({ active }) => active ? 'var(--color-dark-salmon)' : 'var(--color-silver)'};

  cursor: pointer;
`;

export const Info = styled.h4`
  margin: 0px 7px 0px 7px;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: ${({ active }) => active ? '700' : '400'};
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 11px;
  }


  @media (max-width: 425px) {
    font-size: 12px;
    padding: 2px;
  }
`;

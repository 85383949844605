import { PanelContainer, Block } from '../../../components/PanelContainer';
import formatPrice from '../../../utils/formatPrice';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import styles from './Cart.module.css';
import Button from '../../../components/Button';

dayjs.extend(dayjsBusinessDays);

const Layout = ({ cart, onClickGoBack }) => {
  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;
  };

  return (
    <PanelContainer
      headerMenu
      className={styles.container}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      {cart && (
        <>
          <Button
            tertiary
            icon={<ArrowLeft />}
            text="ver todos os carrinhos"
            className={styles.back}
            onClick={onClickGoBack}
          />
          <Block className={styles.block + ' ' + styles.header}>
            <div>
              <h2>{formatDate(cart.cart.creation_date)}</h2>
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.row}>
              <div className={styles.client}>
                <h2>CLIENTE</h2>
                <p>
                  <b>{cart.cart.customer_name}</b>
                </p>
                <p>{cart.cart.customer_email}</p>
                {cart.cart.customer_phone && (
                  <>
                    <a
                      href={
                        'https://api.whatsapp.com/send?phone=55' +
                        cart.cart.customer_ddd +
                        cart.cart.customer_phone
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.phone}
                    >
                      <WhatsAppIcon />
                      <p>{formatPhoneNumber(cart.cart.customer_ddd, cart.cart.customer_phone)}</p>
                    </a>
                  </>
                )}
              </div>
              <div className={styles.total}>
                <h2>TOTAL</h2>
                <h3>{formatPrice(cart.absolute_value)}</h3>
              </div>
              <div className={styles.discounts}>
                <h2>DESCONTOS</h2>
                {cart.voucher?.code && (
                  <p>
                    Cupom:{' '}
                    <a
                      href={'/vouchers/' + cart.voucher.code}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.promocode}
                    >
                      {cart.voucher.code}
                    </a>
                    {cart.voucher.is_percent && ` (${cart.voucher.discount_value}%)`}
                  </p>
                )}
                <p>
                  Produtos: <b>{formatPrice(cart.total_value)}</b>
                </p>
                {cart.voucher?.code && (
                  <p>
                    Valor Cupom:{' '}
                    <b>
                      -
                      {formatPrice(
                        cart.voucher.is_percent
                          ? (cart.total_value * cart.voucher.discount_value) / 100
                          : cart.voucher.discount_value
                      )}
                    </b>
                  </p>
                )}
                <p>
                  Frete:
                  {cart.cart.cart_shipment_cost > 0 ? (
                    <b> +{formatPrice(cart.cart.cart_shipment_cost)}</b>
                  ) : (
                    <b className={styles.free}> Grátis</b>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.delivery}>
                <div>
                  <h2>ENDEREÇO DE ENTREGA</h2>
                  <p>CEP: {cart.cart.cart_zipcode ? cart.cart.cart_zipcode : '-'}</p>
                </div>
                <div>
                  <h2>TRANSPORTADORA</h2>
                  <p>{cart.cart.carrier_name}</p>
                </div>
              </div>
              <div className={styles.tracking}>
                {(cart.cart?.utm_source ||
                  cart.cart?.utm_campaign ||
                  cart.cart?.utm_medium ||
                  cart.cart?.utm_content ||
                  cart.cart?.utm_term) && (
                  <>
                    <h2>TRACKING</h2>
                    {cart.cart?.utm_source && (
                      <p>
                        Origem: <b>{cart.cart?.utm_source}</b>
                      </p>
                    )}
                    {cart.cart?.utm_campaign && (
                      <p>
                        Campanha: <b>{cart.cart?.utm_campaign}</b>
                      </p>
                    )}
                    {cart.cart?.utm_medium && (
                      <p>
                        Meio: <b>{cart.cart?.utm_medium}</b>
                      </p>
                    )}
                    {cart.cart?.utm_content && (
                      <p>
                        Conteúdo: <b>{cart.cart?.utm_content}</b>
                      </p>
                    )}
                    {cart.cart?.utm_term && (
                      <p>
                        Termo: <b>{cart.cart?.utm_term}</b>
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles.products}>
              <h2>PRODUTOS</h2>
              {cart.products.map((form, key) => (
                <div className={styles.form} key={key}>
                  <p>
                    Fórmula: <b>{form.form_name}</b>
                  </p>
                  <p>ID: {form.form_id}</p>
                  <div className={styles.formProducts}>
                    {form.items.map((product, key) => (
                      <div className={styles.product} key={key}>
                        <p>
                          <b>{product.quantity}x</b> {product.product_display_name}
                        </p>
                        <p>{formatPrice(product.value)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Block>
        </>
      )}
    </PanelContainer>
  );
};

export default Layout;

import PanelContainer from '../../components/PanelContainer';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';

import styles from './Unauthorized.module.css';

const Unauthorized = () => {
  return (
    <PanelContainer headerMenu>
      <div className={styles.unauthorized}>
        <h1>Acesso Negado</h1>
        <LockIcon />
      </div>
    </PanelContainer>
  );
};

export default Unauthorized;

import React from 'react';
import Select from 'react-select';

import { Container, Input, SearchIcon } from './styles';
import './select.css';

export const InputSelection = ({
  optionsSelect,
  placeholder,
  name,
  onChange,
  value,
  stylesSelect = {},
  onChangeSelect,
  defaultValueSelect,
  classNameSelect = 'input-selection-container',
  classNameSelectPrefix = 'input-selection',
  classNameContainer = 'input-container'
}) => {
  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: '#808080',
    }),
  };

  return (
    <Container className={classNameContainer}>
      <Select
        className={classNameSelect}
        classNamePrefix={classNameSelectPrefix}
        options={optionsSelect}
        styles={{ ...stylesSelect, customStyles }}
        defaultValue={defaultValueSelect}
        onChange={onChangeSelect}
        hideSelectedOptions
      />
      <Input placeholder={placeholder} className='input-search' onChange={onChange} name={name} value={value} />
      <SearchIcon />
    </Container>
  );
};

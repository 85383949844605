import { useEffect, useState, useRef } from 'react';
import { useSearchParams, createSearchParams, useNavigate } from 'react-router-dom';
import { useQuery, focusManager } from 'react-query';
import dayjs from 'dayjs';

import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';

import getUrlParams from '../../utils/urlParamsHandler';
import notify, { INTERNAL_ERROR } from '../../utils/notify';

import { existingUrlFilters, formatValueToPattern } from './ordersHelper';

import Layout from './Layout';


const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const voucherSelectRef = useRef(null);
  const navigate = useNavigate();
  const carriersSelectRef = useRef(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingCarriers, setLoadingCarriers] = useState(false);
  const [loadingVouchers, setLoadingVouchers] = useState({
    dataReady: false,
    fetching: false,
  });
  const [filteredVoucher, setFilteredVoucher] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalOrders, setTotalOrders] = useState();
  const [metrics, setMetrics] = useState();
  const [statuses, setStatuses] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const { fetchFromBackend } = useFetch();
  const [urlFilters] = useState(
    getUrlParams(searchParams, [
      'search',
      'voucher',
      'payment',
      'status',
      'source',
      'today',
      'week',
      'month',
      'year',
      'start',
      'end',
      'carrier',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'page',
      'limit',
      'filter_by',
    ])
  );

  const defaultStartDate = dayjs(new Date()).format('YYYY-MM-DD');
  const defaultPageSize = 10;
  const defaultPage = 0;
  const [defaultFilterApplied, setDefaultFilterApplied] = useState(false);
  const [pageSize, setPageSize] = useState(urlFilters.limit || defaultPageSize);
  const [filterControlIsOpen, setFilterControlIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(urlFilters.page || defaultPage);
  const [vouchers, setVouchers] = useState([]);

  const { fields, handleChange, setFieldValue } = useForm({
    search: {
      required: false,
      value: urlFilters.search,
    },
    filter_by: {
      required: false,
      value: urlFilters.filter_by || 'client_name',
    },
    status: {
      required: false,
      value:
        urlFilters.status?.split(',')?.length > 0 ? urlFilters.status?.split(',') : [],
    },
    today: {
      required: false,
      value:
        urlFilters.today ||
        (!existingUrlFilters(urlFilters, true) ? defaultStartDate : ''),
    },
    week: {
      required: false,
      value: urlFilters.week,
    },
    month: {
      required: false,
      value: urlFilters.month,
    },
    year: {
      required: false,
      value: urlFilters.year,
    },
    start: {
      required: false,
      value: urlFilters.start,
    },
    end: {
      required: false,
      value: urlFilters.end,
    },
    payment: {
      required: false,
      value: urlFilters.payment,
    },
    voucher: {
      required: false,
      value: urlFilters.voucher,
    },
    source: {
      required: false,
      value: urlFilters.source,
    },
    carrier: {
      required: false,
      value: urlFilters.carrier,
    },
    utm_source: {
      required: false,
      value: urlFilters.utm_source,
    },
    utm_medium: {
      required: false,
      value: urlFilters.utm_medium,
    },
    utm_campaign: {
      required: false,
      value: urlFilters.utm_campaign,
    },
  });
  const dateFields = ['today', 'week', 'month', 'year', 'start'];
  const selectedFieldUrl = dateFields.find((key) => fields[key]?.value);
  const seletedFieldFilter = useRef(selectedFieldUrl);

  const ordersSource = [
    { value: 'checkout', label: 'Checkout' },
    { value: 'yampi', label: 'Yampi' },
  ];

  const paymentMethodsOptions = [
    { value: 'pix', label: 'Pix' },
    { value: 'credit_card', label: 'Cartão de Crédito' },
    { value: 'hash', label: 'Hash' },
  ];

  const handleClearFilters = () => {
    //reset selection to today
    seletedFieldFilter.current = 'today';

    Object.keys(fields).map((key) => {
      const isArray = Array.isArray(fields[key].value);
      return isArray ? setFieldValue(key, []) : setFieldValue(key, '');
    });

    //reset seleted dropdown
    setFilteredVoucher('');
    voucherSelectRef.current.clearValue();
    carriersSelectRef.current.clearValue();

    fetchVouchers();
  };

  const handleControllFilters = () => {
    setFilterControlIsOpen(!filterControlIsOpen);
  };

  const handleChangeFilterValue = (field, value, useRange) => {
    const rangedFields = ['start', 'end'];

    //remove another filters
    if (!useRange || !rangedFields.includes(seletedFieldFilter.current))
      Object.values([...dateFields, 'end']).map((key) => setFieldValue(key, ''));

    //define end date
    if (!rangedFields.includes(field))
      setFieldValue('end', dayjs().startOf('day').format('YYYY-MM-DD'));

    seletedFieldFilter.current = field;
    setFieldValue(field, value);
  };

  const handleChangeSelectedFilter = (target, value) => {
    //remove selection
    if (fields[target].value === value) return setFieldValue(target, '');
    setFieldValue(target, value);
  };

  const handleClickOpenOrder = (path) => {
    navigate(path);
  };

  const parseSearchParams = (params = {}, mergeDateFilters = false) => {
    Object.entries(fields).forEach((field) => {
      const key = field[0];
      const value = field[1].value;
      if (value) {
        if (typeof value === 'object') {
          const validValues = value.filter((v) => v !== '');
          if (validValues.length > 0) params[key] = validValues.join(',');
        } else if (value !== '') {
          if (
            mergeDateFilters &&
            dateFields.includes(key) &&
            !params[seletedFieldFilter]
          ) {
            params.start = value;
          } else {
            params[key] = formatValueToPattern(fields.filter_by?.value,key, value);
          }
        }
      }
    });
    if (pageSize !== defaultPageSize) params.limit = pageSize;
    if (currentPage !== defaultPage) params.page = currentPage;
    return createSearchParams(params);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.value);
  };

  const toggleDefaultFilters = () => {
    const appliedFilters = [...parseSearchParams().entries()];
    if (appliedFilters.length === 0) {
      handleChange({ target: { name: 'today', value: defaultStartDate } });
      setDefaultFilterApplied(true);
    } else {
      const startDateFilter = appliedFilters.find((filter) => filter[0] === 'today');
      const searchFilter = appliedFilters.find((filter) => filter[0] === 'search');
      if (
        startDateFilter &&
        appliedFilters.length > 1 &&
        startDateFilter[1] === defaultStartDate &&
        defaultFilterApplied &&
        searchFilter
      ) {
        handleChange({ target: { name: 'today', value: '' } });
        setDefaultFilterApplied(false);
      } else if (
        startDateFilter &&
        appliedFilters.length === 1 &&
        startDateFilter[1] === defaultStartDate
      ) {
        setDefaultFilterApplied(true);
      }
    }
  };

  const handleSelectChange = (targetValue, targetName, valueInArray = false) => {
    let fieldValue = fields[targetName].value;

    if (valueInArray) {
      if (fieldValue.includes(targetValue)) {
        fieldValue.splice(fieldValue.indexOf(targetValue), 1);
      } else {
        fieldValue.push(targetValue);
      }
    } else {
      if (targetValue[0]) {
        fieldValue = targetValue[0].value;
      } else {
        fieldValue = '';
      }
    }

    handleChange({ target: { name: targetName, value: fieldValue } });
  };

  const fetchStatus = () => {
    setLoadingStatus(true);
    fetchFromBackend('/orders/status/all', 'GET', {}, true)
      .then((response) => {
        let parsedResponse = [];
        Object.entries(response).forEach((status) => {
          if (status[1].id) {
            parsedResponse.push({
              value: status[1].id,
              label: status[1].label,
            });
          }
        });
        setStatuses(parsedResponse);
        setLoadingStatus(false);
      })
      .catch((error) => {
        setLoadingStatus(false);
        notify(INTERNAL_ERROR);
      });
  };

  const fetchVouchers = (voucher = '', limit = pageSize, page = currentPage) => {
    setLoadingVouchers({ dataReady: loadingVouchers.dataReady, fetching: true });

    const requestURL = decodeURIComponent(
      `/vouchers/filter/general?${createSearchParams({
        search: voucher,
        limit: limit,
        page,
      })}`
    );
    fetchFromBackend(requestURL, 'GET', {}, true)
      .then((response) => {
        let parsedResponse = [];
        Object.entries(response.data).forEach((status) => {
          if (status[1].id) {
            parsedResponse.push({
              value: status[1].code,
              label: status[1].code.toUpperCase(),
            });
          }
        });

        if (
          fields.voucher?.value &&
          !parsedResponse.find((res) => res.value === fields.voucher?.value)
        ) {
          parsedResponse.push({
            value: fields.voucher?.value,
            label: fields.voucher?.value.toUpperCase(),
          });
        }

        setVouchers(parsedResponse);
        setLoadingVouchers({ dataReady: true, fetching: false });
      })
      .catch((error) => {
        notify(INTERNAL_ERROR);
        setLoadingVouchers({ dataReady: true, fetching: false });
      });
  };

  const fetchCarriers = () => {
    setLoadingCarriers(true);
    fetchFromBackend('/logistics/carriers', 'GET', {}, true)
      .then((response) => {
        let parsedResponse = [];
        Object.entries(response).forEach((carrier) => {
          if (carrier[1].id) {
            parsedResponse.push({
              value: carrier[1].id,
              label: carrier[1].carrier_name,
            });
          }
        });
        setCarriers(parsedResponse);
        setLoadingCarriers(false);
      })
      .catch((error) => {
        setLoadingCarriers(false);
        notify(INTERNAL_ERROR);
      });
  };

  const fetchOrderOnError = (response) => {
    //desable refresh data on error
    focusManager.setFocused(false);
    setLoading(false);
    notify(INTERNAL_ERROR);
  };
  const fetchOrderOnSuccess = (response) => {
    //reset refresh data to default
    focusManager.setFocused(undefined);

    setOrders(response.data);
    setTotalPages(Math.ceil(response.total / response.limit));
    setTotalOrders(response.total);
    setMetrics(response.metrics);
    setLoading(false);
  };

  const fetchOrders = async () => {
    const searchParams = parseSearchParams({ metrics: true }, true);
    const requestURL = decodeURIComponent(`/orders/filter/general?${searchParams}`);

    const result = await fetchFromBackend(requestURL, 'GET', {}, true).then(
      (response) => {
        return response;
      }
    );
    return result;
  };

  const { refetch: getOrders } = useQuery('orders', fetchOrders, {
    onError: fetchOrderOnError,
    onSuccess: fetchOrderOnSuccess,
    retry: false,
  });

  useEffect(() => {
    !loading && setLoading(true);
    toggleDefaultFilters();
    const filterDelayDebounce = setTimeout(async () => {
      getOrders();
      setSearchParams(parseSearchParams());
    }, 1200);
    return () => clearTimeout(filterDelayDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, currentPage, pageSize]);

  useEffect(() => {
    if (statuses.length === 0) fetchStatus();
    if (carriers.length === 0) fetchCarriers();
    if (vouchers.length === 0) fetchVouchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses]);

  useEffect(() => {
    if (filteredVoucher.length > 0) fetchVouchers(filteredVoucher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredVoucher]);

  return (
    <Layout
      voucherSelectRef={voucherSelectRef}
      carriersSelectRef={carriersSelectRef}
      orders={orders}
      loading={loading}
      loadingStatus={loadingStatus}
      loadingCarriers={loadingCarriers}
      totalPages={totalPages}
      pageSize={pageSize}
      totalOrders={totalOrders}
      metrics={metrics}
      onPageSizeChange={handlePageSizeChange}
      currentPage={currentPage}
      onPageChange={handlePageChange}
      statuses={statuses}
      carriers={carriers}
      ordersSource={ordersSource}
      paymentMethodsOptions={paymentMethodsOptions}
      fields={fields}
      vouchers={vouchers}
      handleChange={handleChange}
      handleSelectChange={handleSelectChange}
      setFieldValue={setFieldValue}
      seletedFieldFilter={seletedFieldFilter}
      handleChangeFilterValue={handleChangeFilterValue}
      handleClearFilters={handleClearFilters}
      handleControllFilters={handleControllFilters}
      filterControlIsOpen={filterControlIsOpen}
      handleChangeSelectedFilter={handleChangeSelectedFilter}
      setFilteredVoucher={setFilteredVoucher}
      filteredVoucher={filteredVoucher}
      loadingVouchers={loadingVouchers}
      handleClickOpenOrder={handleClickOpenOrder}
    />
  );
};

export default Orders;

import styles from './ProductCard.module.css';

const Layout = ({ product }) => {
  return (
    <div className={styles.product} key={'product-preview'}>
      <div className={styles.wrapper}>
        <div className={styles.imageWrap}>
          {
            product?.url ? <img
              src={product.url}
              alt={`Frasco de ${product.product_display_name} Personalizado JustForYou`}
            /> : null
          }

        </div>
        <div className={styles.details}>
          <h3 className={styles.name}>{(product?.product_display_name) ? product.product_display_name : ''}</h3>
          <p className={styles.description}>{(product?.product_size) ? product.product_size : ''}</p>
          <h3 className={styles.price}>{(product?.value) ? product.value : ''}</h3>
        </div>
      </div>
    </div>
  );
};

export default Layout;

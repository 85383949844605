import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import ReactPaginate from 'react-paginate';

import { PanelContainer } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import JFYSelect from '../../../components/Select';
import LoadingDots from '../../../components/LoadingDots';
import { OrderHeader } from '../../../components/OrderHeader';
import {
  ListUniqueSelection,
  ListMultSelection,
} from '../../../components/ListSelection';
import { FilterControl } from '../../../components/FilterControl';
import { Status } from '../../../components/Status';
import { InputSelection } from '../../../components/InputSelection';

import formatPrice from '../../../utils/formatPrice';

import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left.svg';

import styles from './Orders.module.css';
import paginateStyles from '../../../styles/Paginate.module.css';
import './select.css';
import {
  Block,
  FilterContainer,
  Left,
  Right,
  Footer,
  Header,
  Label,
  StatusContainer,
  MoreFiltersContainer,
  BlockUtm,
  Select,
  Grid,
  GridHeader,
  GridLine,
  Text,
  Link,
  QueryInfoContainer,
  HeaderWithLoading,
} from './styles';

const Layout = ({
  orders,
  pageSize,
  currentPage,
  totalPages,
  totalOrders,
  metrics,
  loading,
  fetching,
  loadingStatus,
  loadingCarriers,
  onPageSizeChange,
  onPageChange,
  statuses,
  carriers,
  fields,
  handleChange,
  ordersSource,
  paymentMethodsOptions,
  handleSelectChange,
  setFieldValue,
  handleChangeFilterValue,
  seletedFieldFilter,
  handleClearFilters,
  handleControllFilters,
  filterControlIsOpen,
  handleChangeSelectedFilter,
  vouchers,
  setFilteredVoucher,
  loadingVouchers,
  voucherSelectRef,
  carriersSelectRef,
  handleClickOpenOrder,
}) => {
  const pageSizes = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];

  const optionsSelect = [
    { value: 'cpf', label: 'CPF' },
    { value: 'order', label: 'Nº Pedido' },
    { value: 'email', label: 'E-mail' },
    { value: 'client_name', label: 'Nome' },
  ];

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <PanelContainer
      headerMenu
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <OrderHeader
        fields={fields}
        setFieldValue={setFieldValue}
        handleChangeFilterValue={handleChangeFilterValue}
        seletedFieldFilter={seletedFieldFilter}
        metrics={metrics}
        totalOrders={totalOrders}
      />
      <Block
        removeBorder={filterControlIsOpen}
        enablePaddingBottom={!filterControlIsOpen}
      >
        <FilterContainer>
          <Left>
            <Header>
              <Label>Busca</Label>
              <InputSelection
                name='search'
                value={fields.search?.value}
                onChange={handleChange}
                placeholder='Buscar por número do pedido, e-mail, CPF...'
                optionsSelect={optionsSelect}
                defaultValueSelect={optionsSelect.find(
                  (type) => type.value === fields.filter_by?.value
                )}
                onChangeSelect={(selected) => handleSelectChange([selected], 'filter_by')}
              />
            </Header>
            <Footer removeMarginBottom>
              <div>
                <HeaderWithLoading>
                  <Label>Cupom</Label>
                  {loadingVouchers.fetching && (
                    <LoadingDots dotHeight='10px' dotWidth='10px' />
                  )}
                </HeaderWithLoading>
                {loadingVouchers.dataReady ? (
                  <Select
                    options={vouchers}
                    ref={voucherSelectRef}
                    hideSelectedOptions
                    className='filter-container'
                    classNamePrefix='filter'
                    isClearable={false}
                    placeholder='Ex: JUSTFOR40'
                    onBlur={({ target }) => {
                      if (!vouchers.find((voucher) => voucher.value === target.value)) {
                        setFilteredVoucher('');
                      }
                    }}
                    defaultValue={vouchers.find(
                      (voucher) => fields.voucher?.value === voucher.value.toString()
                    )}
                    onKeyDown={({ target }) => {
                      setFilteredVoucher(target.value);
                    }}
                    onChange={(selected) => handleSelectChange([selected], 'voucher')}
                  />
                ) : (
                  <LoadingDots dotHeight='10px' dotWidth='10px' />
                )}
              </div>
              <div>
                <Label>Pagamento</Label>
                <ListUniqueSelection
                  data={paymentMethodsOptions}
                  selected={fields.payment.value}
                  handleClick={(value) => {
                    handleChangeSelectedFilter('payment', value);
                  }}
                />
              </div>
            </Footer>
          </Left>
          <Right>
            <Header>
              <HeaderWithLoading>
                <Label>Status</Label>
                {loadingStatus && <LoadingDots dotHeight='10px' dotWidth='10px' />}
              </HeaderWithLoading>
              <StatusContainer>
                <ListMultSelection
                  data={statuses}
                  name='status'
                  handleClick={handleSelectChange}
                  selected={fields.status?.value}
                />
              </StatusContainer>
            </Header>
            <Footer numColumns={1} removeMarginBottom>
              {!filterControlIsOpen && (
                <FilterControl
                  handleClearFilters={handleClearFilters}
                  handleControllFilters={handleControllFilters}
                  isOpen={filterControlIsOpen}
                />
              )}
            </Footer>
          </Right>
        </FilterContainer>
      </Block>
      {filterControlIsOpen && (
        <>
          <BlockUtm removeBorder>
            <MoreFiltersContainer>
              <div>
                <Header>
                  <Label>UTM:</Label>
                </Header>
                <Footer numColumns={3} gap='7px' removeMarginTop>
                  <Input
                    name='utm_source'
                    value={fields.utm_source?.value}
                    onChange={handleChange}
                    placeholder='Origem'
                    type='text'
                    className={styles.input}
                  />
                  <Input
                    name='utm_medium'
                    value={fields.utm_medium?.value}
                    onChange={handleChange}
                    placeholder='Meio'
                    type='text'
                    className={styles.input}
                  />
                  <Input
                    name='utm_campaign'
                    value={fields.utm_campaign?.value}
                    onChange={handleChange}
                    placeholder='Campanha'
                    type='text'
                    className={styles.input}
                  />
                </Footer>
              </div>
              <div>
                <HeaderWithLoading>
                  <Label>Transportadora:</Label>
                  {loadingCarriers && <LoadingDots dotHeight='10px' dotWidth='10px' />}
                </HeaderWithLoading>
                <Footer numColumns={1} removeMarginTop>
                  <Select
                    placeholder='Transportadora'
                    options={carriers}
                    ref={carriersSelectRef}
                    defaultValue={carriers.find(
                      (carrier) => fields.carrier?.value === carrier.value.toString()
                    )}
                    onChange={(selected) => handleSelectChange([selected], 'carrier')}
                    hideSelectedOptions
                  />
                </Footer>
              </div>
              <div>
                <Header>
                  <Label>Canal:</Label>
                </Header>
                <ListUniqueSelection
                  data={ordersSource}
                  selected={fields.source.value}
                  handleClick={(value) => {
                    handleChangeSelectedFilter('source', value);
                  }}
                />
              </div>
            </MoreFiltersContainer>
          </BlockUtm>
          <BlockUtm>
            <Footer numColumns={1}>
              {filterControlIsOpen && (
                <FilterControl
                  handleClearFilters={handleClearFilters}
                  handleControllFilters={handleControllFilters}
                  isOpen={filterControlIsOpen}
                />
              )}
            </Footer>
          </BlockUtm>
        </>
      )}
      <Block>
        <GridHeader>
          <Label>Numero do Pedido</Label>
          <Label aling='center'>Status</Label>
          <Label aling='center'>Cliente</Label>
          <Label aling='center'>Tipo de Pagamento</Label>
          <Label aling='center'>Valor</Label>
          <Label aling='center'>Data de Criação</Label>
        </GridHeader>
        <Grid>
          {!loading && orders.length > 0 ? (
            orders.map((order, key) => (
              <GridLine
                key={key}
                onClick={() => {
                  isMobile &&
                    handleClickOpenOrder({
                      pathname: '/orders/' + order.internal_id,
                      search: window.location.search,
                    });
                }}
              >
                <Link
                  to={{
                    pathname: '/orders/' + order.internal_id,
                    search: window.location.search,
                  }}
                  aling='start'
                >
                  {order.order_id}
                </Link>
                <Status active>{order.status}</Status>
                <Text>{order.customer_name}</Text>
                <Text>
                  {
                    paymentMethodsOptions.find(
                      (method) => method.value === order.payment_type
                    ).label
                  }
                </Text>
                <Text>{formatPrice(order.final_value)}</Text>
                <Text>{formatDate(order.creation_date)}</Text>
              </GridLine>
            ))
          ) : (
            <QueryInfoContainer>
              {!loading && orders.length === 0 ? (
                <Text>Nenhum pedido encontrado</Text>
              ) : (
                <LoadingDots />
              )}
            </QueryInfoContainer>
          )}
        </Grid>
      </Block>
      <Block>
        <div className={styles.footer}>
          <div className={styles.limit}>
            <JFYSelect
              options={pageSizes}
              defaultValue={
                pageSizes.find((page) => page.value === parseInt(pageSize)) ||
                pageSizes[0]
              }
              isSearchable={false}
              onChange={onPageSizeChange}
            />
            <p>Itens por página</p>
          </div>
          {fetching && <LoadingDots />}
          <div className={styles.pages}>
            <ReactPaginate
              pageCount={totalPages}
              nextLabel={<LeftArrow />}
              previousLabel={<LeftArrow />}
              initialPage={parseInt(currentPage) || undefined}
              previousClassName={paginateStyles.paginationPreviousLink}
              containerClassName={paginateStyles.pagination}
              previousLinkClassName={paginateStyles.paginationLink}
              nextLinkClassName={
                paginateStyles.paginationLink + ' ' + paginateStyles.next
              }
              activeClassName={paginateStyles.paginationLinkActive}
              onPageChange={onPageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

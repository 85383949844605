import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { noSpecialCharsTrimMask } from '../../utils/formater';
import notify, { MISSING_FIELDS, INTERNAL_ERROR } from '../../utils/notify';
import Layout from './Layout';

const AddCampaign = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([{ id: '1', value: '', quantity: '' }]);
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange, handleFinish } = useForm({
    name: {
      required: true,
      mask: noSpecialCharsTrimMask,
    },
    voucher: {
      required: false,
      mask: noSpecialCharsTrimMask,
    },
    utmCampaign: {
      required: true,
      mask: noSpecialCharsTrimMask,
    },
    utmSource: {
      required: false,
      mask: noSpecialCharsTrimMask,
    },
    utmMedium: {
      required: false,
      mask: noSpecialCharsTrimMask,
    },
    utmContent: {
      required: false,
      mask: noSpecialCharsTrimMask,
    },
    utmTerm: {
      required: false,
      mask: noSpecialCharsTrimMask,
    },
    shampoo: {
      required: false,
    },
    conditioner: {
      required: false,
    },
    mask: {
      required: false,
    },
    leavein: {
      required: false,
    },
    antifrizz: {
      required: false,
    },
  });

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/vouchers');
  };

  const getProducts = () => {
    let productsToReturn = [];

    productsToReturn = products.map((product) => ({
      product_id: product.value,
      quantity: parseInt(product.quantity),
    }));

    return productsToReturn;
  };

  const validateProducts = () => {
    // check if any field are empty
    const unfilledProducts = products.filter((product) => !(product.value && product.quantity));
    if (unfilledProducts.length > 0) return false;

    // check for bad quantities
    const quantities = products.flatMap((product) => product.quantity);
    const badQuantities = quantities.filter((quantity) => quantity <= 0);
    if (badQuantities.length > 0) return false;

    return true;
  };

  const createCampaign = async () => {
    const { valid } = await handleFinish();
    const validProducts = validateProducts();

    if (!valid || !validProducts) {
      if (!valid) return notify(MISSING_FIELDS, 'error');
      if (!validProducts) return notify('Necessário incluir ao menos 1 produto', 'error');
    }

    setLoading(true);
    let voucherCode = fields.voucher.value;
    const voucherResponse = await fetchFromBackend(`/vouchers/code/${voucherCode}`, 'GET');
    if (voucherResponse.responseStatus === 200) {
      let voucherId = voucherResponse.voucher.id;
      let productsObject = getProducts();
      const reqBody = {
        token: fields.name.value,
        voucher_id: voucherId,
        products: productsObject.length > 0 ? productsObject : undefined,
        utm_source: fields.utmSource?.value,
        utm_campaign: fields.utmCampaign?.value,
        utm_medium: fields.utmMedium?.value,
        utm_content: fields.utmContent?.value,
        utm_term: fields.utmTerm?.value,
      };
      setLoading(false);
      fetchFromBackend('/campaigns', 'POST', reqBody, true)
        .then((response) => {
          notify('Campanha Criada!', 'success');
          setLoading(false);
        })
        .catch((error) => {
          notify(INTERNAL_ERROR, 'error');
          setLoading(false);
        });
    } else {
      notify('Cupom não encontrado', 'error');
      setLoading(false);
    }
  };

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      loading={loading}
      onClickGoBack={handleGoBack}
      handleClickSave={createCampaign}
      products={products}
      setProducts={setProducts}
    />
  );
};

export default AddCampaign;

import { AuthProvider } from './contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import initializeFirebaseApp from './firebase';
import { queryClient } from './service/queryClient';
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Router from './routes';

import './styles/App.css';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles/Toast.module.css';

initializeFirebaseApp();

const App = () => (
  <>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <AuthProvider>
        <Router />
        <ToastContainer
          autoClose={4300}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className={styles.toast}
          bodyClassName={styles.body}
          progressClassName={styles.progressBar}
        />
      </AuthProvider>
    </QueryClientProvider>

  </>
);

export default App;

import ReactSwitch from 'react-switch';

import './switch.css';

const Switch = ({ className, onChange, checked, disabled = false }) => {
  if (checked === '') checked = false;
  return (
    <ReactSwitch
      className={'jfy-switch ' + className}
      onChange={onChange}
      checked={checked}
      onColor="#f4a27e"
      disabled={disabled}
    />
  );
};

export default Switch;

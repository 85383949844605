import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import formatPrice from '../../utils/formatPrice';

import {
  Header,
  FilterContainer,
  OrderContainer,
  ResumeContainer,
  Title,
  OrdersQuantity,
  Subtitle,
  Selector,
  Filter,
  Square,
  FilterInfo,
  Input,
  Block,
  Group,
} from './styles';

const getDate = (typeDate) => {
  return dayjs().startOf(typeDate).format('YYYY-MM-DD');
};

const filterTypes = [
  {
    label: ['Hoje'],
    useFormField: ['today'],
    useRange: false,
    getDateInfo: () => getDate('day'),
  },
  {
    label: ['Esta semana'],
    useFormField: ['week'],
    useRange: false,
    getDateInfo: () => getDate('week'),
  },
  {
    label: ['Este Mês'],
    useFormField: ['month'],
    useRange: false,
    getDateInfo: () => getDate('month'),
  },
  {
    label: ['Este Ano'],
    useFormField: ['year'],
    useRange: false,
    getDateInfo: () => getDate('year'),
  },
  {
    label: ['De:', 'Até:'],
    useFormField: ['start', 'end'],
    useRange: true,
  },
];

const FilterGroup = ({
  seletedFieldFilter,
  handleChangeFilterValue,
  fields,
  isMobile,
}) => {
  return (
    <FilterContainer>
      <Subtitle>Periodo:</Subtitle>
      <Selector isMobile={isMobile}>
        {filterTypes.map((filter, index) => {
          const activated = filter.useFormField
            .includes(seletedFieldFilter.current)
            .toString();
          if (filter.useRange) {
            return (
              <Filter key={index}>
                <Square active={activated} />
                <Group>
                  <FilterInfo active={activated}>{filter.label[0]}</FilterInfo>
                  <Input
                    active={activated}
                    type='date'
                    name={filter.useFormField[0]}
                    value={fields[filter.useFormField[0]].value}
                    onChange={(e) =>
                      handleChangeFilterValue(
                        filter.useFormField[0],
                        e.target.value,
                        filter.useRange
                      )
                    }
                  />
                </Group>
                <Group>
                  <FilterInfo active={activated}>{filter.label[1]}</FilterInfo>
                  <Input
                    active={activated}
                    type='date'
                    name={filter.useFormField[1]}
                    value={fields[filter.useFormField[1]].value}
                    onChange={(e) =>
                      handleChangeFilterValue(
                        filter.useFormField[1],
                        e.target.value,
                        filter.useRange
                      )
                    }
                  />
                </Group>
              </Filter>
            );
          }

          return (
            <Filter
              key={index}
              onClick={() =>
                handleChangeFilterValue(
                  filter.useFormField[0],
                  filter.getDateInfo(),
                  filter.useRange
                )
              }
            >
              <Square active={activated} />
              <FilterInfo active={activated}>{filter.label[0]}</FilterInfo>
            </Filter>
          );
        })}
      </Selector>
    </FilterContainer>
  );
};

export const OrderHeader = ({
  seletedFieldFilter,
  handleChangeFilterValue,
  totalOrders,
  metrics,
  fields,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      <Block enablePaddingBottom>
        <Header isMobile={isMobile}>
          <OrderContainer>
            <Title>Pedidos</Title>
            <OrdersQuantity>{totalOrders ? totalOrders : 0} pedidos</OrdersQuantity>
          </OrderContainer>
          {!isMobile && (
            <FilterGroup
              seletedFieldFilter={seletedFieldFilter}
              handleChangeFilterValue={handleChangeFilterValue}
              fields={fields}
            />
          )}
          <ResumeContainer>
            <Subtitle aling='end'>
              Ticket Médio:{' '}
              <span>{formatPrice(metrics ? metrics.average_ticket_price : 0)}</span>
            </Subtitle>
            <Subtitle aling='end'>
              Receita: <span>{formatPrice(metrics ? metrics.revenue : 0)}</span>
            </Subtitle>
          </ResumeContainer>
        </Header>
      </Block>
      {isMobile && (
        <Block enablePaddingBottom>
          <FilterGroup
            isMobile={isMobile}
            seletedFieldFilter={seletedFieldFilter}
            handleChangeFilterValue={handleChangeFilterValue}
            fields={fields}
          />
        </Block>
      )}
    </>
  );
};

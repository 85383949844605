import styles from './FileInput.module.css';

const FileInput = ({
  text = 'Botão',
  primary = false,
  secondary = false,
  tertiary = false,
  onClick,
  className,
}) => {
  return (
    <label
      className={
        styles.btn +
        (primary ? ' ' + styles.primary : '') +
        (secondary ? ' ' + styles.secondary : '') +
        (tertiary ? ' ' + styles.tertiary : '') +
        (className ? ' ' + className : '')
      }
    >
      <input type="file" multiple onChange={onClick} />
      {text}
    </label>
  );
};

export default FileInput;

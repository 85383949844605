import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import Layout from './Layout';

const Products = () => {
  const navigate = useNavigate();
  const { fetchFromBackend } = useFetch();
  const [shelfProducts, setShelfProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { fields, handleChange } = useForm({
    search: {
      required: false,
      value: ''
    }
  });
  const handleNewProduct = () => {
    navigate('/products/add');
  };

  const goToProductPage = (productId, newTab) => {
    navigate('/products/' + productId);
  };

  useEffect(() => {
    fetchFromBackend('/products?all_products=true&shelf_products=false', 'GET', {}).then(
      (response) => {
        setProducts(response);
      }
    );
    fetchFromBackend('/products?all_products=true&shelf_products=true', 'GET', {}).then(
      (response) => {
        setShelfProducts(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let search = fields.search.value.toLowerCase();
    let filtered = products.filter((element) => {
      return element.prd_dspnam?.toLowerCase().includes(search);
    });

    setFilteredProducts(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.search.value]);

  return (
    <Layout
      handleNewProduct={handleNewProduct}
      shelfProducts={shelfProducts}
      products={products}
      handleClick={goToProductPage}
      fields={fields}
      filteredProducts={filteredProducts}
      handleChange={handleChange}
    />
  );
};

export default Products;

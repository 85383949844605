import { useRef } from 'react';

import styles from './Block.module.css';

export const Block = ({ className, children, title, description }) => {
  const contentSpace = useRef(null);

  return (
    <div style={{ width: '100%' }} className={styles.block + (className ? ' ' + className : '')}>
      {(title || description) && (
        <div className={styles.title}>
          <div>
            {title && <h1>{title}</h1>}
            {description && <h1>{description}</h1>}
          </div>
        </div>
      )}
      <div className={styles.content} ref={contentSpace}>
        {children}
      </div>
    </div>
  );
};

import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

import Layout from './Layout';

const BurguerMenu = ({ onClose, onOpen, isOpen, transition, disableOverlayClick }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState('');

  const handleItemClick = (path, newTab) => {
    if (newTab) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  return (
    <Layout
      onClickItem={handleItemClick}
      selectedRoute={selectedRoute}
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      transition={transition}
      disableOverlayClick={disableOverlayClick}
      logout={authContext.logout}
      userRole={authContext.role}
    />
  );
};

export default BurguerMenu;

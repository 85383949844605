import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { noSpaceMask, numericMask } from '../../utils/formater';
import { AuthContext } from '../../contexts/AuthContext';
import notify, { MISSING_FIELDS, INTERNAL_ERROR, UF_VALUE_IS_MISSING, NO_STATES_MASS_CHANGE_WITH_FILTER, NO_PRICE_MASS_CHANGE, CONFIRM_MASS_CHANGE_UF_VALUE } from '../../utils/notify';
import dayjs from 'dayjs';
import Layout from './Layout';
import {
  validateUfandValues,
  formatUfList,
  getRegionUf,
  removeSpecialCharacters,
  shippingTypes,
  formatDecimal,
} from './VoucherHelpers';

const Voucher = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [voucherId, setVoucherId] = useState();
  const [loading, setLoading] = useState();
  const [loadingUf, setLoadingUf] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [dataUf, setDataUf] = useState([]);
  const [dataUfFilter, setDataUfFilter] = useState([]);
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange, setFieldValue, handleFinish } = useForm({
    active: {
      required: false,
    },
    code: {
      required: true,
      mask: noSpaceMask,
    },
    description: {
      required: true,
    },
    percent: {
      required: true,
    },
    discountValue: {
      required: true,
    },
    freeShipping: {
      required: false,
      value: false,
    },
    supportShipping: {
      required: false,
      value: 'false',
    },
    fixShipping: {
      required: false,
      value: 'false',
    },
    minValue: {
      required: false,
    },
    start: {
      required: false,
    },
    end: {
      required: false,
    },
    minProducts: {
      required: false,
    },
    shippingSearch: {
      required: false,
    },
    shippingFilterBy: {
      required: false,
      value: 'uf',
    },
    shippingFilterByOptions: {
      required: false,
      value: [
        { value: 'uf', label: 'Estado' },
        { value: 'regions', label: 'Região' },
      ],
    },
    shippingTypeOptions: {
      required: false,
      value: shippingTypes
    },
    shippingType: {
      required: false,
      value: 'client',
    },
    shippingTypeAllUfs: {
      required: false,
    },
    shippingValueAllUfs: {
      required: false,
      mask: numericMask,
    },
  });

  const getDataUfFilter = (dataUfInfo, termSearch = '') => {
    const filterBy = fields.shippingFilterBy?.value;
    const search = removeSpecialCharacters(termSearch || fields.shippingSearch?.value);

    if (filterBy === 'uf' && search) {
      return {
        filterInUse: true,
        ufList: dataUfInfo.filter((uf) => new RegExp(search).test(removeSpecialCharacters(uf.label)))
      };
    }

    if (filterBy === 'regions' && search) {
      return {
        filterInUse: true,
        ufList: dataUfInfo.filter((uf) => new RegExp(search).test(removeSpecialCharacters(uf.region)))
      };
    }

    return {
      filterInUse: false,
      ufList: dataUfInfo
    };
  };

  const handleConfirmModalAction = () => {
    handleChangeAllShippingValue();
    setModalIsOpen(false);
  };

  const handleCancelModalAction = () => {
    setModalIsOpen(false);
  };
  const handleOpenModal = () => {
    const shippingTypeAllUfs = fields.shippingTypeAllUfs.value;
    const shippingValueAllUfs = fields.shippingValueAllUfs.value;

    const afectedUfs = dataUf.filter(uf => uf.group === shippingTypeAllUfs);

    if (!afectedUfs || afectedUfs?.length === 0) return notify(NO_STATES_MASS_CHANGE_WITH_FILTER, 'error');
    if (!shippingValueAllUfs) return notify(NO_PRICE_MASS_CHANGE, 'error');

    setModalMessage(CONFIRM_MASS_CHANGE_UF_VALUE(afectedUfs.length))
    setModalIsOpen(true);
  };

  const handleChangeUfShippingValue = (price, shippingInfo) => {

    let priceShipping = formatDecimal(price);

    const newDataUf = dataUf.map((uf) => {
      if (uf.id === shippingInfo.id) {
        return { ...uf, price: priceShipping, valid: true };
      }
      return uf;
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
  };

  const handleChangeUfShippingType = (seleted, shippingInfo) => {
    const newDataUf = dataUf.map((uf) => {
      if (uf.id === shippingInfo.id) {
        return { ...uf, group: seleted.value, useValue: seleted.useValue, price: seleted.useValue ? uf.price : '' };
      }
      return uf;
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
    const shippingTypes = fields.shippingTypeOptions.value;
    const allShippingTypeIsTheSame = shippingTypes.find((shippingType) =>
      newDataUf.every((uf) => uf.group === shippingType.value)
    );

    setFieldValue(
      'shippingType',
      allShippingTypeIsTheSame ? allShippingTypeIsTheSame.value : ''
    );
  };

  const handleChangeAllShippingType = (type) => {
    const shippingTypes = fields.shippingTypeOptions.value;
    const seletedShippingTypes = shippingTypes.find(
      (shippingType) => shippingType.value === type
    );

    const newDataUf = dataUf.map((uf) => {
      return {
        ...uf,
        group: type,
        useValue: seletedShippingTypes.useValue,
        price: seletedShippingTypes.useValue ? uf.price : ''
      };
    });

    const { ufList } = getDataUfFilter(newDataUf);
    setDataUf(newDataUf);
    setDataUfFilter(ufList);
    setFieldValue('shippingType', type);
  };

  const handleChangeAllShippingValue = () => {
    const shippingTypeAllUfs = fields.shippingTypeAllUfs.value;
    const shippingValueAllUfs = fields.shippingValueAllUfs.value;

    const newDataUf = dataUf.map((uf) => {
      if (uf.group === shippingTypeAllUfs) {
        return {
          ...uf,
          price: shippingValueAllUfs,
          valid: true,
        };
      }

      return uf;
    });

    setDataUf(newDataUf);
    setDataUfFilter(newDataUf);
  }

  const handleFilterShippingChange = (valueSearch, inputName) => {
    const termSearch = valueSearch.target?.value;
    const { ufList } = getDataUfFilter(dataUf, termSearch);
    setDataUfFilter(ufList);
    setFieldValue(inputName, termSearch);
  }

  const handleClickSave = async () => {
    const { valid } = await handleFinish();
    const ufIsValid = validateUfandValues(dataUf, fields);

    if (!ufIsValid.valid) {
      changeObjectToShowError(ufIsValid.details)
      return notify(UF_VALUE_IS_MISSING, 'error');
    }

    if (valid) {
      setLoading(true);
      const reqBody = {
        active: fields.active.value,
        code: fields.code.value,
        description: fields.description.value,
        discount_value: fields.discountValue.value,
        uf_list: formatUfList(dataUf),
        zip_limitation: ufIsValid.zipLimitationIsEnabled,
        payment_method: 'any',
        all_products: true,
      };

      if (fields.freeShipping?.value === 'true') {
        reqBody.free_shipment = true;
      } else {
        reqBody.free_shipment = false;
      }

      if (fields.percent?.value === 'true') {
        reqBody.is_percent = true;
      } else {
        reqBody.is_percent = false;
        reqBody.discount_value = reqBody.discount_value * 100;
      }

      if (fields.start.value) {
        reqBody.start_date = new Date(fields.start.value).toISOString();
      }
      if (fields.end.value) {
        reqBody.end_date = new Date(fields.end.value).toISOString();
      }

      if (fields.minValue?.value) {
        reqBody.minimun_spent = fields.minValue?.value * 100;
      }

      reqBody.product_quantity = fields.minProducts?.value ? fields.minProducts.value : 0;

      fetchFromBackend(`/vouchers/${voucherId}`, 'PATCH', reqBody, true)
        .then((response) => {
          setLoading(false);
          notify('Cupom Salvo!', 'success');
        })
        .catch((error) => {
          setLoading(false);
          notify(INTERNAL_ERROR, 'error');
        });
    } else {
      notify(MISSING_FIELDS, 'error');
    }
  };

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/vouchers');
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);
  };

  const formatDate = (date) => {
    if (date) {
      return dayjs(date.split('+')[0]).subtract(3, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return null;
    }
  };

  const handleShippingSearchChange = (targetValue, targetName) => {
    let fieldValue = fields[targetName].value;

    if (targetValue[0]) {
      fieldValue = targetValue[0].value;
    } else {
      fieldValue = '';
    }

    handleChange({ target: { name: targetName, value: fieldValue } });
  };

  const changeObjectToShowError = (dateUfUnfiled) => {
    let objectErrorIds = [];
    dateUfUnfiled.forEach((error) => {
      return objectErrorIds.push(error.id);
    })

    const newDataUf = dataUf.map((uf) => {
      if (objectErrorIds.includes(uf.id)) {
        return {
          ...uf,
          valid: false,
        };
      }

      return uf;
    });
    setDataUfFilter(newDataUf);
  }

  const getUfCodes = async () => {
    setLoadingUf(true);
    await fetchFromBackend(`/logistics/ufs`, 'GET', {}, true).then((response) => {
      let parsedResponse = [];
      response.forEach((uf) => {
        if (uf.uf_if) {
          parsedResponse.push({
            id: uf.uf_if,
            label: uf.uf_name,
            value: uf.uf_if,
            group: 'client',
            price: '',
            region: getRegionUf(uf.uf_if).region,
            valid: true,
            useValue: false,
          });
        }
      });
      const ordenedResponse = parsedResponse.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

      fetchVoucher(ordenedResponse)
    });
    setLoadingUf(false);
  };

  const fetchVoucher = (ufsFetch) => {
    setLoading(true);
    fetchFromBackend(`/vouchers/code/${code}?all_vouchers=true`, 'GET', {}, true).then(
      (response) => {
        const voucher = response.voucher;
        setVoucherId(voucher.id);
        setFieldValue('active', voucher.active ? 'true' : 'false');
        setFieldValue('code', voucher.code);
        setFieldValue('description', voucher.description);
        setFieldValue('percent', voucher.is_percent ? 'true' : 'false');
        setFieldValue(
          'discountValue',
          voucher.is_percent ? voucher.discount_value : voucher.discount_value / 100
        );
        setFieldValue('freeShipping', voucher.free_shipment ? 'true' : 'false');
        setFieldValue('minValue', voucher.minimun_spent ? voucher.minimun_spent / 100 : '');
        setFieldValue('start', formatDate(voucher.start_date));
        setFieldValue('end', formatDate(voucher.end_date));
        setFieldValue('minProducts', voucher.product_quantity);

        const ufListIsReadyToCharge = voucher.uf_limitations?.length > 0;

        if (ufListIsReadyToCharge) {
          const newDataUf = ufsFetch.map(uf => {
            const idUfExists = voucher.uf_limitations.find(ufLimitation => ufLimitation.uf_id === uf.id);

            if (idUfExists) {
              const seletedShippingTypes = shippingTypes.find(
                (shippingType) => shippingType.value === idUfExists.shipment_type
              );

              return {
                ...uf,
                group: idUfExists.shipment_type,
                price: seletedShippingTypes.useValue ? formatDecimal(idUfExists.shipment_value.toString()) : '',
                useValue: seletedShippingTypes.useValue,
              }
            }

            return uf;
          })

          setDataUfFilter(newDataUf);
          setDataUf(newDataUf);
          setFieldValue('shippingType', '');
        }else{
          setDataUfFilter(ufsFetch);
          setDataUf(ufsFetch);
        }

        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (dataUf.length === 0 && !loadingUf) {
      getUfCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      handleClickSave={handleClickSave}
      onClickGoBack={handleGoBack}
      allowChanges={authContext.verifyAccess([2])}
      loading={loading}
      dataUf={dataUfFilter}
      loadingUf={loadingUf}
      confirmAction={handleConfirmModalAction}
      cancelAction={handleCancelModalAction}
      isOpen={modalIsOpen}
      modalMessage={modalMessage}
      handleShippingSearchChange={handleShippingSearchChange}
      handleChangeUfShippingValue={handleChangeUfShippingValue}
      handleChangeUfShippingType={handleChangeUfShippingType}
      handleChangeAllShippingType={handleChangeAllShippingType}
      handleFilterShippingChange={handleFilterShippingChange}
      handleChangeAllShippingValue={handleChangeAllShippingValue}
      handleOpenModal={handleOpenModal}
    />
  );
};

export default Voucher;

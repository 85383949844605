import { PanelContainer, Block } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';
import { ReactComponent as ValidIcon } from '../../../assets/icons/approved.svg';
import { ReactComponent as InvalidIcon } from '../../../assets/icons/denied.svg';

import styles from './Campaigns.module.css';

const Layout = ({
  campaigns,
  fields,
  handleChange,
  handleNewCampaign,
  handleCampaignClick,
  loading,
}) => {
  return (
    <PanelContainer
      headerMenu
      headerTitle="Campanhas"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <Block className={styles.block}>
        <div className={styles.search}>
          <Input
            name="search"
            value={fields.search?.value}
            onChange={handleChange}
            placeholder="Buscar campanha"
            className={styles.input}
          />
          <Button
            primary
            text="Criar Campanha"
            onClick={handleNewCampaign}
            className={styles.button}
          />
        </div>
        {!loading ? (
          <>
            {campaigns.length > 0 ? (
              <>
                <div className={styles.header}>
                  <p className={styles.code}>Campanha</p>
                  <p className={styles.status}>UTMs</p>
                  <p className={styles.valid}>Cupom</p>
                  <p className={styles.discount}>Ativo</p>
                </div>
                <div className={styles.campaigns}>
                  {campaigns.map((campaign, key) => (
                    <div className={styles.campaign} key={key}>
                      <div
                        onClick={(e) => handleCampaignClick(campaign.campaign_name, e.ctrlKey)}
                        className={styles.name}
                      >
                        <h2>{campaign.campaign_name}</h2>
                      </div>
                      <div>
                        <p>{campaign.utm_campaign}</p>
                        <p>{campaign.utm_source}</p>
                        <p>{campaign.utm_medium}</p>
                      </div>
                      <div>
                        <h2 className={styles.voucher}>{campaign.voucher_code}</h2>
                      </div>
                      <div>{campaign.active ? <ValidIcon /> : <InvalidIcon />}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p className={styles.notFound}>Nenhuma campanha encontrado</p>
            )}
          </>
        ) : (
          <LoadingDots className={styles.loading} />
        )}
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import styled from 'styled-components';
import SelectUI from 'react-select';
import { Link as LinkUI } from 'react-router-dom';

export const Block = styled.div`
  ${({ removeBorder }) => !removeBorder && `border-bottom:  1px solid #ECEAEA;`};
  padding: ${({ enablePaddingBottom }) =>
    enablePaddingBottom ? '12px;' : '12px 12px 0px 12px;'};
  width: 100%;
  background-color: var(--color-white);
`;

export const BlockUtm = styled(Block)`
  padding: ${({ enablePaddingBottom }) =>
    enablePaddingBottom ? '12px;' : '12px 12px 0px 12px;'};
`;

export const Label = styled.p`
  margin: 0px 0px 5px 0px;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 600;
  text-align: ${({ aling }) => (aling ? aling : 'start')};

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 425px) {
    font-size: 13px;
  }
`;

export const FilterContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  grid-template-columns: 1fr 1.5fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0rem;
  }
`;

export const Left = styled.div``;
export const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Header = styled.header``;
export const HeaderWithLoading = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 15px;
  margin-bottom: 6px;
`;

export const Footer = styled.footer`
  display: grid;
  grid-template-columns: ${({ numColumns = 2 }) => `repeat(${numColumns},2fr);`};
  gap: ${({ gap }) => (gap ? gap : '23px')};
  margin: ${({ removeMarginBottom, removeMarginTop }) =>
    removeMarginBottom
      ? '12px 0px 0px 0px;'
      : removeMarginTop
      ? '0px 0px 8px 0px;'
      : '12px 0px 8px 0px;'};
`;

export const StatusContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MoreFiltersContainer = styled.div`
  display: grid;
  gap: 29px;
  grid-template-columns: 1.89fr 0.5fr 1fr;

  @media (max-width: 425px) {
    gap: 0.5px;
    grid-template-columns: 1fr;
  }
`;

export const Select = styled(SelectUI).attrs({
  className: 'filter-container single-filter',
  classNamePrefix: 'filter',
  isClearable: true,
})`
  svg {
    color: var(--color-light-salmon);
  }
`;

export const Grid = styled.div`
  display: grid;
`;

export const GridHeader = styled(Grid)`
  text-align: center;
  border-bottom: 1px solid #eceaea;
  grid-template-columns: 1fr 1.7fr 2.5fr 0.8fr 0.8fr 0.9fr;

  @media (max-width: 425px) {
    display: none;
    border: 0px !important;
  }
`;

export const GridLine = styled(Grid)`
  border-top: 1px solid #eceaea;
  width: 100%;
  grid-template-columns: 1fr 1.7fr 2.5fr 0.8fr 0.8fr 0.9fr;
  align-items: center;
  justify-items: center;

  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    &:first-child {
      border-top: 0;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 13px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const Link = styled(LinkUI)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-align: ${({ aling }) => (aling ? aling : 'center')};
  text-decoration-line: underline;
  width: 100%;
  color: #1f282c;

  @media (max-width: 425px) {
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const QueryInfoContainer = styled.div`
  min-height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: end;
`;

export const Button = styled.button`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;

  border: 0px;
  background: #fff;
  text-decoration-line: underline;
  cursor: pointer;
`;

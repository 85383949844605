import { PanelContainer, Block } from '../../../components/PanelContainer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import Modal from '../../../components/Modal';
import { InputSelection } from '../../../components/InputSelection';
import { ListUniqueSelection } from '../../../components/ListSelection';
import LoadingDots from '../../../components/LoadingDots';


import {
  ShippingContainer,
  Label,
  SearchContainer,
  MassChangeContainer,
  Left,
  Right,
  ControlerChangeContainer,
  Select,
  GridSelect,
  ControlerValueContainer,
  GridHeader,
  Grid,
  GridLine,
  Text
} from './styles';

import styles from './AddVoucher.module.css';

const Layout = ({
  fields,
  handleChange,
  handleFieldChange,
  handleClickAddVoucher,
  onClickGoBack,
  dataUf,
  loadingUf,
  confirmAction,
  cancelAction,
  isOpen,
  modalMessage,
  handleShippingSearchChange,
  handleChangeUfShippingValue,
  handleChangeUfShippingType,
  handleChangeAllShippingType,
  handleFilterShippingChange,
  handleChangeAllShippingValue,
  handleOpenModal
}) => {
  return (
    <PanelContainer
      headerMenu
      headerTitle='Criar Cupom'
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      <Modal
        active={isOpen}
        onAccept={confirmAction}
        onCancel={cancelAction}
        acceptText='CONTINUAR MESMO ASSIM'
        cancelText='CANCELAR'
      >
        <p className={styles.modalMessage}>{modalMessage}</p>
      </Modal>
      <div className={styles.header}>
        <Button
          tertiary
          icon={<ArrowLeft />}
          text='ver todos os cupons'
          className={styles.back}
          onClick={onClickGoBack}
        />
      </div>
      <Block className={styles.block}>
        <div className={styles.code}>
          <h2>Código</h2>
          <Input
            name='code'
            value={fields.code?.value}
            valid={!fields.code?.valid ? fields.code?.valid : null}
            message={fields.code?.message}
            placeholder=''
            className={styles.input}
            inputClassName={styles.inputSecundary}
            onChange={handleChange}
          />
        </div>
        <h2>Descrição</h2>
        <Input
          name='description'
          value={fields.description?.value}
          valid={!fields.description?.valid ? fields.description?.valid : null}
          message={fields.description?.message}
          placeholder=''
          inputClassName={styles.inputSecundary}
          onChange={handleChange}
        />
        <div className={styles.discount}>
          <h2>Desconto</h2>
          <div>
            <ChoiceGroup
              name='percent'
              selected='true'
              className={styles.options}
              onChange={(e) => {
                handleFieldChange('percent', e);
              }}
            >
              <Choice className={styles.choice} title='%' value='true' />
              <Choice className={styles.choice} title='R$' value='false' />
            </ChoiceGroup>
            {fields.percent.value === 'true' ? (
              <Input
                name='discountValuePercentage'
                value={fields.discountValuePercentage?.value}
                valid={
                  !fields.discountValuePercentage?.valid
                    ? fields.discountValuePercentage?.valid
                    : null
                }
                message={fields.discountValuePercentage?.message}
                onlyDigit
                placeholder='0'
                inputClassName={styles.inputSecundary}
                maxLength={2}
                onChange={handleChange}
              />
            ) : (
              <Input
                name='discountValueAbsolut'
                value={fields.discountValueAbsolut?.value}
                valid={
                  !fields.discountValueAbsolut?.valid
                    ? fields.discountValueAbsolut?.valid
                    : null
                }
                message={fields.discountValueAbsolut?.message}
                inputClassName={styles.inputSecundary}
                onlyDigit
                placeholder='0,00'
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className={styles.rules}>
          <div>
            <h2>Valor Mínimo</h2>
            <div className={styles.value}>
              <h2>R$</h2>
              <Input
                name='minValue'
                value={fields.minValue?.value}
                onlyDigit
                placeholder=''
                className={styles.input}
                inputClassName={styles.inputSecundary}
                onChange={handleChange}
              />
            </div>
            <h2>Número Mínimo de Produtos</h2>
            <Input
              name='minProducts'
              value={fields.minProducts?.value}
              valid={!fields.minProducts?.valid ? fields.minProducts?.valid : null}
              type='number'
              inputClassName={styles.inputSecundary}
              onlyDigit
              placeholder=''
              onChange={handleChange}
            />
          </div>
          <div className={styles.dates}>
            <h2>Válido A Partir</h2>
            <Input
              name='start'
              type='datetime-local'
              inputClassName={styles.inputSecundary}
              value={fields.start?.value}
              valid={!fields.start?.valid ? fields.start?.valid : null}
              message={fields.start?.message}
              onChange={handleChange}
            />
            <h2>Válido Até</h2>
            <Input
              name='end'
              inputClassName={styles.inputSecundary}
              type='datetime-local'
              value={fields.end?.value}
              valid={!fields.end?.valid ? fields.end?.valid : null}
              message={fields.end?.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.shippmentContent}>
          <h2 className={styles.shippmentTitle}>Configurações para o frete:</h2>
        </div>
        <ShippingContainer>
          <SearchContainer>
            <Label>Busca</Label>
            <InputSelection
              name='shippingSearch'
              value={fields.shippingSearch?.value}
              onChange={(value) => handleFilterShippingChange(value, 'shippingSearch')}
              placeholder='Pesquisar pelo filtro...'
              optionsSelect={fields.shippingFilterByOptions?.value}
              defaultValueSelect={
                fields.shippingFilterByOptions?.value.find(
                  (type) => type.value === fields.shippingFilterBy?.value
                ) || fields.shippingFilterByOptions?.value[0]
              }
              onChangeSelect={(selected) =>
                handleShippingSearchChange([selected], 'shippingFilterBy')
              }
            />
          </SearchContainer>
          <MassChangeContainer>
            <Left>
              <Label>Definir frete de todos os estados como:</Label>
              <ListUniqueSelection
                data={fields.shippingTypeOptions?.value}
                selected={fields.shippingType?.value}
                handleClick={(selected) => {
                  handleChangeAllShippingType(selected);
                }}
              />
            </Left>
            <Right>
              <Label>Definir valor para todos os estados pelo tipo de frete:</Label>
              <ControlerChangeContainer>
                <Select
                  optionsPlacement='bottom'
                  options={fields.shippingTypeOptions?.value?.filter(
                    (shippingType) => shippingType.useValue
                  )}
                  onChange={({value}) => handleChange({ target: { name: 'shippingTypeAllUfs', value: value }})}
                  placeholder='Tipo de frete...'
                  hideSelectedOptions
                />
                <ControlerValueContainer>
                  <h2>R$</h2>
                  <Input
                    name='shippingValueAllUfs'
                    value={fields.shippingValueAllUfs?.value}
                    onlyDigit
                    placeholder='0,00'
                    inputClassName={styles.inputSecundary}
                    onChange={handleChange}
                  />
                </ControlerValueContainer>
                <Button
                  secondary
                  text='Aplicar'
                  onClick={handleOpenModal}
                />
              </ControlerChangeContainer>
            </Right>
          </MassChangeContainer>
          <GridHeader>
            <Label>Estado</Label>
            <Label margin="10px">Tipo de frete</Label>
            <Label>Valor do Frete</Label>
          </GridHeader>
          <Grid>
            {!loadingUf ?
              (dataUf.map((uf, key) => (
                <GridLine key={key}>
                  <Text>
                    {uf.label}
                  </Text>
                  <GridSelect
                    optionsPlacement='bottom'
                    options={fields.shippingTypeOptions?.value}
                    defaultValue={fields.shippingTypeOptions?.value.find((type) => type.value === uf.group)}
                    shippingTypeSeleted={uf.group}
                    value={fields.shippingTypeOptions?.value.find((type) => type.value === uf.group)}
                    onChange={(selected) => handleChangeUfShippingType(selected, uf)}
                    placeholder='Tipo de frete...'
                    hideSelectedOptions
                  />
                  <ControlerValueContainer removeBold>
                    <h3>R$</h3>
                    <Input
                      name='priceUfShipping'
                      value={uf.price}
                      disabled={!uf.useValue}
                      placeholder='0,00'
                      valid={!uf.valid? uf.valid : null}
                      inputClassName={styles.inputSecundary}
                      onChange={({ target }) => handleChangeUfShippingValue(target.value, uf)}
                    />
                  </ControlerValueContainer>
                </GridLine>
              )
              )) : <LoadingDots />
            }
          </Grid>
        </ShippingContainer>
        <Button
          primary
          text='Criar'
          className={styles.button}
          onChange={handleChange}
          onClick={handleClickAddVoucher}
        />
      </Block>
    </PanelContainer>
  );
};

export default Layout;

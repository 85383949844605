export const INTERNAL_ERROR = 'Ops, algo deu errado. Por favor, tente mais tarde';
export const LOGIN_FAILED = 'Não foi possível fazer o login com as credenciais informadas';
export const WRONG_OR_MISSING_FIELD = 'Campo(s) incorreto(s) ou faltante(s)';
export const SERVER_ERROR = 'Erro ao conectar com servidor, tente novamente em instantes';
export const MISSING_FIELDS = 'Por favor, preencha todos os campos obrigatórios';
export const ORDER_DETAILS_FAIL = 'Erro ao carregar detalhes do pedido';
export const UNAUTHORIZED = 'Autenticação expirada, faça o login novamente';
export const VOUCHER_GREATER_THAN_MINIMUM_AMOUNT = 'Cupom com desconto maior que valor mínimo';
export const VOUCHER_MINIMUM_VALUE = 'Cupom deve ter valor mínimo';
export const VOUCHER_MINIMUM_STATE = 'Ops, selecione ao menos um estado';
export const VOUCHER_SUCCESS = 'Cupom Criado!';
export const UF_VALUE_IS_MISSING = 'Verifique se todos os valores foram preenchidos de acordo com tipos de fretes informados';
export const NO_STATES_MASS_CHANGE_WITH_FILTER = 'Sem estados para o filtro informado';
export const NO_PRICE_MASS_CHANGE = 'Informe o valor para processeguir';
export const CONFIRM_MASS_CHANGE_UF_VALUE = (afectedUfsCount) => `Ao continuar a ação ${afectedUfsCount} estados serão alterados, ainda sim deseja continuar?`;
export const SHIPPING_CHANGE_TYPE_MULT = 'Ao continuar a ação, as listagens de estados e valores já definidas serão apagadas. Ainda sim, deseja continuar?';
export const SHIPPING_CHANGE_TYPE_SINGLE = 'Ao continuar a ação, a listagem com  os estados e valores já definida será apagada. Ainda sim, deseja continuar?';

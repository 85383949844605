export const dateIsBettweenDates = (startDate, endDate, dateToValidade) => {
  if (!dateToValidade) return false;

  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  const validate = new Date(dateToValidade).getTime()

  return validate <= end && validate >= start
}

export const ComplexDeliverieDatesToIncrement = [
  { dateValidToIncrement: '2022-12-25', daysToIncrement: 19 },
  { dateValidToIncrement: '2022-12-26', daysToIncrement: 19 },
  { dateValidToIncrement: '2022-12-27', daysToIncrement: 20 },
  { dateValidToIncrement: '2022-12-28', daysToIncrement: 19 },
  { dateValidToIncrement: '2022-12-29', daysToIncrement: 19 },
  { dateValidToIncrement: '2022-12-30', daysToIncrement: 19 },
  { dateValidToIncrement: '2022-12-31', daysToIncrement: 18 },
  { dateValidToIncrement: '2023-01-01', daysToIncrement: 18 },
  { dateValidToIncrement: '2023-01-02', daysToIncrement: 17 },
  { dateValidToIncrement: '2023-01-03', daysToIncrement: 16 },
  { dateValidToIncrement: '2023-01-04', daysToIncrement: 16 },
  { dateValidToIncrement: '2023-01-05', daysToIncrement: 16 },
  { dateValidToIncrement: '2023-01-06', daysToIncrement: 15 },
  { dateValidToIncrement: '2023-01-07', daysToIncrement: 15 },
  { dateValidToIncrement: '2023-01-08', daysToIncrement: 14 },
  { dateValidToIncrement: '2023-01-09', daysToIncrement: 14 },
  { dateValidToIncrement: '2023-01-10', daysToIncrement: 13 },
  { dateValidToIncrement: '2023-01-11', daysToIncrement: 12 },
  { dateValidToIncrement: '2023-01-12', daysToIncrement: 12 },
  { dateValidToIncrement: '2023-01-13', daysToIncrement: 12 }
]

export const validateDateToIncrement = (orderCreatedDate, statusId) => {
  //paid and production status
  if (![4, 11].includes(statusId)) return { isValidToIncrement: false, dateValidToIncrement: orderCreatedDate, daysToIncrement: 0 }
  if (new Date(orderCreatedDate) < new Date(ComplexDeliverieDatesToIncrement[0].dateValidToIncrement)) return { isValidToIncrement: true, dateValidToIncrement: orderCreatedDate, daysToIncrement: 19 }

  const dateFinded = ComplexDeliverieDatesToIncrement.find(date => dateIsBettweenDates(`${date.dateValidToIncrement} 00:00:00`, `${date.dateValidToIncrement} 23:59:59`, orderCreatedDate))

  if (dateFinded) return { isValidToIncrement: true, ...dateFinded };

  return { isValidToIncrement: false, dateValidToIncrement: orderCreatedDate, daysToIncrement: 0 }
}

import styled from 'styled-components';
import { findOrderStausStyle } from '../../utils/constants'

export const Status = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
  font-family: 'Montserrat';
  font-weight: 400;

  text-align: center;
  font-size: 12px;
  cursor: pointer;
  border: 0;

  ${({ children, active }) => active ? findOrderStausStyle(children).styles : `
    border: 1px solid var(--color-black);
    background: var(--color-white);
    padding: 2px;
  `}

  @media (max-width: 1024px) {
    font-size: 10px;
  }

  @media (max-width: 425px) {
    font-size: 11px;
    padding: 4px;
  }
`;

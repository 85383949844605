import React, { createContext, useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import notify from '../utils/notify';
import useFetch from '../hooks/useFetch';
import { isExpired } from 'react-jwt';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('@JFY_PANEL:Token'));
  const [user, setUser] = useState(localStorage.getItem('@JFY_PANEL:User'));
  const [role, setRole] = useState(parseInt(localStorage.getItem('@JFY_PANEL:Role')));
  const { fetchFromBackend } = useFetch();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const defaultRoleId = 2;

  const login = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        GoogleAuthProvider.credentialFromResult(result);
        const authUser = result.user;

        if (authUser.email.split('@')[1] !== 'justfor.com.br') {
          throw Error('Acesso Negado!');
        } else {
          const apiToken = await auth.currentUser.getIdToken(true);
          const infos = auth.currentUser;
          const expirationTime = new Date().setHours(new Date().getHours() + 10);

          // Check user in database
          fetchFromBackend(
            `/dashboard/users/search?user=${authUser.email}`,
            'GET',
            {},
            false,
            apiToken
          ).then((response) => {
            if (response.responseStatus === 404) {
              //If not found, create
              let reqBody = {
                name: infos.displayName,
                email: authUser.email,
                role: defaultRoleId,
              };

              fetchFromBackend('/dashboard/users', 'POST', reqBody, true, apiToken)
                .then(() => {
                  setUser(authUser);
                  setToken(apiToken);
                  setRole(parseInt(defaultRoleId));
                  localStorage.setItem('@JFY_PANEL:User', authUser.email);
                  localStorage.setItem('@JFY_PANEL:Token', apiToken);
                  localStorage.setItem('@JFY_PANEL:Role', parseInt(defaultRoleId));
                  localStorage.setItem('@JFY_PANEL:ExpirationTime', expirationTime);
                })
                .catch((error) => {
                  notify('Usuário não cadastrado no sistema.', 'error');
                  logout();
                });
            } else {
              setUser(authUser);
              setToken(apiToken);
              setRole(parseInt(response.user_role));
              localStorage.setItem('@JFY_PANEL:User', authUser.email);
              localStorage.setItem('@JFY_PANEL:Token', apiToken);
              localStorage.setItem('@JFY_PANEL:Role', parseInt(response.user_role));
              localStorage.setItem('@JFY_PANEL:ExpirationTime', expirationTime);
            }
          });
        }
      })
      .catch((error) => {
        notify(error?.message, 'error');
      });
  };

  const validadeAndRefreshToken = async (token) => {
    const expirationTime = localStorage.getItem('@JFY_PANEL:ExpirationTime');
    const tokenExpired = isExpired(token);

    if (!auth.currentUser) return token;
    if (!tokenExpired) return token;
    if (new Date() >= new Date(Number(expirationTime)) || !expirationTime) {
      return logout();
    };
    const refreshedToken = await auth.currentUser.getIdToken(true);
    localStorage.setItem('@JFY_PANEL:Token', refreshedToken);
    setToken(refreshedToken);

    return refreshedToken;
  }

  const logout = () => {
    localStorage.removeItem('@JFY_PANEL:Token');
    localStorage.removeItem('@JFY_PANEL:ExpirationTime');
    setToken(null);
  };

  const verifyAccess = (deniedRoles = []) => {
    if (deniedRoles.includes(role)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('@JFY_PANEL:Token');
    if (savedToken) {
      setToken(savedToken);
      setUser(localStorage.getItem('@JFY_PANEL:User'));
      setRole(parseInt(localStorage.getItem('@JFY_PANEL:Role')));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(token), login, verifyAccess, validadeAndRefreshToken, token, logout, user, role }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

export const validateUfandValues = (itens, fields) => {
  const shippingTypeOptions = fields.shippingTypeOptions.value;

  //generate objeto to validate on includes
  let useValueShippingTypes = [];
  shippingTypeOptions.forEach((shipping) => {
    if (shipping.useValue) {
      useValueShippingTypes.push(shipping.value);
    }
  });

  //check if have zip limitaion
  const zipLimitation = itens.filter((item) =>
    ['free', 'fix', 'support'].includes(item.group)
  );
  const zipLimitationIsEnabled = zipLimitation.length > 0;

  // check if any field are empty
  const unfilledProducts = itens.filter(
    (item) => !item.price && useValueShippingTypes.includes(item.group)
  );

  if (unfilledProducts.length > 0)
    return {
      valid: false,
      details: unfilledProducts,
      zipLimitationIsEnabled: zipLimitationIsEnabled,
    };

  return { valid: true, details: [], zipLimitationIsEnabled: zipLimitationIsEnabled };
};

export const shippingFieldsToValidate = (fields) => {
  let shippingFields = [];
  if (fields.fixShipping?.value === 'true') shippingFields.push('fix');
  if (fields.supportShipping?.value === 'true') shippingFields.push('support');

  return shippingFields;
};

export const formatUfList = (ufList, groups = []) => {
  var filtredList = [];
  if (groups.length > 0) {
    filtredList = ufList.filter((item) =>
      groups.includes(item.group || item.shipment_type)
    );
  } else {
    filtredList = ufList;
  }

  return filtredList
    .map((uf) => {
      return {
        id: uf.value,
        shipment_value: Number(uf.price.replace(/[^0-9]/g, '')),
        shipment_type: uf.group,
      };
    })
    .filter((uf) => uf.shipment_type !== 'client');
};

export const removeSpecialCharacters = (string) => {
  if (!string) return string;

  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const getRegionUf = (ufId) => {
  const regions = [
    { region: 'sudeste', ufIds: [3, 4, 2, 1] },
    { region: 'sul', ufIds: [25, 26, 27] },
    { region: 'centro oeste', ufIds: [20, 24, 19, 22] },
    { region: 'norte', ufIds: [16, 14, 18, 17, 15, 21, 23] },
    { region: 'nordeste', ufIds: [13, 12, 11, 10, 9, 6, 5, 8, 7] },
  ];

  return regions.find((region) => region.ufIds.includes(ufId));
};

export const formatDecimal = (price) => {
  if (!price) return price;

  //regex to apply mask to number 1.111,00
  return price
    .replace(/\D/g, '')
    .replace(/(\d{1,2})$/, ',$1')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatActiveShippingFields = (ufs) => {
  let fields = [];

  ufs.forEach((item) => {
    fields.push(item.shipment_type);
  });

  return fields;
};

export const shippingTypes = [
  { value: 'client', label: 'Pago pelo cliente', useValue: false },
  { value: 'free', label: 'Frete Grátis', useValue: false },
  { value: 'support', label: 'Frete Custeado', useValue: true },
  { value: 'fix', label: 'Frete Fixo', useValue: true },
];

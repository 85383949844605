export const orderStatus = [
  {
    status: 'pedidorealizado',
    styles: `
      background: var(--color-light-salmon);
      color: var(--color-white);
    `
  },
  {
    status: 'pedidocancelado',
    styles: `
      background: var(--color-wine-berry);
      color: var(--color-white);
    `
  },
  {
    status: 'pedidoentregue',
    styles: `
      background: var(--color-cadet-blue);
      color: var(--color-white);
    `
  },
  {
    status: 'pagamentoaprovado',
    styles: `
      background: var(--color-cadet-blue);
      color: var(--color-white);
    `
  },
  {
    status: 'pagamentonãoefetuado',
    styles: `
      background: var(--color-wine-berry);
      color: var(--color-white);
    `
  },
  {
    status: 'aguardandopagamento',
    styles: `
      background: var(--color-banana-mania);
      color: var(--color-black);
    `
  },
  {
    status: 'manipulandosuafórmula',
    styles: `
      background: var(--color-blush);
      color: var(--color-black);
    `
  },
  {
    status: 'manipulaçãoematraso',
    styles: `
      background: var(--color-wine-berry);
      color: var(--color-white);
    `
  },
  {
    status: 'aguardandoenvio',
    styles: `
      background: var(--color-banana-mania);
      color: var(--color-black);
    `
  },
  {
    status: 'emtransporte',
    styles: `
      background: var(--color-banana-mania);
      color: var(--color-black);
    `
  },
  {
    status: 'entregaematraso',
    styles: `
      background: var(--color-wine-berry);
      color: var(--color-white);
    `
  },
  {
    status: 'pago',
    styles: `
      background: var(--color-mantis);
      color: var(--color-white);
    `
  },
  {
    status: 'cancelado',
    styles: `
      background: var(--color-tart-orange);
      color: var(--color-white);
    `
  },
  {
    status: 'recusado',
    styles: `
      background: var(--color-tart-orange);
      color: var(--color-white);
    `
  },
  {
    status: 'esperandopagamento',
    styles: `
      background: var(--color-orange-web);
      color: var(--color-white);
    `
  },
  {
    status: 'entregue',
    styles: `
      background: var(--color-mantis);
      color: var(--color-white);
    `
  },
  {
    status: 'criado',
    styles: `
      background: var(--color-blue-jeans);
      color: var(--color-white);
    `
  },
  {
    status: 'emtransporte',
    styles: `
      background: var(--color-blue-jeans);
      color: var(--color-white);
    `
  },
  {
    status: 'esperandoenvio',
    styles: `
      background: var(--color-blue-jeans);
      color: var(--color-white);
    `
  },
  {
    status: 'faturado',
    styles: `
      background: var(--color-tart-orange);
      color: var(--color-white);
    `
  },
  {
    status: 'emprodução',
    styles: `
      background: var(--color-cadmium-violet);
      color: var(--color-white);
    `
  },
  {
    status: 'client',
    styles: `
      background: var(--color-light-salmon);
      color: var(--color-white);
    `
  },
  {
    status: 'free',
    styles: `
      background: var(--color-mantis);
      color: var(--color-white);
    `
  },
  {
    status: 'support',
    styles: `
      background: var(--color-banana-mania);
      color: var(--color-black);
    `
  },
  {
    status: 'fix',
    styles: `
      background: var(--color-blue-jeans);
      color: var(--color-white);
    `
  },
];

export const findOrderStausStyle = (status) => {
  const styles = orderStatus.find(item => item.status === status.replaceAll(' ', '').toLowerCase());
  return { ...styles, styles: styles.styles + 'padding: 5px 6.25px 3px 4.5px;' }
}

import { PanelContainer, Block } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import JFYSelect from '../../../components/Select';
import LoadingDots from '../../../components/LoadingDots';
import ReactPaginate from 'react-paginate';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left.svg';
import formatPrice from '../../../utils/formatPrice';
import dayjs from 'dayjs';

import styles from './Carts.module.css';
import paginateStyles from '../../../styles/Paginate.module.css';

const Layout = ({
  carts,
  totalPages,
  totalCarts,
  fields,
  loading,
  currentPage,
  pageSize,
  handleChange,
  handlePageChange,
  handlePageSizeChange,
  handleCartClick,
}) => {
  const pageSizes = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;
  };

  return (
    <PanelContainer
      headerMenu
      headerTitle="Carrinhos abandonados"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <Block className={styles.block}>
        <div className={styles.search}>
          <Input
            name="search"
            value={fields.search?.value}
            onChange={handleChange}
            placeholder="Buscar carrinho"
            className={styles.input}
          />
          <Checkbox
            name="all"
            checked={fields.all.value || ''}
            onChange={handleChange}
            className={styles.checkbox}
            label="Incluir carrinhos sem email"
          />
          {(totalCarts || totalCarts === 0) && <p>{totalCarts} carrinhos</p>}
        </div>
        <div className={styles.carts}>
          {!loading ? (
            <>
              {carts.length > 0 ? (
                <>
                  <div className={styles.header}>
                    <p className={styles.date}>Data</p>
                    <p className={styles.value}>Valor</p>
                    <p className={styles.client}>Cliente</p>
                    <p className={styles.voucher}>Cupom</p>
                    <p className={styles.source}>Origem</p>
                  </div>
                  {carts.map((cart, key) => (
                    <div key={key}>
                      {(cart.customer_email || fields.all.value) && (
                        <div
                          className={styles.cart}
                          onClick={(e) => handleCartClick(cart.cart_id, e.ctrlKey)}
                        >
                          <div>
                            <p>{formatDate(cart.created_at)}</p>
                          </div>
                          <div>
                            <p>{formatPrice(cart.cart_value)}</p>
                          </div>
                          <div className={styles.client}>
                            <p>{cart.customer_name}</p>
                            <p>{cart.customer_email}</p>
                          </div>
                          <div className={styles.voucher}>
                            <p>{cart.voucher_code}</p>
                          </div>
                          <div className={styles.utms}>
                            {cart.utm_source && <p>{cart.utm_source}</p>}
                            {cart.utm_medium && <p>{cart.utm_medium}</p>}
                            {cart.utm_campaign && <p>{cart.utm_campaign}</p>}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <p className={styles.notFound}>Nenhum carrinho encontrado</p>
              )}
            </>
          ) : (
            <LoadingDots className={styles.loading} />
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.limit}>
            <JFYSelect
              options={pageSizes}
              defaultValue={
                pageSizes.find((page) => page.value === parseInt(pageSize)) || pageSizes[0]
              }
              isSearchable={false}
              onChange={handlePageSizeChange}
            />
            <p>Itens por página</p>
          </div>
          <div className={styles.pages}>
            <ReactPaginate
              pageCount={totalPages}
              nextLabel={<LeftArrow />}
              previousLabel={<LeftArrow />}
              initialPage={parseInt(currentPage) || undefined}
              previousClassName={paginateStyles.paginationPreviousLink}
              containerClassName={paginateStyles.pagination}
              previousLinkClassName={paginateStyles.paginationLink}
              nextLinkClassName={paginateStyles.paginationLink + ' ' + paginateStyles.next}
              activeClassName={paginateStyles.paginationLinkActive}
              onPageChange={handlePageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { APP_ROUTES } from './appRoutes';
import CustomRoute from './CustomRoute';

const Router = () => (
  <BrowserRouter>
    <Routes>
      {Object.entries(APP_ROUTES).map(([routeKey, route]) => (
        <Route path={route.path} element={<CustomRoute route={route} />} key={routeKey} />
      ))}
    </Routes>
  </BrowserRouter>
);

export default Router;

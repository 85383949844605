import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import Layout from './Layout';

const Cart = () => {
  const { cartId } = useParams();
  const navigate = useNavigate();
  const [cart, setCart] = useState();
  const [loading, setLoading] = useState(false);
  const { fetchFromBackend } = useFetch();

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/carts');
  };

  useEffect(() => {
    const fetchCart = () => {
      setLoading(true);
      fetchFromBackend(`/carts/${cartId}`, 'GET', {}, true).then(async (response) => {
        setCart(response.result);
        setLoading(false);
      });
    };

    if (!cart && !loading) {
      fetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Layout cart={cart} onClickGoBack={handleGoBack} />;
};

export default Cart;

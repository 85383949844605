import { Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import Orders from '../pages/Orders';
import Order from '../pages/Order';
import Vouchers from '../pages/Vouchers';
import Voucher from '../pages/Voucher';
import AddVoucher from '../pages/AddVoucher';
import Campaigns from '../pages/Campaings';
import Campaign from '../pages/Campaign';
import AddCampaign from '../pages/AddCampaign';
import Carts from '../pages/Carts';
import Cart from '../pages/Cart';
import Products from '../pages/Products';
import Product from '../pages/Product';
import AddProducts from '../pages/AddProducts';
import AddHashCampaign from '../pages/AddHashCampaign';
import HashCampaign from '../pages/HashCampaign';
import HashCampaigns from '../pages/HashCampaigns';
import Hashes from '../pages/Hashes';

export const APP_ROUTES = {
  orders: {
    path: '/orders',
    element: <Orders />,
    name: 'Pedidos',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  order: {
    path: '/orders/:orderId',
    element: <Order />,
    name: 'Detalhes do Pedido',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  vouchers: {
    path: '/vouchers',
    element: <Vouchers />,
    name: 'Cupons',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  voucher: {
    path: '/vouchers/:code',
    element: <Voucher />,
    name: 'Detalhes do Cupom',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  addVoucher: {
    path: '/vouchers/add',
    element: <AddVoucher />,
    name: 'Adicionar Cupom',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [2],
    },
  },
  campaigns: {
    path: '/campaigns',
    element: <Campaigns />,
    name: 'Campanhas',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [2],
    },
  },
  campaign: {
    path: '/campaigns/:id',
    element: <Campaign />,
    name: 'Campanha',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [2],
    },
  },
  addCampaign: {
    path: '/campaigns/add',
    element: <AddCampaign />,
    name: 'Criar Campanha',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [2],
    },
  },
  carts: {
    path: '/carts',
    element: <Carts />,
    name: 'Carrinhos Abandonados',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  cart: {
    path: '/carts/:cartId',
    element: <Cart />,
    name: 'Carrinho',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [],
    },
  },
  login: {
    path: '/login',
    element: <Login />,
    name: 'Login',
    menu: false,
    auth: {
      required: false,
    },
  },
  root: {
    path: '/',
    element: <Navigate to="/orders" />,
    menu: false,
    auth: {
      required: false,
    },
  },
  fallback: {
    path: '*',
    element: <Navigate to="/login" />,
    menu: false,
    auth: {
      required: false,
    },
  },
  products: {
    path: '/products',
    element: <Products />,
    name: 'Produtos',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  addProducts: {
    path: '/products/add',
    element: <AddProducts />,
    name: 'Adicionar Produtos',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  product: {
    path: '/products/:productId',
    element: <Product />,
    name: 'Produto',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  addHashCampaign: {
    path: '/hash-campaigns/add',
    element: <AddHashCampaign />,
    name: 'Adicionar Campanha de Cupons',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  hashCampaign: {
    path: '/hash-campaigns/:hashCampaignId',
    element: <HashCampaign />,
    name: 'Visualizar Campanha de Cupons',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  hashCampaigns: {
    path: '/hash-campaigns',
    element: <HashCampaigns />,
    name: 'Códigos de compra',
    menu: true,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
  hashes: {
    path: '/hash-campaigns/:campaign/hashes',
    element: <Hashes />,
    name: 'Lista de códigos',
    menu: false,
    auth: {
      required: true,
      allowList: [],
      denyList: [1, 2],
    },
  },
};

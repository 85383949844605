import styled from 'styled-components';
import { ReactComponent as Search } from '../../assets/icons/search.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 3fr 0.2fr;
  gap: 10px;
  padding: 6px;

  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray-web);

  @media (max-width: 1024px) {
    padding: 4px;
  }
`;

export const Input = styled.input`
  border:0;
  font-family: 'Montserrat';
  font-size: var(--font-size-h3);
  font-weight: 400;

  letter-spacing: 0em;
  text-align: left;
  outline: none;
  min-height: 25px;
  font-size: 13px;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const SearchIcon = styled(Search)`
  fill: var(--color-dark-salmon);
  stroke: var(--color-dark-salmon);
  cursor: pointer;
  width: 20px;
`;

import styles from './Modal.module.css';

const Modal = ({
  acceptText = 'Ok',
  cancelText = 'Cancelar',
  onAccept,
  onCancel,
  active = true,
  children,
}) => {
  return (
    <div className={styles.modal + (active ? ` ${styles.active}` : '')}>
      <div className={styles.content}>
        {children}
        <div className={styles.actions}>
          {onCancel && <p onClick={onCancel && onCancel} className={styles.cancel} data-testid="cancel-operator-modal">
            {cancelText}
          </p>}
          <p onClick={onAccept && onAccept} className={styles.accept} data-testid="accept-operator-modal">
            {acceptText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;

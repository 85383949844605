import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

import Layout from './Layout';

const Hashes = () => {
  const navigate = useNavigate();
  const { campaign } = useParams();
  const { fetchFromBackend } = useFetch();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [hashes, setHashes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleGoBack = () => {
    navigate(
      window.history.state && window.history.state.idx > 0 ? -1 : `/hash-campaigns/${campaign}`
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.value);
  };

  const search = () => {
    fetchHashes(0, pageSize, searchTerm);
  };

  const onSearchFieldChange = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const fetchHashes = (page = 0, limit = 50, search = '') => {
    let params;
    let requestURL;
    if (search === '') {
      params = createSearchParams({ page: page, limit: limit });
      requestURL = decodeURIComponent(`/hash/campaigns/${campaign}/hashes?${params}`);
    } else {
      params = createSearchParams({ page: page, limit: limit, search_term: searchTerm });
      requestURL = decodeURIComponent(`/hash/campaigns/${campaign}/hashes/filter?${params}`);
    }
    setLoading(true);
    fetchFromBackend(requestURL, 'GET', {}, true)
      .then((response) => {
        setLoading(false);
        setHashes(response.hashes);
        setTotalPages(Math.ceil(response.total / limit));
      })
      .catch((error) => {
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchHashes(currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return (
    <Layout
      loading={loading}
      hashes={hashes}
      campaign={campaign}
      pageSize={pageSize}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      currentPage={currentPage}
      totalPages={totalPages}
      search={search}
      searchTerm={searchTerm}
      onSearchFieldChange={onSearchFieldChange}
      onClickGoBack={handleGoBack}
    />
  );
};

export default Hashes;

import React from 'react';
import { Container, Button } from './styles';

export const FilterControl = ({ handleClearFilters, handleControllFilters, isOpen }) => {
  return (
    <Container>
      <Button onClick={handleClearFilters}>+Limpar filtros</Button>
      <Button onClick={handleControllFilters}>{isOpen ? '- Menos' : '+ Mais'} filtros</Button>
    </Container>
  );
};

import { Header } from './Header';

import styles from './PanelContainer.module.css';
import { useEffect, useState } from 'react';

export const PanelContainer = ({
  children,
  className,
  blocksClassName,
  headerTitle,
  onGoBack,
  headerMenu,
  headerLogo,
  headerClassName,
  disableMenuOverlayClick,
  ...props
}) => {
  const mobileDevice = window.innerWidth < 980;
  const [menuClosed, setMenuClosed] = useState(mobileDevice);
  useEffect(() => {
    if (typeof headerMenu === 'undefined') {
      setMenuClosed(true);
    }
  }, [headerMenu]);

  return (
    <div
      className={
        (menuClosed ? styles.panelContainerClosedMenu : styles.panelContainer) +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      <Header
        title={headerTitle}
        onGoBack={onGoBack}
        menu={headerMenu}
        menuTransition={mobileDevice}
        onClose={() => {
          setMenuClosed(true);
        }}
        onOpen={() => {
          setMenuClosed(false);
        }}
        isOpen={!menuClosed}
        logo={headerLogo}
        className={headerClassName}
        disableMenuOverlayClick={disableMenuOverlayClick}
      />
      <div className={styles.blocksWrap}>
        <div className={styles.blocks + (blocksClassName ? ' ' + blocksClassName : '')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PanelContainer;
export * from './Block';

import Select from 'react-select';

import { PanelContainer, Block } from '../../../components/PanelContainer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Switch from '../../../components/Switch';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import ProductsList from '../../../components/ProductsList';
import FileInput from '../../../components/FileInput';

import styles from './AddHashCampaign.module.css';

const Layout = ({
  fields,
  handleChange,
  handleFieldChange,
  handleSelectChange,
  handleClickAddCampaign,
  products,
  setProducts,
  onClickGoBack,
  sheetOptions,
  loading,
  emailsMode,
  handleUploadFile,
  handleRemoveFile,
  filename,
  rawFilename,
  badEmails,
  validEmails,
  handleModeChange,
}) => {
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      height: '37px',
    }),

    container: (provided, state) => ({
      ...provided,
      width: '200px',
      height: '37px',
    }),
    input: (provided, state) => ({
      ...provided,
      marginTop: '-10px',
    }),
  };
  return (
    <PanelContainer
      headerMenu
      headerTitle="Criar Campanha de Códigos Promocionais"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      <div className={styles.header}>
        <Button
          tertiary
          icon={<ArrowLeft />}
          text="ver todos as campanhas"
          className={styles.back}
          onClick={onClickGoBack}
        />
      </div>
      <Block className={styles.block}>
        <div className={styles.flexContainer}>
          <div className={styles.modeContainer}>
            <h2 className={styles.noTopMargin}>Modo de criação</h2>
            <ChoiceGroup
              name="mode"
              selected="true"
              className={styles.options}
              onChange={handleModeChange}
            >
              <Choice className={styles.choice} title="Lista" value="true" />
              <Choice className={styles.choice} title="Emails" value="false" />
            </ChoiceGroup>
            {emailsMode ? (
              <p className={styles.hintText}>
                Neste modo os links de compra serão enviados via email diretamente para os
                utilizadores. Cada utilizador pode receber 1 ou mais links, quantidade essa definida
                no campo abaixo. Os emails devem estar listados em um arquivo do tipo CSV que será
                carregado e validado. Este arquivo deve conter uma coluna cujo cabeçalho é "email" e
                cada linha terá um email válido.
              </p>
            ) : (
              <p className={styles.hintText}>
                Neste modo todos os links de compra gerados serão armazenados em um arquivo CSV,
                cuja URL para download será enviado para o seu email. O campo de quantidade abaixo
                controlorá o total de links de compra gerados, e estes deverão ser distribuídos
                manualmente.
              </p>
            )}
            <div className={styles.quantity}>
              {emailsMode ? (
                <h2>Quantidade de códigos por email</h2>
              ) : (
                <h2>Quantidade total de códigos</h2>
              )}
              <Input
                name="hashesQuantity"
                value={fields.hashesQuantity?.value}
                valid={!fields.hashesQuantity?.valid ? fields.hashesQuantity?.valid : null}
                message={fields.hashesQuantity?.message}
                placeholder=""
                className={styles.input}
                onChange={handleChange}
                type="number"
                min="1"
              />
            </div>
          </div>
          <div className={styles.emails}>
            {emailsMode && (
              <>
                <h2 className={styles.noTopMargin}>Emails</h2>
                <div className={styles.emailButtons}>
                  <FileInput
                    text="Carregar CSV"
                    onClick={handleUploadFile}
                    secondary
                    className={styles.uploadButton}
                  />
                  {filename && (
                    <Button
                      text="Limpar"
                      className={styles.cleanButton}
                      onClick={handleRemoveFile}
                      secondary
                    />
                  )}
                </div>
              </>
            )}
            {emailsMode && filename && (
              <p
                className={styles.rawFilename}
              >{`${rawFilename} - ${validEmails.length} emails`}</p>
            )}
            {emailsMode && (validEmails.length > 0 || badEmails.length > 0) && (
              <div className={styles.emailsList}>
                {badEmails.length > 0 &&
                  badEmails.map((email) => <p className={styles.badEmail}>{`- ${email}`}</p>)}
                {validEmails.length > 0 && validEmails.map((email, idx) => <p>{`- ${email}`}</p>)}
              </div>
            )}
          </div>
        </div>
      </Block>
      <Block className={styles.block}>
        <div className={styles.name}>
          <h2>Nome da Campanha</h2>
          <Input
            name="campaignName"
            value={fields.campaignName?.value}
            valid={!fields.campaignName?.valid ? fields.campaignName?.valid : null}
            message={fields.campaignName?.message}
            placeholder=""
            className={styles.input}
            onChange={handleChange}
          />
        </div>
        <h2>Data de expiração</h2>
        <Input
          name="expirationDate"
          type="datetime-local"
          value={fields.expirationDate?.value}
          className={styles.date}
          onChange={handleChange}
        />
        <h2 className={styles.closeheader}>Marketing</h2>
        <div className={styles.flexContainer}>
          <div>
            <h3>CRM</h3>
            <Switch
              checked={fields.crm?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('crm', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h3>Analytics</h3>
            <Switch
              checked={fields.analytics?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('analytics', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div>
            <h2>Gerará Nota fiscal</h2>
            <Switch
              checked={fields.sendInvoice?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendInvoice', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h2>Enviará pela transportadora</h2>
            <Switch
              checked={fields.ship?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('ship', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <h2 className={styles.closeheader}>Transacional</h2>
        <div className={styles.flexContainer}>
          <div>
            <h3>Email</h3>
            <Switch
              checked={fields.sendEmail?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendEmail', e ? 'true' : 'false');
              }}
            />
          </div>
          <div>
            <h3>SMS</h3>
            <Switch
              checked={fields.sendSms?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('sendSms', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <div className={styles.sheet}>
          <h2>Planilha</h2>
          <Select
            key="sheetName"
            options={sheetOptions}
            placeholder="Planilha"
            hideSelectedOptions
            className="filter-container"
            classNamePrefix="filter"
            styles={selectStyle}
            isClearable={false}
            onChange={(selected) => handleSelectChange(selected, 'sheetName')}
          />
        </div>
        <div>
          <h2>Produtos</h2>
          {products && <ProductsList products={products} setProducts={setProducts} />}
        </div>
        <Button
          primary
          text="Criar"
          className={styles.button}
          onChange={handleChange}
          onClick={handleClickAddCampaign}
          loading={loading}
        />
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg';
import { Block, PanelContainer } from '../../../components/PanelContainer';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import formatPrice from '../../../utils/formatPrice';

import Button from '../../../components/Button';
import styles from './Order.module.css';

dayjs.extend(dayjsBusinessDays);

const Layout = ({ order, products, discounts, statusHistory, onClickGoBack }) => {
  const statuses = {
    2: { string: 'Criado', color: 'blue' },
    3: { string: 'Aguardando Pagamento', color: 'yellow' },
    4: { string: 'Pago', color: 'green' },
    5: { string: 'Aguardando Envio', color: 'blue' },
    6: { string: 'Em transporte', color: 'blue' },
    7: { string: 'Entregue', color: 'green' },
    8: { string: 'Cancelado', color: 'red' },
    9: { string: 'Recusado', color: 'red' },
    11: { string: 'Em Manipulação', color: 'purple' },
  };

  const paymentMethods = {
    credit_card: 'Cartão de crédito',
    pix: 'Pix',
    hash: 'Hash',
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;
  };

  const calculateDeliveryDate = (date, businessDays) => {
    return date ? dayjs(date).businessDaysAdd(parseInt(businessDays)).format('DD/MM/YYYY') : null;
  };

  const formatInstallments = (installments, total) => {
    if (installments && total) {
      return `${installments}x ${formatPrice((total / installments).toFixed(0))}`;
    } else {
      return '';
    }
  };

  const getPaymentGatewayUrl = () => {
    if (order.gateway_id?.split('-').length > 1) {
      return process.env.REACT_APP_WIRECARD_URL;
    }
    return process.env.REACT_APP_PAGARME_URL;
  };

  return (
    <PanelContainer
      headerMenu
      className={styles.container}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      {order && products && discounts && (
        <>
          <Button
            tertiary
            icon={<ArrowLeft />}
            text="ver todos os pedidos"
            className={styles.back}
            onClick={onClickGoBack}
          />
          <Block className={styles.block + ' ' + styles.header}>
            <div>
              <h1>Pedido: {order.public_id}</h1>
              <h2>{formatDate(order.creation_date)}</h2>
            </div>
            <div className={styles.status + ' ' + styles[statuses[order.status_id].color]}>
              <h1>{order.status}</h1>
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.client}>
              <h2>CLIENTE</h2>
              <p>
                <b>{order.customer_name}</b>
              </p>
              <p>{order.customer_email}</p>
              <p>CPF: {order.customer_cpf}</p>
              <a
                href={
                  'https://api.whatsapp.com/send?phone=55' +
                  order.customer_ddd +
                  order.customer_phone
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.phone}
              >
                <WhatsAppIcon />
                <p>{formatPhoneNumber(order.customer_ddd, order.customer_phone)}</p>
              </a>
            </div>
            <div className={styles.total}>
              <h2>TOTAL</h2>
              <h3>{formatPrice(order.final_value)}</h3>
              <p>{paymentMethods[order.payment_type]}</p>
              <p>{formatInstallments(order.transaction?.installments, order.final_value)}</p>
            </div>
            <div className={styles.discounts}>
              <h2>DESCONTOS</h2>
              {order.voucher_code && (
                <p>
                  Cupom:{' '}
                  <a
                    href={'/vouchers/' + order.voucher_code}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.promocode}
                  >
                    {order.voucher_code}
                  </a>
                  {order.voucher_is_percent && ` (${order.voucher_value}%)`}
                </p>
              )}
              <p>
                Produtos: <b>{formatPrice(order.total_value)}</b>
              </p>
              {/* {discounts?.voucher > 0 && ( */}
              {/*   <p> */}
              {/*     Valor Cupom: <b>-{formatPrice(discounts.voucher)}</b> */}
              {/*   </p> */}
              {/* )} */}
              {discounts?.payment > 0 && (
                <p>
                  Método de pagamento: <b>-{formatPrice(discounts.payment)}</b>
                </p>
              )}
              <p>
                Frete:
                {order.shipping_fee > 0 ? (
                  <b> +{formatPrice(order.shipping_fee)}</b>
                ) : (
                  <b className={styles.free}> Grátis</b>
                )}
              </p>
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.address}>
              <h2>ENDEREÇO DE ENTREGA</h2>
              <p>
                {order.customer_address.trim()}, {order.customer_number} - {order.customer_district}
              </p>
              {order.customer_complement && <p>Complemento: {order.customer_complement}</p>}
              <p>{order.customer_city + '/' + order.customer_state}</p>
              <p>CEP: {order.customer_zip}</p>
              {order.customer_reference_point && (
                <p>Ponto de referência: {order.customer_reference_point}</p>
              )}
            </div>
            <div className={styles.delivery}>
              <div>
                <h2>PREVISÃO DE ENTREGA</h2>
                <p>
                  {calculateDeliveryDate(order.creation_date, parseInt(order.days_to_deliver))} (
                  {order.days_to_deliver} dias úteis)
                </p>
              </div>
              <div>
                <h2>TRANSPORTADORA</h2>
                <p>{order.carrier}</p>
              </div>
              <div>
                <h2>RASTREIO</h2>
                {order.tracking_code === '' ? (
                  <p>Indisponível</p>
                ) : (
                  <a
                    href={order.tracking_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.tracking}
                  >
                    {order.tracking_code}
                  </a>
                )}
              </div>
              <div>
                <h2>BLING</h2>
                <p>{order.bling_id === '' ? 'Indisponível' : order.bling_id}</p>
              </div>
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.products}>
              <h2>PRODUTOS</h2>
              {products.map((form, key) => (
                <div className={styles.form} key={key}>
                  <p>
                    Fórmula: <b>{form.formName}</b>
                  </p>
                  <p>ID: {form.formId}</p>
                  <div className={styles.formProducts}>
                    {form.products.map((product, key) => (
                      <div className={styles.product} key={key}>
                        <p>
                          <b>{product.quantity}x</b> {product.product_display_name}
                        </p>
                        <p>{formatPrice(product.value)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.payment}>
              <div>
                <h2>MÉTODO DE PAGAMENTO</h2>
                <p>{paymentMethods[order.payment_type]}</p>
              </div>
              <div>
                <h2>GATEWAY DE PAGAMENTO</h2>
                <p>
                  {order.gateway_id ? (
                    <a
                      href={getPaymentGatewayUrl() + order.gateway_id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.gateway}
                    >
                      {order.gateway_id}
                    </a>
                  ) : (
                    'Indisponível'
                  )}
                </p>
              </div>
            </div>
            <div className={styles.invoice}>
              <h2>NOTA FISCAL</h2>
              <p>
                {order.nf_id ? (
                  <a
                    href={order.nf_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.gateway}
                  >
                    {order.nf_id}
                  </a>
                ) : (
                  'Indisponível'
                )}
              </p>
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.history}>
              <h2>HISTÓRICO</h2>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.marker}></div>
                  <div className={styles.content}>
                    <p>
                      <b>Criado</b>
                    </p>
                    <p>{formatDate(order.creation_date)}</p>
                  </div>
                </div>
                {statusHistory?.changes.map((statusChange, key) => (
                  <div className={styles.item} key={key}>
                    <div className={styles.marker}></div>
                    <div className={styles.content}>
                      <p>
                        <b>{statuses[statusChange.status_to].string}</b>
                      </p>
                      <p>{formatDate(statusChange.updated)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.tracking}>
              {(order.utm_source ||
                order.utm_campaign ||
                order.utm_medium ||
                order.utm_content ||
                order.utm_term) && (
                <>
                  <h2>TRACKING</h2>
                  {order?.utm_source && (
                    <p>
                      Origem: <b>{order?.utm_source}</b>
                    </p>
                  )}
                  {order?.utm_campaign && (
                    <p>
                      Campanha: <b>{order?.utm_campaign}</b>
                    </p>
                  )}
                  {order?.utm_medium && (
                    <p>
                      Meio: <b>{order?.utm_medium}</b>
                    </p>
                  )}
                  {order?.utm_content && (
                    <p>
                      Conteúdo: <b>{order?.utm_content}</b>
                    </p>
                  )}
                  {order?.utm_term && (
                    <p>
                      Termo: <b>{order?.utm_term}</b>
                    </p>
                  )}
                </>
              )}
            </div>
          </Block>
          <Block className={styles.block}>
            <div className={styles.transactional}>
              <h2>MENSAGENS TRANSACIONAIS</h2>
              <div>
                <div className={styles.activity}>
                  <p>
                    <b>Emails</b>
                  </p>
                  <div className={styles.list}>
                    {order?.email_statuses.map((email, key) => (
                      <div
                        key={key}
                        className={styles.contact + (email.sent ? ' ' + styles.sent : '')}
                      >
                        <span className={styles.tooltip}>{email.subject}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.activity}>
                  <p>
                    <b>SMS</b>
                  </p>
                  <div className={styles.list}>
                    {order?.sms_statuses.map((message, key) => (
                      <div
                        key={key}
                        className={styles.contact + (message.sent ? ' ' + styles.sent : '')}
                      >
                        <span className={styles.tooltip}>{message.subject}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </>
      )}
    </PanelContainer>
  );
};

export default Layout;

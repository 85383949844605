import { PanelContainer, Block } from '../../../components/PanelContainer';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingDots from '../../../components/LoadingDots';
import ProductsList from '../../../components/ProductsList';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import styles from './AddCampaign.module.css';

const Layout = ({
  campaign,
  fields,
  handleChange,
  loading,
  onClickGoBack,
  handleFieldChange,
  handleClickSave,
  products,
  setProducts
}) => {
  return (
    <PanelContainer
      headerMenu
      className={styles.container}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      <div className={styles.header}>
        <Button
          tertiary
          icon={<ArrowLeft />}
          text="ver todos as campanhas"
          className={styles.back}
          onClick={onClickGoBack}
        />
      </div>
      <Block className={styles.block}>
        {!loading ? (
          <>
            <div className={styles.row}>
              <div className={styles.name}>
                <h2>Nome</h2>
                <Input
                  name="name"
                  value={fields.name?.value}
                  valid={!fields.name?.valid ? fields.name?.valid : null}
                  message={fields.name?.message}
                  placeholder=""
                  className={styles.input}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.voucher}>
                <h2>Cupom</h2>
                <Input
                  name="voucher"
                  value={fields.voucher?.value}
                  valid={!fields.voucher?.valid ? fields.voucher?.valid : null}
                  message={fields.voucher?.message}
                  placeholder=""
                  className={styles.input}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.utms}>
              <h2>UTMS</h2>
              <div className={styles.row}>
                <div>
                  <h2>Campaign</h2>
                  <Input
                    name="utmCampaign"
                    value={fields.utmCampaign?.value}
                    valid={!fields.utmCampaign?.valid ? fields.utmCampaign?.valid : null}
                    message={fields.utmCampaign?.message}
                    placeholder=""
                    className={styles.input}
                    onChange={handleChange}
                  />
                  <h2>Source</h2>
                  <Input
                    name="utmSource"
                    value={fields.utmSource?.value}
                    valid={!fields.utmSource?.valid ? fields.utmSource?.valid : null}
                    message={fields.utmSource?.message}
                    placeholder=""
                    className={styles.input}
                    onChange={handleChange}
                  />
                  <h2>Medium</h2>
                  <Input
                    name="utmMedium"
                    value={fields.utmMedium?.value}
                    valid={!fields.utmMedium?.valid ? fields.utmMedium?.valid : null}
                    message={fields.utmMedium?.message}
                    placeholder=""
                    className={styles.input}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <h2>Content</h2>
                  <Input
                    name="utmContent"
                    value={fields.utmContent?.value}
                    valid={!fields.utmContent?.valid ? fields.utmContent?.valid : null}
                    message={fields.utmContent?.message}
                    placeholder=""
                    className={styles.input}
                    onChange={handleChange}
                  />
                  <h2>Term</h2>
                  <Input
                    name="utmTerm"
                    value={fields.utmTerm?.value}
                    valid={!fields.utmTerm?.valid ? fields.utmTerm?.valid : null}
                    message={fields.utmTerm?.message}
                    placeholder=""
                    className={styles.input}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.products}>
              <div>
                <h2>Produtos</h2>
                {products && <ProductsList products={products} setProducts={setProducts} />}
              </div>
            </div>
            <Button
              primary
              text="Criar Campanha"
              onClick={handleClickSave}
              className={styles.button}
            />
          </>
        ) : (
          <LoadingDots />
        )}
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Layout from './Layout';
import uploadToS3 from '../../utils/uploadFile';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import notify, { MISSING_FIELDS } from '../../utils/notify';
import { numericMask } from '../../utils/formater';
import formatPrice from '../../utils/formatPrice';

const AddProducts = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [imageUrl, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urlText, setUrlText] = useState('');
  const [urlTextInfo1, setUrlTextInfo1] = useState('');
  const [urlTextInfo2, setUrlTextInfo2] = useState('');
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange, setFieldValue, handleFinish, setFieldProperty } = useForm({
    name: {
      required: true,
    },
    displayWeight: {
      required: true,
    },
    description: {
      required: false,
    },
    brand: {
      required: false,
    },
    prodTime: {
      required: true,
    },
    sku: {
      required: true,
    },
    bling: {
      required: true,
    },
    ncm: {
      required: false,
    },
    price: {
      required: true,
      mask: numericMask,
    },
    height: {
      required: false,
    },
    width: {
      required: false,
    },
    length: {
      required: false,
    },
    netWeight: {
      required: true,
    },
    grossWeight: {
      required: true,
    },
    active: {
      required: true,
      value: 'true',
    },
    custom: {
      required: true,
      value: 'false',
    },
    benefit: {
      required: true,
      value: 'false',
    },
    shelf: {
      required: true,
      value: 'false',
    },
    productType: {
      required: true,
      value: ['physical'],
    },
    taxType: {
      required: true,
      value: ['product'],
    },
    weightUnit: {
      required: true,
      value: ['mg'],
    },
    dimensionUnit: {
      required: true,
      value: ['mm'],
    },
  });
  const onClickGoBack = () => {
    navigate('/products/');
  };

  const handleFileInput = (evt) => {
    if (file === null) {
      setFile(evt.target.files[0]);
      setFileName(evt.target.value);
    } else {
      setFile(evt.target.files[0]);
      setFileName(evt.target.value);
    }
  };

  const submitFile = async () => {
    if (fields.name.value !== null) {
      setLoading(true);
      let obj = await uploadToS3(file);
      setImageURL(obj.location);
      setLoading(false);
    }
  };

  const createProduct = async () => {
    setLoading(true);
    const { valid } = await handleFinish();
    if (valid) {
      let formattedPrice = parseInt(fields.price.value.replace(',', ''));
      const slugName = fields.name.value.replace(' ', '_').toLowerCase();
      const productObject = {
        active: fields.active.value,
        is_benefit: fields.benefit.value,
        is_shelf: fields.shelf.value,
        is_custom: fields.custom.value,
        value: formattedPrice,
        sku: fields.sku.value,
        name: slugName,
        display_name: fields.name.value,
        weight: fields.grossWeight.value,
        description: fields.description.value,
        image: imageUrl,
        bling_code: fields.bling.value,
        brand: fields.brand.value,
        ncm_code: fields.ncm.value,
        size_display: fields.displayWeight.value,
        net_weight: fields.netWeight.value,
        gross_weight: fields.grossWeight.value,
        production_time: fields.prodTime.value,
        length: fields.length.value,
        height: fields.height.value,
        width: fields.width.value,
        weight_unit: fields.weightUnit.value[0],
        dimension_unit: fields.dimensionUnit.value[0],
        product_type: fields.productType.value[0],
        tax_type: fields.taxType.value[0],
      };
      fetchFromBackend('/products', 'POST', productObject, true)
        .then((response) => {
          setLoading(false);
          notify('Produto Criado!', 'success');
        })
        .catch((error) => {
          notify('Erro ao salvar produto', 'error');
          setLoading(false);
        });
    } else {
      notify(MISSING_FIELDS, 'error');
      setLoading(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);
  };

  const handleSelectChange = (targetValue, targetName, isMulti) => {
    let fieldValue = [];
    if (targetName === 'taxType') {
      if (fieldValue === 'product') {
        setFieldProperty('bling', 'required', true);
      } else {
        setFieldProperty('bling', 'required', false);
      }
    }
    targetValue.forEach((selected) => selected && fieldValue.push(selected.value));
    handleChange({ target: { name: targetName, value: fieldValue } });
  };

  const formatedValue = () => {
    if (fields.price.value) {
      let parsedValue = fields.price.value.replace(',', '');
      return formatPrice(parseInt(parsedValue));
    }
    return null;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlText);
    notify('URL Copiada para Área de Transferência', 'success');
  };

  useEffect(() => {
    const sku = fields.sku.value;
    var url_text =
      fields.custom.value === 'true'
        ? 'https://checkout.justfor.com.br/?form=<form id>&products=' + sku + ':1'
        : 'https://checkout.justfor.com.br/?products=' + sku + ':1';
    setUrlText(url_text);
  }, [fields]);

  useEffect(() => {
    var url_text_info1 =
      fields.custom.value === 'true'
        ? 'Para utilizar a URL, substitua <formid> por um form id válido'
        : '';
    var url_text_info2 =
      fields.custom.value === 'true'
        ? 'https://checkout.justfor.com.br/?form=<formid>&products=<sku1>:<quantidade>,<sku2>:<quantidade>'
        : 'https://checkout.justfor.com.br/?products=<sku1>:<quantidade>,<sku2>:<quantidade>';
    setUrlTextInfo1(url_text_info1);
    setUrlTextInfo2(url_text_info2);
  }, [fields]);

  return (
    <Layout
      onClickGoBack={onClickGoBack}
      handleFileInput={handleFileInput}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      handleSelectChange={handleSelectChange}
      submitFile={submitFile}
      file={file}
      fileName={fileName}
      fields={fields}
      createProduct={createProduct}
      imageUrl={imageUrl}
      formatedValue={formatedValue}
      loading={loading}
      copyToClipboard={copyToClipboard}
      urlText={urlText}
      urlTextInfo1={urlTextInfo1}
      urlTextInfo2={urlTextInfo2}
    />
  );
};

export default AddProducts;

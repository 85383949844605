import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import notify, { UNAUTHORIZED } from '../utils/notify';
import { NetworkResponseWasNotOk, BadRequestError } from '../utils/customErrors';

const useFetch = () => {
  const authContext = useContext(AuthContext);
  const fetchFromBackend = async (
    endpoint = '',
    method,
    body = {},
    checkResponseForError = false,
    token = null,
    isForm = false
  ) => {
    const headers = new Headers();

    let payload;
    if (!isForm) {
      headers.append('Content-Type', 'application/json');
      payload = JSON.stringify(body);
    } else {
      payload = body;
    }

    if (authContext.token) {
      const tokenValid = await authContext?.validadeAndRefreshToken(authContext.token || token);
      headers.append('Token', tokenValid);
    } else {
      headers.append('Token', token);
    }
    headers.append('auth-token', process.env.REACT_APP_API_AUTH_TOKEN);

    let init = { method, headers };
    if (method !== 'GET') {
      init = { method, headers, body: payload };
    }

    return fetch(process.env.REACT_APP_API_BASE_URL + endpoint, init)
      .then(async (result) => {
        if (result.status === 401) {
          notify(UNAUTHORIZED, 'error');
          authContext.logout();
        }

        if (!result.ok && checkResponseForError) {
          throw new BadRequestError(`${result.status} (${result.statusText})`, await result.json());
        }

        const body = await result.json();
        const response = body;
        if (!checkResponseForError) response.responseStatus = result.status;
        return response;
      })
      .catch((error) => {
        throw new NetworkResponseWasNotOk(
          init.method,
          process.env.REACT_APP_API_BASE_URL + endpoint,
          error
        );
      });
  };

  return { fetchFromBackend };
};

export default useFetch;

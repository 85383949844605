import _ from 'lodash';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import notify, { INTERNAL_ERROR, MISSING_FIELDS } from '../../utils/notify';
import Layout from './Layout';

const HashCampaign = () => {
  const navigate = useNavigate();
  const { fetchFromBackend } = useFetch();
  const { hashCampaignId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sheetOptions, setSheetOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [initialProducts, setInitialProducts] = useState([]);
  const [defaultSheet, setDefaultSheet] = useState();
  const [someChanged, setSomeChanged] = useState(false);
  const {
    fields,
    handleChange,
    setFieldValue,
    handleFinish,
    anyChanged,
    setInitialValues,
    getChangedFieldNames,
  } = useForm({
    quantityTotal: {
      required: false,
    },
    quantityUsed: {
      required: false,
    },
    campaignName: {
      required: true,
    },
    expirationDate: {
      required: true,
    },
    crm: {
      required: true,
      value: 'false',
    },
    analytics: {
      required: true,
      value: 'false',
    },
    sendInvoice: {
      required: true,
      value: 'false',
    },
    ship: {
      required: true,
      value: 'true',
    },
    sendEmail: {
      required: true,
    },
    sendSms: {
      required: true,
    },
    sheetName: {
      required: true,
    },
    productOptions: {
      required: true,
      value: [],
    },
  });

  useEffect(() => {
    if (someProductChanged() || anyChanged) {
      setSomeChanged(true);
    } else {
      setSomeChanged(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, products, initialProducts, anyChanged]);

  useEffect(() => {
    const fetchHashCampaign = async () => {
      const res = await fetchFromBackend('/hash/campaign/' + hashCampaignId, 'GET');

      const expDate = dayjs(res.expiration_date.split('+')[0])
        .subtract(3, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss');

      // set fields from useForm
      setFieldValue('quantityTotal', String(res.quantity_total));
      setFieldValue('quantityUsed', String(res.quantity_used));
      setFieldValue('campaignName', res.name);
      setFieldValue('expirationDate', expDate);
      setFieldValue('crm', String(res.crm));
      setFieldValue('analytics', String(res.analytics));
      setFieldValue('sendInvoice', String(res.send_invoice));
      setFieldValue('sendSms', String(res.send_sms));
      setFieldValue('sendEmail', String(res.send_email));
      setFieldValue('ship', String(res.shipment));
      setFieldValue('sheetName', res.sheet);
      setInitialValues();

      setDefaultSheet({ value: res.sheet, label: res.sheet });

      // populate products state so it will be used in child component
      let productFields = [];
      res.products.forEach((product) => {
        productFields.push({
          id: String(product.product_id),
          value: String(product.product_id),
          quantity: String(product.quantity),
        });
      });
      setProducts(productFields);

      // save initial products to be compared later
      setInitialProducts(productFields.map((p) => ({ ...p })));
    };

    fetchHashCampaign();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: fetch existent sheets from an API and show then here
    if (sheetOptions.length === 0) {
      const sheets = ['hash', 'givers', 'data', 'influencers', 'projeto-amora'];
      setSheetOptions(sheets.map((sheet) => ({ value: sheet, label: sheet })));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetOptions]);

  const handleGoBack = () => {
    navigate('/hash-campaigns');
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);
  };

  const handleSelectChange = (selected, targetField) => {
    handleChange({ target: { name: targetField, value: selected.value } });
  };

  const validateProducts = () => {
    // check if any field are empty
    const unfilledProducts = products.filter((product) => !(product.value && product.quantity));
    if (unfilledProducts.length > 0) return false;

    // check for bad quantities
    const quantities = products.flatMap((product) => product.quantity);
    const badQuantities = quantities.filter((quantity) => quantity <= 0);
    if (badQuantities.length > 0) return false;

    return true;
  };

  const someProductChanged = () => {
    return !_.isEqual(products, initialProducts);
  };

  const createHashCampaign = async () => {
    setLoading(true);

    // validate
    const { valid } = await handleFinish();
    const validProducts = validateProducts();

    if (!valid || !validProducts) {
      if (!valid) notify(MISSING_FIELDS, 'error');
      if (!validProducts) notify('Corrija ou preencha os produtos', 'error');
      setLoading(false);
      return;
    }

    // fill request body with changed fields
    let productsBody = [];
    if (someProductChanged()) {
      productsBody = products.map((product) => ({
        product_id: product.value,
        quantity: product.quantity,
      }));
    }

    const changedFields = getChangedFieldNames();

    const expirationDate = dayjs(fields.expirationDate.value)
      .add(3, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss+00:00');

    const reqBody = {
      id: hashCampaignId,
      expiration_date: changedFields.includes('expirationDate') ? expirationDate : undefined,
      crm: changedFields.includes('crm') ? fields.crm.value : undefined,
      analytics: changedFields.includes('analytics') ? fields.analytics.value : undefined,
      send_invoice: changedFields.includes('sendInvoice') ? fields.sendInvoice.value : undefined,
      sheet: changedFields.includes('sheetName') ? fields.sheetName.value : undefined,
      shipment: changedFields.includes('ship') ? fields.ship.value : undefined,
      send_email: changedFields.includes('sendEmail') ? fields.sendEmail.value : undefined,
      send_sms: changedFields.includes('sendSms') ? fields.sendSms.value : undefined,
      products: productsBody.length > 0 ? productsBody : undefined,
    };

    // remove unchanged fields
    Object.keys(reqBody).forEach((key) => {
      if (reqBody[key] === undefined) {
        delete reqBody[key];
      }
    });

    try {
      await fetchFromBackend('/hash/campaign', 'PATCH', reqBody, true);
      notify('Campanha atualizada com sucesso', 'success');

      // reset initial values
      setInitialValues();
      setInitialProducts(products.map((p) => ({ ...p })));
    } catch (err) {
      notify(INTERNAL_ERROR, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      handleSelectChange={handleSelectChange}
      onClickGoBack={handleGoBack}
      handleClickAddCampaign={createHashCampaign}
      products={products}
      setProducts={setProducts}
      defaultSheet={defaultSheet}
      sheetOptions={sheetOptions}
      anyChanged={someChanged}
      loading={loading}
      campaignId={hashCampaignId}
    />
  );
};

export default HashCampaign;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import notify, { INTERNAL_ERROR } from '../../utils/notify';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';

import Layout from './Layout';

const Campaigns = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaings] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [loading, setLoading] = useState();
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange } = useForm({
    search: {
      required: false,
    },
  });

  const handleCampaignClick = (id, newTab = false) => {
    const url = '/campaigns/' + id;
    if (newTab) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const handleNewCampaign = () => {
    navigate('/campaigns/add');
  };

  useEffect(() => {
    if (fields.search.value) {
      setFilteredCampaigns(
        campaigns.filter((campaign) =>
          campaign.campaign_name.toLowerCase().includes(fields.search.value.toLowerCase())
        )
      );
    } else {
      setFilteredCampaigns(campaigns);
    }
  }, [fields, campaigns]);

  useEffect(() => {
    const fetchCampaings = () => {
      setLoading(true);
      fetchFromBackend('/campaigns', 'GET', {}, true)
        .then((response) => {
          setCampaings(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          notify(INTERNAL_ERROR);
        });
    };

    if (campaigns.length === 0) fetchCampaings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      campaigns={filteredCampaigns}
      fields={fields}
      handleChange={handleChange}
      loading={loading}
      handleCampaignClick={handleCampaignClick}
      handleNewCampaign={handleNewCampaign}
    />
  );
};

export default Campaigns;

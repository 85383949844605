import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { noSpecialCharsMask } from '../../utils/formater';
import notify, { INTERNAL_ERROR, MISSING_FIELDS } from '../../utils/notify';
import Layout from './Layout';

const AddHashCampaign = () => {
  const navigate = useNavigate();
  const { fetchFromBackend } = useFetch();
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState();
  const [rawFilename, setRawFilename] = useState();
  const [sheetOptions, setSheetOptions] = useState([]);
  const [emailsMode, setEmaislMode] = useState(false);
  const [validEmails, setValidEmails] = useState([]);
  const [badEmails, setBadEmails] = useState([]);
  const [products, setProducts] = useState([{ id: '1', value: '', quantity: '' }]);
  const { fields, handleChange, setFieldValue, handleFinish } = useForm({
    hashesQuantity: {
      required: true,
    },
    campaignName: {
      required: true,
      mask: noSpecialCharsMask,
    },
    expirationDate: {
      required: true,
    },
    crm: {
      required: true,
      value: 'false',
    },
    analytics: {
      required: true,
      value: 'false',
    },
    sendInvoice: {
      required: true,
      value: 'false',
    },
    sendEmail: {
      required: true,
      value: 'true',
    },
    sendSms: {
      required: true,
      value: 'true',
    },
    ship: {
      required: true,
      value: 'true',
    },
    sheetName: {
      required: true,
    },
    productOptions: {
      required: true,
      value: [],
    },
  });

  useEffect(() => {
    // TODO: fetch existent sheets from an API and show then here
    if (sheetOptions.length === 0) {
      const sheets = ['hash', 'givers', 'data', 'influencers', 'projeto-amora'];
      setSheetOptions(sheets.map((sheet) => ({ value: sheet, label: sheet })));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetOptions]);

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/hash-campaigns');
  };

  const handleFieldChange = (field, value) => {
    setFieldValue(field, value);
  };

  const handleModeChange = (value) => {
    if (value === 'false') {
      setEmaislMode(true);
    } else {
      setEmaislMode(false);
    }
  };

  const handleSelectChange = (targetValue, targetName) => {
    handleChange({ target: { name: targetName, value: targetValue.value } });
  };

  const handleUploadFile = async (e) => {
    const body = new FormData();
    body.append('file', e.target.files[0]);

    const res = await fetchFromBackend(
      '/hash/campaign/emails-upload',
      'POST',
      body,
      false,
      null,
      true
    );

    switch (res.responseStatus) {
      case 201:
        setFilename(res.filename);
        setRawFilename(e.target.files[0].name);

        setValidEmails(res.emails.valid);
        setBadEmails([]);

        notify('Lista de emails carregada com sucesso', 'success');
        break;
      case 400:
        if (res.reason === 'bad file type') {
          notify('Por favor, insira um arquivo CSV', 'error');
        }
        if (res.reason === 'no emails') {
          notify('O arquivo deve conter ao menos um email listado', 'error');
        }
        if (res.reason === 'bad header') {
          notify('O arquivo deve conter um item de cabeçalho "email"', 'error');
        }
        if (res.reason === 'bad emails') {
          notify(
            'Um ou mais emails mal formatados. Por favor, corrija-os e carregue o arquivo novamente',
            'error'
          );
        }
        setFilename();
        setValidEmails(res.emails?.valid || []);
        setBadEmails(res.emails?.bad || []);
        break;
      default:
        setFilename();
        setValidEmails([]);
        setBadEmails([]);
        notify(INTERNAL_ERROR, 'error');
        break;
    }
    e.target.value = null;
  };

  const handleRemoveFile = () => {
    setFilename();
    setValidEmails([]);
    setBadEmails([]);
  };

  const validateProducts = () => {
    // check if any field are empty
    const unfilledProducts = products.filter((product) => !(product.value && product.quantity));
    if (unfilledProducts.length > 0) return false;

    // check for bad quantities
    const quantities = products.flatMap((product) => product.quantity);
    const badQuantities = quantities.filter((quantity) => quantity <= 0);
    if (badQuantities.length > 0) return false;

    return true;
  };

  const validateEmails = () => {
    if (!emailsMode) return true;
    if (!filename) return false;
    if (validEmails.length === 0) return false;
    return true;
  };

  const createHashCampaign = async () => {
    setLoading(true);

    // validations
    const { valid } = await handleFinish();
    const validProducts = validateProducts();
    const validEmails = validateEmails();

    if (!valid || !validProducts || !validEmails) {
      if (!valid) notify(MISSING_FIELDS, 'error');
      if (!validProducts) notify('Corrija ou preencha os produtos', 'error');
      if (!validEmails) notify('Arquivo de emails não carregado ou contém erros', 'error');
      setLoading(false);
      return;
    }

    let productsBody = [];
    productsBody = products.map((product) => ({
      product_id: product.value,
      quantity: product.quantity,
    }));

    const expirationDate = dayjs(fields.expirationDate.value)
      .add(3, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss+00:00');

    const reqBody = {
      campaign: {
        name: fields.campaignName.value,
        number_of_hashes: fields.hashesQuantity.value,
        expiration_date: expirationDate,
        crm: fields.crm.value,
        analytics: fields.analytics.value,
        send_invoice: fields.sendInvoice.value,
        send_email: fields.sendEmail.value,
        send_sms: fields.sendSms.value,
        sheet: fields.sheetName.value,
        shipment: fields.ship.value,
        products: productsBody,
      },
      creation: {
        mode: emailsMode ? 'emails' : 'list',
        filename: filename,
        number_of_hashes: fields.hashesQuantity.value,
      },
    };

    try {
      const res = await fetchFromBackend('/hash/campaign', 'POST', reqBody);
      switch (res.responseStatus) {
        case 200:
          const successMessage = emailsMode
            ? 'Links de compra gerados e enviados com sucesso'
            : 'Links de compra gerados com sucesso, verifique o seu email';
          notify(successMessage, 'success');
          break;
        case 409:
          notify('Nome da campanha repetido, por favor use outro', 'error');
          break;
        default:
          notify(INTERNAL_ERROR, 'error');
          break;
      }
    } catch (err) {
      notify(INTERNAL_ERROR, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      fields={fields}
      handleChange={handleChange}
      handleFieldChange={handleFieldChange}
      handleSelectChange={handleSelectChange}
      onClickGoBack={handleGoBack}
      handleClickAddCampaign={createHashCampaign}
      products={products}
      setProducts={setProducts}
      sheetOptions={sheetOptions}
      loading={loading}
      emailsMode={emailsMode}
      setEmaislMode={setEmaislMode}
      handleUploadFile={handleUploadFile}
      handleRemoveFile={handleRemoveFile}
      filename={filename}
      rawFilename={rawFilename}
      badEmails={badEmails}
      validEmails={validEmails}
      handleModeChange={handleModeChange}
    />
  );
};

export default AddHashCampaign;

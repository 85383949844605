import { PanelContainer, Block } from '../../../components/PanelContainer';
import Select from 'react-select';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Switch from '../../../components/Switch';
import ProductCard from '../../../components/ProductCard';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import styles from './AddProducts.module.css';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 37,
    minHeight: 37,
  }),
};

const Layout = ({
  onClickGoBack,
  handleFileInput,
  submitFile,
  handleChange,
  file,
  fileName,
  fields,
  createProduct,
  handleFieldChange,
  handleSelectChange,
  imageUrl,
  formatedValue,
  loading,
  copyToClipboard,
  urlText,
  urlTextInfo1,
  urlTextInfo2,
}) => {
  const lengthUnit = [
    { value: 'mm', label: 'mm' },
    { value: 'cm', label: 'cm' },
    { value: 'm', label: 'm' },
  ];

  const weightUnit = [
    { value: 'mg', label: 'mg' },
    { value: 'g', label: 'g' },
    { value: 'kg', label: 'kg' },
  ];

  const productType = [
    { value: 'digital', label: 'Produto Digital' },
    { value: 'physical', label: 'Produto Físico' },
    { value: 'any', label: '-' },
  ];

  const NFType = [
    { value: 'product', label: 'Produto' },
    { value: 'service', label: 'Serviço' },
    { value: 'na', label: 'Vazia' },
    { value: 'any', label: '-' },
  ];

  return (
    <PanelContainer
      headerMenu
      headerTitle="Criar Produto"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
      disableMenuOverlayClick
    >
      <div className={styles.header}>
        <Button
          tertiary
          icon={<ArrowLeft />}
          text="ver todos os produtos"
          className={styles.back}
          onClick={onClickGoBack}
          loading={loading}
          disabled={loading}
        />
      </div>
      <Block className={styles.block}>
        <div className={styles.firstRow}>
          <div className={styles.active}>
            <h2>Produto ativo</h2>
            <Switch
              checked={fields.active?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('active', e ? 'true' : 'false');
              }}
            />
          </div>
          <div className={styles.preview}>
            <h2>Preview</h2>
            <ProductCard
              url={imageUrl}
              display_name={fields.name.value}
              size={fields.displayWeight.value}
              value={formatedValue()}
            />
          </div>
        </div>

        <div className={styles.name}>
          <div className={styles.interfaceInfos}>
            <h2>Nome</h2>
            <Input
              name="name"
              placeholder=""
              valid={!fields.name.valid ? fields.name.valid : null}
              className={styles.input}
              value={fields.name?.value}
              onChange={handleChange}
            />
          </div>
          <div className={styles.interfaceInfos}>
            <h2>Informações de peso</h2>
            <Input
              name="displayWeight"
              placeholder="Ex.: 250ml"
              className={styles.input}
              value={fields.displayWeight?.value}
              valid={!fields.displayWeight.valid ? fields.displayWeight.valid : null}
              onChange={handleChange}
            />
          </div>
          <div className={styles.interfaceInfos}>
            <h2>Imagem do produto</h2>
            <input name="upload" type="file" onChange={handleFileInput} value={fileName} />
          </div>
          <div className={styles.interfaceButton}>
            <Button
              primary
              text="Fazer upload"
              className={styles.uploadBtn}
              onClick={submitFile}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
        <div className={styles.description}>
          <h2>Descrição</h2>
          <Input
            name="description"
            lines={2}
            placeholder=""
            className={styles.input}
            value={fields.description?.value}
            valid={!fields.description.valid ? fields.description.valid : null}
            onChange={handleChange}
          />
        </div>
        <div className={styles.brand}>
          <div className={styles.brandCols}>
            <h2>Marca </h2>
            <Input
              name="brand"
              placeholder=""
              className={styles.input}
              value={fields.brand?.value}
              valid={!fields.brand.valid ? fields.brand.valid : null}
              onChange={handleChange}
            />
          </div>

          <div className={styles.prodTime}>
            <h2>Tempo de Produção (dias) </h2>
            <Input
              name="prodTime"
              placeholder=""
              className={styles.input}
              onlyDigit
              type="number"
              value={fields.prodTime?.value}
              onChange={handleChange}
              valid={!fields.prodTime.valid ? fields.prodTime.valid : null}
            />
          </div>
        </div>
        <div className={styles.itemInfos}>
          <div className={styles.items}>
            <h2> Customizado (Necessita de fórmula?)</h2>
            <Switch
              checked={fields.custom?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('custom', e ? 'true' : 'false');
              }}
            />
          </div>

          <div className={styles.items}>
            <h2>Tipo</h2>
            <Select
              className="filter-container"
              classNamePrefix="filter"
              options={productType}
              isClearable
              value={productType.find((type) => type.value === fields.productType.value)}
              defaultValue={productType.find((type) => type.value === productType)}
              onChange={(selected) => handleSelectChange([selected], 'productType')}
              hideSelectedOptions
            />
          </div>

          <div className={styles.items}>
            <h2>Nota Fiscal</h2>
            <Select
              className="filter-container"
              classNamePrefix="filter"
              options={NFType}
              value={NFType.find((type) => type.value === fields.taxType.value)}
              onChange={(selected) => handleSelectChange([selected], 'taxType')}
              hideSelectedOptions
            />
          </div>
        </div>

        <div className={styles.codes}>
          <div className={styles.sku}>
            <h2> Código SKU</h2>
            <Input
              name="sku"
              placeholder=""
              className={styles.input}
              value={fields.sku?.value}
              valid={!fields.sku.valid ? fields.sku.valid : null}
              onChange={handleChange}
            />
          </div>

          <div className={styles.sku}>
            <h2> Código Bling</h2>
            <Input
              name="bling"
              placeholder=""
              className={styles.input}
              value={fields.bling?.value}
              valid={!fields.bling.valid ? fields.bling.valid : null}
              onChange={handleChange}
            />
          </div>
          <div className={styles.brandCols}>
            <h2>Código NCM</h2>
            <Input
              name="ncm"
              placeholder=""
              className={styles.input}
              value={fields.ncm?.value}
              valid={!fields.ncm.valid ? fields.ncm.valid : null}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.value}>
          <div className={styles.valueItem}>
            <h2>Preço</h2>
            <div className={styles.price}>
              <h2>R$</h2>
              <Input
                name="price"
                onlyDigit
                placeholder=""
                className={styles.input}
                value={fields.price?.value}
                valid={!fields.price.valid ? fields.price.valid : null}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.valueItem}>
            <h2>Brinde</h2>
            <Switch
              checked={fields.benefit?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('benefit', e ? 'true' : 'false');
              }}
            />
          </div>

          <div className={styles.valueItem}>
            <h2>Vitrine</h2>
            <Switch
              checked={fields.shelf?.value === 'true' ? true : false}
              onChange={(e) => {
                handleFieldChange('shelf', e ? 'true' : 'false');
              }}
            />
          </div>
        </div>
        <div className={styles.dimensions}>
          <h2>Dimensões do pacote</h2>
          <div className={styles.dimensionRow}>
            <div className={styles.dimensionContainer}>
              <h2>Peso Líquido</h2>
              <div className={styles.unitContainer}>
                <Input
                  name="netWeight"
                  placeholder=""
                  className={styles.dimensionInput}
                  value={fields.netWeight?.value}
                  valid={!fields.netWeight.valid ? fields.netWeight.valid : null}
                  onChange={handleChange}
                  onlyDigit
                />
              </div>
            </div>
            <div className={styles.dimensionContainerSelect}>
              <h2>Peso Bruto</h2>
              <div className={styles.unitContainer}>
                <Input
                  name="grossWeight"
                  placeholder=""
                  className={styles.dimensionInputSelect}
                  value={fields.grossWeight?.value}
                  valid={!fields.grossWeight.valid ? fields.grossWeight.valid : null}
                  onChange={handleChange}
                  onlyDigit
                />
                <Select
                  className="filter-container"
                  classNamePrefix="filter"
                  options={weightUnit}
                  value={weightUnit.find((unit) => unit.value === fields.weightUnit.value)}
                  defaultValue={weightUnit.find((unit) => unit.value === fields.weightUnit.value)}
                  isSearchable={false}
                  onChange={(selected) => handleSelectChange([selected], 'weightUnit')}
                  styles={customStyles}
                  hideSelectedOptions
                />
              </div>
            </div>

            <div className={styles.dimensionContainer}>
              <h2>Altura</h2>
              <div className={styles.unitContainer}>
                <Input
                  name="height"
                  placeholder=""
                  className={styles.dimensionInput}
                  value={fields.height?.value}
                  valid={!fields.height.valid ? fields.height.valid : null}
                  onChange={handleChange}
                  onlyDigit
                />
              </div>
            </div>
            <div className={styles.dimensionContainer}>
              <h2>Largura</h2>
              <div className={styles.unitContainer}>
                <Input
                  name="width"
                  placeholder=""
                  className={styles.dimensionInput}
                  value={fields.width?.value}
                  valid={!fields.width.valid ? fields.width.valid : null}
                  onChange={handleChange}
                  onlyDigit
                />
              </div>
            </div>
            <div className={styles.dimensionContainerSelect}>
              <h2>Comprimento</h2>
              <div className={styles.unitContainer}>
                <Input
                  name="length"
                  placeholder=""
                  className={styles.dimensionInputSelect}
                  value={fields.length?.value}
                  valid={!fields.length.valid ? fields.length.valid : null}
                  onChange={handleChange}
                  onlyDigit
                />
                <Select
                  className="filter-container"
                  classNamePrefix="filter"
                  options={lengthUnit}
                  value={lengthUnit.find((unit) => unit.value === fields.dimensionUnit.value)}
                  defaultValue={lengthUnit.find(
                    (unit) => unit.value === fields.dimensionUnit.value
                  )}
                  isSearchable={false}
                  onChange={(selected) => handleSelectChange([selected], 'dimensionUnit')}
                  styles={customStyles}
                  hideSelectedOptions
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.productURL}>
          <h2>URL do Produto</h2>
          <p>
            <i>
              <b>Formato:</b> {urlTextInfo2}
            </i>
          </p>
          <p>
            <b>
              <font color="red">{urlTextInfo1}</font>
            </b>
          </p>
          <div className={styles.productURLRow}>
            <Input name="url" className={styles.urlTextInput} value={urlText} disabled />
            <Button secondary text="Copiar" onClick={copyToClipboard} />
          </div>
        </div>
        <div className={styles.createProduct}>
          <Button
            primary
            text="ATUALIZAR"
            className={styles.createBtn}
            onClick={createProduct}
            loading={loading}
            disabled={loading}
          />
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

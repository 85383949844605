import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

import Layout from './Layout';

const Login = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    authContext.login();
  };

  useEffect(() => {
    if (authContext.signed) {
      navigate('/');
    }
  }, [authContext.signed, navigate]);

  return <Layout onClickLogin={handleLoginClick} />;
};

export default Login;

import styled from 'styled-components';
import { Input as InputUI } from '../Input';

export const Block = styled.div`
  ${({ removeBorder }) => !removeBorder && `border-bottom:  1px solid #ECEAEA;`};
  padding: 12px;
  width: 100%;
  background-color: var(--color-white);
`;

export const Header = styled.header`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isMobile }) => isMobile ? '0.5fr 0.7fr' : '0.5fr 2.5fr 0.7fr'};
`;

export const OrderContainer = styled.div``;
export const ResumeContainer = styled.div`
  margin-top: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  margin-top: 4px;
`;

export const Title = styled.h1`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
`;

export const OrdersQuantity = styled.h2`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.875rem;
  margin: 2px;
`;

export const Subtitle = styled.h3`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  margin: 2px 2px 2px 0px;

  text-align: ${({ aling }) => aling ? aling : 'start'};

  span {
    font-weight: 400;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Input = styled(InputUI)`
  max-width: 110px;

  div{
    border: 0;

    input{
      padding:0px !important;
      margin: 0px;
      display: flex;
      flex-direction: row-reverse;
      font-size: 12px;

      align-items: center;
      font-weight: ${({ active }) => JSON.parse(active) ? '700' : '400'};

      ::-webkit-calendar-picker-indicator{
        padding-left: 4px;
        margin: 0px;
      }

      @media (max-width: 1024px) {
        font-size: 11px;
      }
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  @media (max-width: 425px) {
    justify-content: center;

    &:last-child{
    margin-top: 10px;
    width: 100%;
  }
}`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
`;

export const FilterInfo = styled.h4`
  margin: 0px 7px 0px 7px;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: ${({ active }) =>
    JSON.parse(active) ? '700' : '400'
  };
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 11px;
  }

  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const Square = styled.div`
  width: 7px;
  height: 7px;
  min-width: 7px;
  background:${({ active }) => JSON.parse(active) ? 'var(--color-dark-salmon);' : 'var(--color-silver);'};

  cursor: pointer;
`;

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { CPFIsValid, CNPJIsValid } from './complexValidations';
import * as msg from './messages';
import yup from './yup';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export const cepSchema = yup
  .string()
  .min(9)
  .max(9)
  .matches(/^\d{5}-\d{3}$/m);

export const emailSchema = yup.string().email();

export const fullDateSchema = yup
  .string()
  .min(10)
  .max(10)
  .test({
    message: msg.DATE_INVALID,
    test: (value) => dayjs(value, 'DD/MM/YYYY', true).isValid(),
  });

export const birthdateSchema = yup
  .string()
  .test({
    message: msg.DATE_INVALID,
    test: (value) => dayjs(value, 'DD/MM/YYYY', true).isValid(),
  })
  .test({
    message: msg.BIRTHDATE_LESS_THAN_1_YEAR,
    test: (value) => dayjs(value, 'DD/MM/YYYY', true).diff(new Date(), 'year') < 0,
  })
  .test({
    message: msg.BIRTHDATE_MORE_THAN_120_YEAR,
    test: (value) => dayjs(value, 'DD/MM/YYYY', true).diff(new Date(), 'year') >= -120,
  });

export const cpfCnpjSchema = yup
  .string()
  .test({
    message: msg.CPF_LENGHT,
    test: (value) => {
      if (value.replace(/\D/g, '').length < 11) {
        return false;
      }

      return true;
    },
  })
  .test({
    message: msg.CPF_INVALID,
    test: (value) => {
      if (value.replace(/\D/g, '').length === 11) {
        return CPFIsValid(value);
      }

      return true;
    },
  })
  .test({
    message: msg.CNPJ_LENGHT,
    test: (value) => {
      let onlyDigit = value.replace(/\D/g, '');
      if (onlyDigit.length < 14 && onlyDigit.length !== 11) {
        return false;
      }

      return true;
    },
  })
  .test({
    message: msg.CNPJ_INVALID,
    test: (value) => {
      if (value.replace(/\D/g, '').length === 14) {
        return CNPJIsValid(value);
      }

      return true;
    },
  });

export const cpfSchema = yup.string().test({
  message: msg.CPF_LENGHT,
  test: (value) => {
    if (value.replace(/\D/g, '').length !== 11) {
      return false;
    }

    return true;
  },
});

export const cellphoneSchema = yup
  .string()
  .min(14)
  .max(15)
  .test({
    message: msg.FIELD_INVALID,
    test: (value) => {
      if (value.match(/^\(\d{2}\) \d{4,5}-\d{4}$/m)) {
        return true;
      }

      return false;
    },
  });

export const creditCardNumberSchema = yup.string().test({
  message: msg.INVALID_CARD_NUMBER,
  test: (value) => {
    // The Luhn Algorithm
    // https://gist.github.com/DiegoSalazar/4075533

    // Accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    let nCheck = 0,
      bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  },
});

export const creditCardExpirationSchema = yup
  .string()
  .test({
    message: msg.DATE_INVALID,
    test: (value) => dayjs(value, 'MM/YY', true).isValid(),
  })
  .test({
    message: msg.EXPIRED_CARD,
    test: (value) => dayjs(value, 'MM/YY', true).isSameOrAfter(new Date(), 'year'),
  })
  .test({
    message: msg.EXPIRED_CARD,
    test: (value) => dayjs(value, 'MM/YY', true).isSameOrAfter(new Date(), 'month'),
  });

export const passwordSchema = yup.string().min(8);

export const confirmationPasswordSchema = {
  refValue: '',
  validate: async (valueToValidate) => {
    if (confirmationPasswordSchema.refValue !== valueToValidate) {
      throw new Error(msg.CONFIRMATION_PASSWORD_INVALID);
    }
    return;
  },
};

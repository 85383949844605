import { PanelContainer, Block } from '../../../components/PanelContainer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import LoadingDots from '../../../components/LoadingDots';
import JFYSelect from '../../../components/Select';
import ReactPaginate from 'react-paginate';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left.svg';

import paginateStyles from '../../../styles/Paginate.module.css';

import styles from './HashCampaigns.module.css';

const Layout = ({
  loading,
  campaigns,
  onPageSizeChange,
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  onSearchFieldChange,
  searchTerm,
  search,
  handleCampaignClick,
  handleClickAdd,
}) => {
  const pageSizes = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];

  return (
    <PanelContainer
      headerMenu
      headerTitle="Campanhas de Códigos de Compra"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <Block className={styles.block}>
        <div className={styles.search}>
          <div className={styles.searchContainer}>
            <Input
              name="search"
              value={searchTerm}
              placeholder="Buscar campanha"
              className={styles.searchInput}
              onChange={onSearchFieldChange}
            />
            <Button secondary text="Buscar" className={styles.searchButton} onClick={search} />
          </div>
          <Button
            primary
            text="Criar campanha"
            className={styles.button}
            onClick={handleClickAdd}
          />
        </div>

        <div className={styles.hashcps}>
          {!loading ? (
            <>
              {campaigns.length > 0 ? (
                <>
                  <div className={styles.header}>
                    <p className={styles.code}>Campanha</p>
                    <p className={styles.discount}>Numero de códigos</p>
                    <p className={styles.expire}>Data de expiração</p>
                  </div>
                  {campaigns.map((campaign, key) => (
                    <div className={styles.hashcp} key={key}>
                      <div className={styles.code}>
                        <h2 onClick={(e) => handleCampaignClick(campaign.id, e.ctrlKey)}>
                          {campaign.name}
                        </h2>
                        <p>
                          {campaign?.products?.map((product, index) => {
                            if (index + 1 === campaign.products.length) {
                              return product;
                            }
                            return product + ', ';
                          })}
                        </p>
                      </div>
                      <div className={styles.discount}>{campaign.quantity}</div>
                      <div className={styles.expire}>
                        {new Date(campaign.expire_date).toLocaleDateString()}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className={styles.notFound}>Nenhuma campanha encontrado</p>
              )}
            </>
          ) : (
            <LoadingDots className={styles.loading} />
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.limit}>
            <JFYSelect
              options={pageSizes}
              defaultValue={
                pageSizes.find((page) => page.value === parseInt(pageSize)) || pageSizes[0]
              }
              isSearchable={false}
              onChange={onPageSizeChange}
            />
            <p>Itens por página</p>
          </div>
          <div className={styles.pages}>
            <ReactPaginate
              pageCount={totalPages}
              nextLabel={<LeftArrow />}
              previousLabel={<LeftArrow />}
              initialPage={parseInt(currentPage) || undefined}
              previousClassName={paginateStyles.paginationPreviousLink}
              containerClassName={paginateStyles.pagination}
              previousLinkClassName={paginateStyles.paginationLink}
              nextLinkClassName={paginateStyles.paginationLink + ' ' + paginateStyles.next}
              activeClassName={paginateStyles.paginationLinkActive}
              onPageChange={onPageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

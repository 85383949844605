import { uploadFile } from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

const BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECERET_ACCESS_KEY;

const config = {
  bucketName: BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};


const uploadToS3 = (file) => {
  console.log(config);
  return uploadFile(file, config).then((data) => {
    return data;
  })
    .catch(err => console.log(err));

};

export default uploadToS3;

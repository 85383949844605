import { Status } from '../Status';
import { Container, Info, Square } from './styled';

const ListUniqueSelection = ({ data, selected, handleClick }) => {
  return (
    <>
      {data.map((item, index) => (
        <Container key={index}>
          <Square
            active={item.value === selected}
            onClick={() => handleClick(item.value)}
          />
          <Info
            active={item.value === selected}
            onClick={() => handleClick(item.value)}>
            {item.label}
          </Info>
        </Container>
      ))}
    </>
  );
};

const ListMultSelection = ({ data, name, selected, handleClick }) => {
  return (
    <>
      {data.map((status) => (
        <Status
          key={status.value}
          onClick={() => handleClick(status.value.toString(), name, true)}
          active={selected.includes(status.value.toString())}
        >
          {status.label}
        </Status>
      ))}
    </>
  );
};

export { ListUniqueSelection, ListMultSelection };

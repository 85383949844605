import { ReactComponent as ValidContentSvg } from '../../../assets/icons/valid.svg';
import { ReactComponent as InvalidContentSvg } from '../../../assets/icons/invalid.svg';
import { ReactComponent as UnhiddenContentSvg } from '../../../assets/icons/unhidden.svg';
import { ReactComponent as HiddenContentSvg } from '../../../assets/icons/hidden.svg';

import styles from './Input.module.css';
import getSuggestion from '../suggestions';

const BaseLayout = ({
  placeholder = 'Normal',
  type = 'text',
  disabled = false,
  onlyDigit = false,
  value = '',
  name,
  valid,
  message,
  onChange,
  onBlur,
  onSuggestion,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  handleChange,
  handleBlur,
  inputContent,
  suggestion,
  setSuggestion,
  children,
  props,
}) => {
  return (
    <div className={className}>
      <div
        className={
          styles.container +
          (valid ? ' ' + styles.valid : '') +
          (valid === false ? ' ' + styles.invalid : '')
        }
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={
            styles.input +
            (inputClassName ? ' ' + inputClassName : '') +
            (disabled ? ' ' + styles.disabled : '')
          }
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={inputContent}
          {...props}
        ></input>
        {children}
      </div>
      {valid !== undefined && message && (
        <p
          className={
            styles.message +
            ' ' +
            (valid ? ' ' + styles.valid : '') +
            (valid === false ? ' ' + styles.invalid : '')
          }
        >
          {message}
        </p>
      )}
      {suggestion && (
        <p className={styles.suggestions}>
          Você quis dizer{' '}
          <b
            onClick={() => {
              onSuggestion(suggestion);
              setSuggestion(getSuggestion(type, suggestion));
            }}
          >
            {suggestion}
          </b>
          ?
        </p>
      )}
    </div>
  );
};

export const InputLayout = ({
  placeholder = 'Normal',
  type = 'text',
  disabled = false,
  onlyDigit = false,
  value = '',
  name,
  valid,
  message,
  onChange,
  onBlur,
  onSuggestion,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  handleChange,
  handleBlur,
  inputContent,
  suggestion,
  setSuggestion,
  props,
}) => {
  return (
    <BaseLayout
      name={name}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      onlyDigit={onlyDigit}
      value={value}
      valid={valid}
      message={message}
      onChange={onChange}
      onBlur={onBlur}
      onSuggestion={onSuggestion}
      noSpace={noSpace}
      maxLength={maxLength}
      className={className}
      inputClassName={inputClassName}
      prepend={prepend}
      handleChange={handleChange}
      handleBlur={handleBlur}
      inputContent={inputContent}
      suggestion={suggestion}
      setSuggestion={setSuggestion}
      props={props}
    >
      {valid && (
        <ValidContentSvg className={styles.validationIcon} alt="Indicador de conteúdo válido" />
      )}
      {valid === false && (
        <InvalidContentSvg className={styles.validationIcon} alt="Indicador de conteúdo válido" />
      )}
    </BaseLayout>
  );
};

export const PasswordInputLayout = ({
  placeholder = 'Normal',
  type = 'text',
  disabled = false,
  onlyDigit = false,
  value = '',
  name,
  valid,
  hidden,
  handleHidden,
  message,
  onChange,
  onBlur,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  handleChange,
  handleBlur,
  inputContent,
  props,
}) => {
  return (
    <BaseLayout
      name={name}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      onlyDigit={onlyDigit}
      value={value}
      valid={valid}
      message={message}
      onChange={onChange}
      onBlur={onBlur}
      noSpace={noSpace}
      maxLength={maxLength}
      className={className}
      inputClassName={inputClassName}
      prepend={prepend}
      handleChange={handleChange}
      handleBlur={handleBlur}
      inputContent={inputContent}
      props={props}
    >
      {hidden && (
        <HiddenContentSvg
          className={styles.visibityIcon}
          alt="Indicador de senha escondida"
          onClick={handleHidden}
        />
      )}
      {!hidden && (
        <UnhiddenContentSvg
          className={styles.visibityIcon}
          alt="Indicador de senha exposta"
          onClick={handleHidden}
        />
      )}
    </BaseLayout>
  );
};

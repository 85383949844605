import { useState, useEffect } from 'react';

import getSuggestion from './suggestions';
import { InputLayout, PasswordInputLayout } from './Layout';
import { handleBlurGeneric, handleChangeGeneric, formatInput } from './utils';

export const Input = ({
  placeholder = 'Normal',
  type = 'text',
  disabled = false,
  onlyDigit = false,
  value = '',
  valid,
  message,
  onChange,
  onBlur,
  name,
  onSuggestion,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  ...props
}) => {
  const [inputContent, setInputContent] = useState(
    formatInput(value, onlyDigit, noSpace, maxLength)
  );
  const [suggestion, setSuggestion] = useState();

  const handleBlur = handleBlurGeneric({
    onSuggestion,
    getSuggestion,
    setSuggestion,
    onBlur,
    type,
  });
  const handleChange = handleChangeGeneric({
    onSuggestion,
    setSuggestion,
    onChange,
    setInputContent,
    onlyDigit,
    noSpace,
    maxLength,
  });

  useEffect(() => {
    setInputContent(value);
  }, [maxLength, value]);

  return (
    <InputLayout
      name={name}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      onlyDigit={onlyDigit}
      value={value}
      valid={valid}
      message={message}
      onChange={onChange}
      onBlur={onBlur}
      onSuggestion={onSuggestion}
      noSpace={noSpace}
      maxLength={maxLength}
      className={className}
      inputClassName={inputClassName}
      prepend={prepend}
      handleChange={handleChange}
      handleBlur={handleBlur}
      inputContent={inputContent}
      suggestion={suggestion}
      setSuggestion={setSuggestion}
      props={props}
    />
  );
};

export const PasswordInput = ({
  placeholder = 'Normal',
  type = 'password',
  disabled = false,
  onlyDigit = false,
  value = '',
  valid,
  message,
  onChange,
  onBlur,
  name,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  ...props
}) => {
  const [inputContent, setInputContent] = useState(
    formatInput(value, onlyDigit, noSpace, maxLength)
  );

  const [hidden, setHidden] = useState(true);
  const [currentType, setCurrentType] = useState('');

  useEffect(() => {
    setCurrentType(type);
  }, [type]);

  const handleHidden = () => {
    setHidden(!hidden);
    if (currentType === 'password') {
      setCurrentType('text');
    } else {
      setCurrentType('password');
    }
  };

  const handleBlur = handleBlurGeneric({ onBlur, type });
  const handleChange = handleChangeGeneric({
    onChange,
    setInputContent,
    onlyDigit,
    noSpace,
    maxLength,
  });

  useEffect(() => {
    setInputContent(value);
  }, [maxLength, value]);

  return (
    <PasswordInputLayout
      name={name}
      placeholder={placeholder}
      type={currentType}
      disabled={disabled}
      onlyDigit={onlyDigit}
      value={value}
      valid={valid}
      hidden={hidden}
      handleHidden={handleHidden}
      message={message}
      onChange={onChange}
      onBlur={onBlur}
      noSpace={noSpace}
      maxLength={maxLength}
      className={className}
      inputClassName={inputClassName}
      prepend={prepend}
      handleChange={handleChange}
      handleBlur={handleBlur}
      inputContent={inputContent}
      props={props}
    />
  );
};

export default Input;

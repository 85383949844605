import { PanelContainer, Block } from '../../../components/PanelContainer';
import LoadingDots from '../../../components/LoadingDots';
import Input from '../../../components/Input';
import JFYSelect from '../../../components/Select';
import ReactPaginate from 'react-paginate';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ValidIcon } from '../../../assets/icons/approved.svg';
import { ReactComponent as InvalidIcon } from '../../../assets/icons/denied.svg';

import styles from './Vouchers.module.css';
import paginateStyles from '../../../styles/Paginate.module.css';
import formatPrice from '../../../utils/formatPrice';
import Button from '../../../components/Button';

const Layout = ({
  vouchers,
  totalPages,
  totalVouchers,
  onPageSizeChange,
  onPageChange,
  loading,
  fields,
  handleChange,
  pageSize,
  currentPage,
  handleNewVoucher,
  handleVoucherClick,
  allowNewVoucher,
}) => {
  const pageSizes = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  return (
    <PanelContainer
      headerMenu
      headerTitle="Cupons"
      className={styles.container}
      headerClassName={styles.containerHeader}
      blocksClassName={styles.blocks}
    >
      <Block className={styles.block}>
        <div className={styles.search}>
          <Input
            name="search"
            value={fields.search?.value}
            onChange={handleChange}
            placeholder="Buscar cupom"
            className={styles.input}
          />
          {(totalVouchers || totalVouchers === 0) && <p>{totalVouchers} cupons</p>}
          {allowNewVoucher && (
            <Button
              primary
              text="Criar Cupom"
              onClick={handleNewVoucher}
              className={styles.button}
            />
          )}
        </div>
        <div className={styles.vouchers}>
          {!loading ? (
            <>
              {vouchers.length > 0 ? (
                <>
                  <div className={styles.header}>
                    <p className={styles.code}>Cupom</p>
                    <p className={styles.discount}>Desconto</p>
                    <p className={styles.status}>Ativo</p>
                    <p className={styles.valid}>Válido</p>
                    <p className={styles.valid}>Frete Limitado</p>
                  </div>
                  {vouchers.map((voucher, key) => (
                    <div className={styles.voucher} key={key}>
                      <div className={styles.code}>
                        <h2 onClick={(e) => handleVoucherClick(voucher.code, e.ctrlKey)}>
                          {voucher.code}
                        </h2>
                        <p>{voucher.description}</p>
                      </div>
                      <div className={styles.discount}>
                        <h2>
                          {voucher.is_percent
                            ? `${voucher.discount_value}%`
                            : formatPrice(voucher.discount_value)}
                        </h2>
                      </div>
                      <div className={styles.status}>
                        {voucher.active ? <ValidIcon /> : <InvalidIcon />}
                      </div>
                      <div className={styles.valid}>
                        {voucher.expired ? <InvalidIcon /> : <ValidIcon />}
                      </div>
                      <div className={styles.valid}>
                        {voucher.zip_limitation ? <ValidIcon /> : <InvalidIcon />}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className={styles.notFound}>Nenhum cupom encontrado</p>
              )}
            </>
          ) : (
            <LoadingDots className={styles.loading} />
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.limit}>
            <JFYSelect
              options={pageSizes}
              defaultValue={
                pageSizes.find((page) => page.value === parseInt(pageSize)) || pageSizes[0]
              }
              isSearchable={false}
              onChange={onPageSizeChange}
            />
            <p>Itens por página</p>
          </div>
          <div className={styles.pages}>
            <ReactPaginate
              pageCount={totalPages}
              nextLabel={<LeftArrow />}
              previousLabel={<LeftArrow />}
              initialPage={parseInt(currentPage) || undefined}
              previousClassName={paginateStyles.paginationPreviousLink}
              containerClassName={paginateStyles.pagination}
              previousLinkClassName={paginateStyles.paginationLink}
              nextLinkClassName={paginateStyles.paginationLink + ' ' + paginateStyles.next}
              activeClassName={paginateStyles.paginationLinkActive}
              onPageChange={onPageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import Layout from './Layout';
import { validateDateToIncrement } from '../../utils/validateDateToIncrement'

const Order = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [statusHistory, setStatusHistory] = useState();
  const [discounts, setDiscounts] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const { fetchFromBackend } = useFetch();

  const handleGoBack = () => {
    navigate(window.history.state && window.history.state.idx > 0 ? -1 : '/orders');
  };

  const calculateDiscounts = (order) => {
    const totalDiscount = order.total_value + order.shipping_fee - order.final_value;
    let voucherDiscount;
    let paymentMethodDiscount;
    if (order.voucher_code) {
      if (order.voucher_is_percent) {
        voucherDiscount = (order.voucher_value * order.total_value) / 100;
      } else {
        voucherDiscount = order.voucher_value;
      }

      paymentMethodDiscount = totalDiscount - voucherDiscount;
      paymentMethodDiscount = paymentMethodDiscount < 0 ? 0 : paymentMethodDiscount;
    } else {
      voucherDiscount = 0;
      paymentMethodDiscount = totalDiscount;
    }

    return {
      total: totalDiscount,
      payment: paymentMethodDiscount,
      voucher: voucherDiscount,
    };
  };

  const parseProducts = (products) => {
    let forms = [];
    products.forEach((product) => {
      const formIndex = forms.findIndex((form) => form.formId === product.form_id);
      if (formIndex !== -1) {
        forms[formIndex].products.push(product);
      } else {
        forms.push({
          formId: product.form_id,
          formName: product.form_name,
          products: [{ ...product }],
        });
      }
    });
    return forms;
  };

  useEffect(() => {
    if (order) {
      if (order.payment_discount_value) {
        setDiscounts({
          total: order?.total_value + order.shipping_fee - order.final_value,
          payment: order?.payment_discount_value,
          voucher: order?.voucher_discount_value,
        });
      } else {
        setDiscounts(calculateDiscounts(order));
      }

      setProducts(parseProducts(order.products));
    }
  }, [order]);

  useEffect(() => {
    const fetchStatusHistory = () => {
      fetchFromBackend(`/orders/status/changes/${orderId}`, 'GET', {}, true).then(
        (orderStatusHistory) => {
          setStatusHistory(orderStatusHistory);
        }
      );
    };

    const fetchOrder = () => {
      setLoading(true);
      fetchFromBackend(`/orders/${orderId}`, 'GET', {}, true).then(async (response) => {
        response.days_to_deliver += validateDateToIncrement(response.creation_date, response?.status_id).daysToIncrement
        setOrder(response);
        await fetchStatusHistory();
        setLoading(false);
      });
    };

    if (!order && !loading) {
      fetchOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      order={order}
      products={products}
      discounts={discounts}
      statusHistory={statusHistory}
      onClickGoBack={handleGoBack}
    />
  );
};

export default Order;

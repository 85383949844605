import styled from 'styled-components';
import { JFYSelect } from '../../../components/Select';
import { findOrderStausStyle } from '../../../utils/constants'

export const ShippingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
`;

export const SearchContainer = styled.div`
  width: 40%;
  @media (max-width: 1024px) {
   width: 100%;
  }
`;

export const Label = styled.p`
  margin: ${({ margin }) => margin ? `0px 0px 5px ${margin}` : "0px 0px 5px 0px"};
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 600;
  text-align: ${({ aling }) => (aling ? aling : 'start')};

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 425px) {
    font-size: 13px;
  }
`;

export const MassChangeContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.7fr 1.35fr;
  margin-top: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Left = styled.div``;
export const Right = styled.div``;

export const ControlerChangeContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr;
  gap: 1rem;
  align-items: center;
  width: 82%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const ControlerValueContainer = styled.div`
  display: flex;
  align-items: center;

  h2,
  h3 {
    margin-top: 12px;
    margin-right: 5px;
    font-weight: ${({ removeBold }) => (removeBold ? 'normal' : 'bold')};
  }
`;

export const Select = styled(JFYSelect).attrs({
  className: 'filter-container',
  classNamePrefix: 'filter',
})`
  svg {
    color: var(--color-dark-salmon);
    height: 35px;
  }
`;

export const GridSelect = styled(JFYSelect).attrs({
  className: 'filter-container',
  classNamePrefix: 'filter',
})`
  width: 150px;

  svg {
    color: var(--color-dark-salmon);
    height: 35px;
  }

  div {
    border: none !important;
    cursor: pointer;
  }

  .filter__single-value {
    ${({ shippingTypeSeleted }) => shippingTypeSeleted && findOrderStausStyle(shippingTypeSeleted).styles};
    padding: 5px 0 5px 2px;
    font-size: 11px;
    text-align: center;
  }
`;


export const GridHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid #eceaea;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;

  margin-top: 10px;
`;

export const Grid = styled.div`
  display: grid;
`;

export const GridLine = styled(Grid)`
  border-bottom: 1px solid #eceaea;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  @media (max-width: 425px) {
    grid-template-columns: 1fr 0.5fr 1fr;

    &:first-child {
      border-top: 0;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 13px;
  text-align: start;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

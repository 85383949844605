import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDOJ2xzCqud5yN7ePSEEDQY-GZkxFitg2Y",
  authDomain: "justforyou-br.firebaseapp.com",
  projectId: "justforyou-br",
  storageBucket: "justforyou-br.appspot.com",
  messagingSenderId: "864261176059",
  appId: "1:864261176059:web:f1b26a16f59e59fe898e5e"
};

// Initialize Firebase
const initializeFirebaseApp = () => initializeApp(firebaseConfig);
export default initializeFirebaseApp;

import { PanelContainer, Block } from '../../../components/PanelContainer';
import Button from '../../../components/Button';

import styles from './Login.module.css';

const Layout = ({ onClickLogin }) => {
  return (
    <PanelContainer headerLogo className={styles.container} headerClassName={styles.header}>
      <Block className={styles.block}>
        <h1 className={styles.title}>Bem vinda(o) ao Painel JFY!</h1>
        <div className={styles.buttons} onClick={onClickLogin}>
          <Button primary text="Entrar" />
        </div>
      </Block>
    </PanelContainer>
  );
};

export default Layout;

import { useEffect, useState } from 'react';
import { useSearchParams, createSearchParams, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import notify, { INTERNAL_ERROR } from '../../utils/notify';
import getUrlParams from '../../utils/urlParamsHandler';
import Layout from './Layout';

const Carts = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [carts, setCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCarts, setTotalCarts] = useState();
  const [urlFilters] = useState(getUrlParams(searchParams, ['search', 'all', 'page', 'limit']));
  const defaultPageSize = 10;
  const defaultPage = 0;
  const [pageSize, setPageSize] = useState(urlFilters.limit || defaultPageSize);
  const [currentPage, setCurrentPage] = useState(urlFilters.page || defaultPage);
  const { fetchFromBackend } = useFetch();
  const { fields, handleChange } = useForm({
    search: {
      required: false,
      value: urlFilters.search,
    },
    all: {
      required: false,
      value: urlFilters.all,
    },
  });

  const handleCartClick = (cartId, newTab = false) => {
    const url = '/carts/' + cartId;
    if (newTab) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.value);
  };

  const parseSearchParams = (params = {}) => {
    Object.entries(fields).forEach((field) => {
      const key = field[0];
      const value = field[1].value;
      if (value) {
        if (typeof value === 'object') {
          const validValues = value.filter((v) => v !== '');
          if (validValues.length > 0) params[key] = validValues.join(',');
        } else if (value !== '') {
          params[key] = value;
        }
      }
    });
    if (pageSize !== defaultPageSize) params.limit = pageSize;
    if (currentPage !== defaultPage) params.page = currentPage;
    return createSearchParams(params);
  };

  const fetchCarts = () => {
    setLoading(true);
    fetchFromBackend(`/carts/abandoned/filter?${parseSearchParams()}`, 'GET', {}, true)
      .then((response) => {
        setCarts(response.data);
        setTotalCarts(response.total);
        setTotalPages(Math.ceil(response.total / response.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(INTERNAL_ERROR);
      });
  };

  useEffect(() => {
    !loading && setLoading(true);
    const filterDelayDebounce = setTimeout(async () => {
      await fetchCarts();
      setSearchParams(parseSearchParams());
    }, 1000);
    return () => clearTimeout(filterDelayDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, currentPage, pageSize]);

  return (
    <Layout
      carts={carts}
      totalPages={totalPages}
      totalCarts={totalCarts}
      fields={fields}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      handleChange={handleChange}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleCartClick={handleCartClick}
    />
  );
};

export default Carts;
